import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, Form, ButtonGroup, Input, Alert, Label } from 'reactstrap';
import { LoadingSpinner, LoadingDots } from "./LoadingAnimations";
import authHelper, { AuthorizeHelper } from './Helpers/AuthorizeHelper';
import stateHelper from './Helpers/StateHelper';


export const ReviewForm = (props) => {

	const [review, setReview] = useState(props.review); //The review this user is posting
	const [errorSection, setErrorSection] = useState({ show: false, text: null });
	const [isSaving, setIsSaving] = useState(false);
	const maxTitleLength = 50;


	function onSaveReviewClick(e) {

		e.preventDefault();

		setIsSaving(true);

		if (!review.rating || review.rating <= 0 || review.rating > 5) {
			setIsSaving(false);
			showError("Please click the stars to choose a rating.");
			return;
		}

		review.revieweeId = props.advisor.id;

		authHelper.securePut(props.putUrl, review)
			.then(response => {

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}

			})
			.then(() => {
				setErrorSection({ show: false, text: null });
				setIsSaving(false);
				props.onSuccessfulSave();
			})
			.catch(error => {
				showError("Something unexpected went wrong! Please contact us if this keeps happening.");
				setIsSaving(false);
				console.error('Unable to save review.', error)
			});

	}

	const showError = (text) => {

		let errorSection = {};

		errorSection.show = true;
		errorSection.text = text.toString();

		setErrorSection(errorSection);
	}


	function onRadioBtnClick(rating) {
		setReview(r => ({
			...r,
			rating: rating,
		}))
	}

	function onBodyChange(e) {

		var newTitle = e.target.value;

		if (!review.title || review.body && review.body.slice(0, maxTitleLength - 3) === review.title.slice(0, maxTitleLength - 3)) {

			if (newTitle.length >= maxTitleLength - 3) {
				newTitle = newTitle.slice(0, maxTitleLength - 3) + "...";
			}

			setReview(a => ({
				...a,
				title: newTitle,
			}))
		}

		onFormChange(e);
	}


	function onFormChange(e) {
		//hideError();
		setReview(a => ({
			...a,
			[e.target.id]: e.target.value,
		}))
	}

	function onDisplayNameChange(e) {
		//hideError();
		setReview(a => ({
			...a,
			[e.target.name]: e.target.value,
		}))
	}


	return (
		<Form onSubmit={onSaveReviewClick} className="review-form">
			<h1>Write a Review</h1>
			<img src={props.advisor.profilePicUrl}
				className="circle-image small-headshot"
				alt={props.advisor.firstName + " " + props.advisor.lastName + ", " + props.advisor.certifications} />
			<h2>How was your experience with {props.advisor.firstName + " " + props.advisor.lastName}?</h2>

			<ButtonGroup className="review-ratings">
				<i onClick={() => onRadioBtnClick(1)} className={review.rating >= 1 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
				<i onClick={() => onRadioBtnClick(2)} className={review.rating >= 2 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
				<i onClick={() => onRadioBtnClick(3)} className={review.rating >= 3 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
				<i onClick={() => onRadioBtnClick(4)} className={review.rating >= 4 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
				<i onClick={() => onRadioBtnClick(5)} className={review.rating >= 5 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
			</ButtonGroup>

			<h4>Tell us why</h4>

			<Input
				id="body"
				name="Body"
				type="textarea"
				rows="3"
				placeholder="Share your experience with others here..."
				value={review.body}
				required
				onChange={(e) => onBodyChange(e)}
			/>

			<h4>Title your review</h4>
			<Input
				id="title"
				name="Title"
				placeholder="Summarize your review"
				type="text"
				value={review.title}
				required
				maxLength={maxTitleLength}
				onChange={(e) => onFormChange(e)}
			/>
			<h4>How would you like your name to appear on this review?</h4>
			<div>
				<Input id="ReviewDisplayNameTypeID1" name="displayName" type="radio" selected={true} required
					value={props.consumer.fullName}
					onChange={(e) => onDisplayNameChange(e)}
					checked={review.displayName == props.consumer.fullName} />&nbsp;
				<Label for="ReviewDisplayNameTypeID1">{props.consumer.fullName}</Label>
				<br />
				<Input id="ReviewDisplayNameTypeID2" name="displayName" type="radio" required
					value={props.consumer.firstName + " " + props.consumer.lastName.charAt(0) + "."}
					onChange={(e) => onDisplayNameChange(e)}
					checked={review.displayName == props.consumer.firstName + " " + props.consumer.lastName.charAt(0) + "."} />&nbsp;
				<Label for="ReviewDisplayNameTypeID2">{props.consumer.firstName + " " + props.consumer.lastName.charAt(0) + "."}</Label>
				<br />
				<Input id="ReviewDisplayNameTypeID0" name="displayName" type="radio" required
					value="Anonymous"
					onChange={(e) => onDisplayNameChange(e)}
					checked={review.displayName == "Anonymous"} />&nbsp;
				<Label for="ReviewDisplayNameTypeID0">Anonymous</Label>
				<br />
				<Input id="ReviewDisplayNameTypeID3" name="displayName" type="radio" required
					value={"Anonymous from " + stateHelper.GetStateNameFromAbbreviation(props.consumer.state)}
					onChange={(e) => onDisplayNameChange(e)}
					checked={review.displayName == "Anonymous from " + stateHelper.GetStateNameFromAbbreviation(props.consumer.state)} />&nbsp;
				<Label for="ReviewDisplayNameTypeID3">Anonymous from {stateHelper.GetStateNameFromAbbreviation(props.consumer.state)}</Label>
			</div>


			{errorSection.show &&
				<Alert color="danger">
					<strong>Uh oh!</strong> {errorSection.text}
				</Alert>
			}
			{props.onCancel &&
				<Button color="light" size="lg" onClick={() => props.onCancel()}>Cancel</Button>
			}
			<Button color="primary" size="lg" className="float-end" disabled={isSaving}>
				{isSaving ? <LoadingDots /> : "Submit"}
			</Button>
		</Form>
	);
}
