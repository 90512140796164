import React, { Component, useState } from 'react';
import { Container, Col, Row, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, Button } from 'reactstrap';
import { Outlet, Link, Router, NavLink } from 'react-router-dom';
import logoImage from "../../img/nectarine-logo.png";
import '../Account/Account.css';
import './Advisor.css';
import authService from '../api-authorization/AuthorizeService';
import authHelper from '../Helpers/AuthorizeHelper';


async function populateActiveUser() {
	return authHelper.secureGet('api/account/user');
}


export const AdvisorLayout = () => {

	const [isOpen, setIsOpen] = useState(false);
	const [user, setUser] = useState(null);
	const [loggedInUser, setLoggedInUser] = useState();

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	React.useEffect(() => {
		populateActiveUser().then((a) => setUser(a));
		authService.getUser().then((a) => setLoggedInUser(a));
	}, []);

	return (
		<div className="container-fluid">
			<div className="impersonate-label">
				{loggedInUser && user && loggedInUser.name !== user.userName && <strong>Impersonating {user.firstName}</strong>}
			</div>
			<Row>
				<Col className="account-menu col-3 d-none d-md-block p-0 bg-secondary p-3 min-vh-100">
					<NavbarBrand tag={Link} to="/"><img src={logoImage} alt="Nectarine" className="logo mt-2" /></NavbarBrand>

					<ul className="navbar-nav flex-grow list-group mt-4">
						<NavItem>
							<NavLink to="/dashboard" end className="nav-link">Home</NavLink>
						</NavItem>
						<NavItem>
							<NavLink to="/dashboard/profile" className="nav-link">My Profile</NavLink>
						</NavItem>
						<NavItem>
							<NavLink to="/dashboard/reviews" className="nav-link">Reviews</NavLink>
						</NavItem>
						<NavItem>
							<NavLink to="/dashboard/payments" className="nav-link">Payments</NavLink>
						</NavItem>
						<NavItem>
							<a href="/Identity/Account/Manage" className="nav-link">Account</a>
						</NavItem>
						<NavItem className="user-section">
							<a href="/Identity/Account/Manage" className="nav-link">Hi {user && user.firstName}!</a>
						</NavItem>
					</ul>
				</Col>
				<Col className="col-12 col-md-9 p-0">
					<Navbar color="light" light expand="md" className="bg-secondary d-md-none mobile-menu">
						<NavbarBrand tag={Link} to="/"><img src={logoImage} alt="Nectarine" className="mobile-logo mt-2" /></NavbarBrand>
						<NavbarToggler onClick={toggle} />
						<Collapse isOpen={isOpen} navbar>
							<Nav className="ml-auto" navbar>
								<NavItem>
									<NavLink to="/dashboard" end className="nav-link">Home</NavLink>
								</NavItem>
								<NavItem>
									<NavLink to="/dashboard/profile" className="nav-link">My Profile</NavLink>
								</NavItem>
								<NavItem>
									<NavLink to="/dashboard/reviews" className="nav-link">Reviews</NavLink>
								</NavItem>
								<NavItem>
									<NavLink to="/dashboard/payments" className="nav-link">Payments</NavLink>
								</NavItem>
								<NavItem>
									<a href="/Identity/Account/Manage" className="nav-link">Account</a>
								</NavItem>
								<NavItem className="user-section">
									<a href="/Identity/Account/Manage" className="nav-link">Hi {user && user.firstName}!</a>
								</NavItem>
							</Nav>
						</Collapse>
					</Navbar>

					<main className="px-4">
						<Outlet context={[user]} />
					</main>

				</Col>
			</Row>

		</div >
	);
}
