import React, { Component, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	Container,
	Row,
	Col,
	UncontrolledAccordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem
} from 'reactstrap';
import stateHelper from './Helpers/StateHelper';
import { LandingPageTemplate } from './LandingPages/LandingPageTemplate';
import authHelper from './Helpers/AuthorizeHelper';

import './Home.css';


async function populateDashboardData() {
	const data = await authHelper.secureGet('api/home/seodata');
	console.log("Dashboard:", data);
	return data;
}

export const Home = (props) => {

	const [dashboard, setDashboard] = useState(null);

	React.useEffect(() => {
		populateDashboardData().then((a) => { setDashboard(a); });
	}, []);

	return (
		<Container>
			<LandingPageTemplate
				plural="financial advisors" //Plural, lowercase
				singular="advisor" //Singular, lowercase
				blurb=""
			/>

			<Row className="mt-5">
				<Col lg="4" md="12" sm="12" xs="12">
					<UncontrolledAccordion defaultOpen={[]}>
						<AccordionItem>
							<AccordionHeader targetId="1">
								Browse Financial Advisors by State
							</AccordionHeader>
							<AccordionBody accordionId="1">
								<ul>
									{
										stateHelper.usStatesAndTerritories.map(state => (
											<li key={state.abbreviation}><Link to={"/s/" + stateHelper.GetStateNameForUrl(state.abbreviation)}>{state.name} Fiduciary Flat-Fee Financial Advisors</Link></li>
										))
									}
								</ul>
							</AccordionBody>
						</AccordionItem>
					</UncontrolledAccordion>
				</Col>
				{dashboard && dashboard.allAttributes &&
					<Col lg="4" md="12" sm="12" xs="12">
						<UncontrolledAccordion defaultOpen={[]}>
							<AccordionItem>
								<AccordionHeader targetId="1">
									Browse Financial Advisors by Speciality
								</AccordionHeader>
								<AccordionBody accordionId="1">
									<ul>
										{
											dashboard.allAttributes.map(att => (
												<li key={att.slug}><Link to={"/advisors/" + att.slug}>{att.title}</Link></li>
											))
										}
									</ul>
								</AccordionBody>
							</AccordionItem>
						</UncontrolledAccordion>
					</Col>
				}
				<Col lg="4" md="12" sm="12" xs="12">
					<UncontrolledAccordion defaultOpen={[]}>
						<AccordionItem>
							<AccordionHeader targetId="1">
								Browse by Business Model
							</AccordionHeader>
							<AccordionBody accordionId="1">
								<ul>
									<li><Link to="/hourly-financial-advisors">Hourly Financial Advisors</Link></li>
									<li><Link to="/flat-fee-financial-planners">Flat-Fee Financial Planners</Link></li>
									<li><Link to="/services/fee-only-fiduciaries">Fee-Only Fiduciaries</Link></li>
									<li><Link to="/services/fiduciary-cfps">Fiduciary CFPs</Link></li>
									<li><Link to="/services/independent-financial-advisors">Independent Financial Advisors</Link></li>
									<li><Link to="/services/flat-rate-financial-planners">Flat-Rate Financial Planners</Link></li>
								</ul>
							</AccordionBody>
						</AccordionItem>
					</UncontrolledAccordion>
				</Col>
			</Row>
		</Container >
	);
}
