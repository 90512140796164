import React, { Component } from 'react';
import { Nav, NavItem, NavLink, Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './Footer.css';
import iconImage from "./../img/nectarine-icon.png";



export class Footer extends Component {

	constructor(props) {
		super(props);

		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			collapsed: true
		};
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	render() {
		return (
			<footer>
				<Container>
					<Row>
						<Col className="center">
							<NavLink tag={Link} to="/"><img src={iconImage} alt="Nectarine Icon" className="icon center" /></NavLink>
							&copy;{(new Date().getFullYear())} Nectarine, Inc.
						</Col>
						<Col>
							<Nav vertical>
								<h3>Resources</h3>
								<NavLink href="/#home">Get Started</NavLink>
								<NavLink href="/#pricing">Pricing</NavLink>
								<NavLink href="/#experts">Our Experts</NavLink>
								<LoginMenu />
							</Nav>
						</Col>
						<Col>
							<Nav vertical>
								<h3>Company</h3>
								<NavLink tag={Link} to="/recruit">Advisor Jobs</NavLink>
								<NavLink tag={Link} to="/affiliates">Affiliates</NavLink>
								<NavLink tag={Link} to="/about">About Nectarine</NavLink>
								<NavLink tag={Link} to="/contact">Contact Us</NavLink>
							</Nav>
						</Col>
						<Col>
							<Nav vertical>
								<h3>Disclosures</h3>
								<NavLink tag={Link} to="/terms">Terms of Use</NavLink>
								<NavLink href="https://s3.amazonaws.com/files.hellonectarine.com/SC8aOavKzk6B2I4Mzd8yvw-nectarine-privacy-policy.pdf" target="_blank">Privacy Policy</NavLink>
								<NavLink href="https://s3.amazonaws.com/files.hellonectarine.com/7jXr8nRu0Ot7MGI28ldg-nectarine-adv-part-2a.pdf" target="_blank">Nectarine ADV 2A</NavLink>
								<NavLink href="https://s3.amazonaws.com/files.hellonectarine.com/MsArmVi5IkGYOOVyN8jYzQ-nectarine-form-crs.pdf" target="_blank">Nectarine CRS</NavLink>
							</Nav>
						</Col>
						{/*
                    <Col>
                        <Nav vertical>
                            <h3>Follow Us</h3>
                            <NavLink tag={Link} to="/counter">Pricing</NavLink>
                            <NavLink tag={Link} to="/counter">Pricing</NavLink>
                        </Nav>
                    </Col>
                    */}
					</Row>
					<Row>
					</Row>
				</Container>
			</footer>
		);
	}
}
