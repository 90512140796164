import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { LoadingDots } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import stateHelper from '../Helpers/StateHelper';

async function populateUserStates(userID) {
	const data = await authHelper.secureGet('/api/advisor/' + userID + '/states');
	console.log("UserStates", data);
	return data;
}

export const ChooseStates = (params) => {

	const [selectedStates, setSelectedStates] = useState([]);
	const [saveStatesLoading, setSaveStatesLoading] = useState(false);
	const [showSavedMessage, setShowSavedMessage] = useState(false);

	React.useEffect(() => {
		populateUserStates(params.userID).then((a) => setSelectedStates(a));
	}, []);

	const onSaveStates = async (event) => {
		event.preventDefault();
		console.log("event", event);

		setSaveStatesLoading(true);

		const url = "/api/advisor/" + params.userID + "/states";
		authHelper.securePut(url, selectedStates)
			.then(response => {

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}

			})
			.then(() => {
				console.log("End onSetStates");
				showSuccess();
				setSaveStatesLoading(false);
			})
			.catch(error => { /*showError(error);*/
				console.error('Unable to add item.', error)
				setSaveStatesLoading(false);
			});

		return false;
	}

	const showSuccess = () => {
		setShowSavedMessage(true);
		setTimeout(() => setShowSavedMessage(false), 3000);
	}

	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;
		if (checked) {
			setSelectedStates((prevSelected) => [...prevSelected, value]);
		} else {
			setSelectedStates((prevSelected) =>
				prevSelected.filter((state) => state !== value)
			);
		}
	};

	const handleSelectAll = (e) => {
		e.preventDefault();
		setSelectedStates(selectedStates.length === stateHelper.usStatesAndTerritories.length ? [] : stateHelper.usStatesAndTerritories.map(state => state.abbreviation));
		return false;
	};

	const isAllSelected = selectedStates.length === stateHelper.usStatesAndTerritories.length;

	return (

		<Form onSubmit={onSaveStates} className="state-checkboxes">
			{showSavedMessage &&
				<Alert className="saved-alert">
					Your states have been saved!
				</Alert>
			}

			<p>
				View and edit your state coverage below. Clients will only match with you if they reside in a state you have selected.{" "}
				<a href="#" className="select-all" onClick={handleSelectAll}>{isAllSelected ? 'Select none' : 'Select all'}</a>
			</p>
			<FormGroup>
				{stateHelper.usStatesAndTerritories.map((state) => (
					<Label check key={state.abbreviation}>
						<Input
							type="checkbox"
							value={state.abbreviation}
							checked={selectedStates.includes(state.abbreviation)}
							onChange={handleCheckboxChange}
						/>&nbsp;{state.name}
					</Label>
				))}
			</FormGroup>
			<Button color="primary" disabled={saveStatesLoading}>
				{saveStatesLoading ? <LoadingDots /> : "Save States"}
			</Button>
		</Form>
	);
}
