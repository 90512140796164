

export class TypeformParser {

	formatAnswer(item) {
		const type = item.type;

		var response;

		try {
			switch (type) {
				case "choice":
					response = item.choice.label;
					break;
				case "number":
					response = item.number;
					break;
				case "text":
					response = item.text;
					break;
				case "boolean":
					response = item.boolean ? "Yes" : "No";
					break;
				case "choices":
					response = "";
					if (item.choices.labels != null) {
						response += item.choices.labels.join(', ');
					}
					if (item.choices.other != null) {
						if (response != "") {
							response += ", ";
						}
						response += item.choices.other;
					}
					break;
				case "email":
					response = item.email;
					break;

				default:
					response = "Error";
					break;

			}
		}
		catch (error) {
			response = "Caught error";
		}

		return response;
	}
}

const typeformParser = new TypeformParser();
export default typeformParser;
