import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Form, Container, Row, Col, Button, Alert, Label, Input, } from 'reactstrap';
import { LoadingSpinner } from "./LoadingAnimations";
import authHelper, { AuthorizeHelper } from './Helpers/AuthorizeHelper';
import authService from './api-authorization/AuthorizeService';
import stateHelper from './Helpers/StateHelper';
import DOMPurify from 'dompurify';
import dateHelper from './Helpers/DateHelper';
import { EditBio } from './Advisor/EditBio';
import { EditAttributes } from './Advisor/EditAttributes';
import { DisclaimerRow } from "./DisclaimerRow";
import { NotFound } from './404';

import './AdvisorDetails.css';
import { EditCustomAttributes } from './Advisor/EditCustomAttributes';
import { EditProfilePhoto } from './Advisor/EditProfilePhoto';
import { EditPersonalPhoto } from './Advisor/EditPersonalPhoto';
import { FAQs } from './HomeElements/FAQs';
async function populateAdvisor(slug) {

	const advisor = await authHelper.secureGet('api/users/slug/' + slug);

	if (!advisor || advisor.length === 0) {
		return null;
	}

	advisor.experienceStart = new Date(advisor.experienceStart);
	advisor.yearsExperience = dateHelper.getFullYearsBetweenDates(advisor.experienceStart, new Date());

	console.log("Advisor:", advisor);

	return advisor;
}

async function populateActiveUser() {
	return authHelper.secureGet('api/account/user');
}

export const AdvisorDetails = (props) => {
	const filteredAttributesSessionKey = "filteredAttributes";

	const [advisor, setAdvisor] = useState(null);
	const [consumer, setConsumer] = useState(null);
	const [redirectToCalendar, setRedirect] = React.useState(false);
	const [errorSection, setErrorSection] = useState({ show: false, text: null });
	const [buttonEnabled, setButtonEnabled] = React.useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [state, setState] = useState(localStorage.getItem('userState'));
	const [editBioModal, setEditBioModal] = useState(false);
	const [editAttributesModal, setEditAttributesModal] = useState(false);
	const [editEducationModal, setEditEducationModal] = useState(false);
	const [editExperienceModal, setEditExperienceModal] = useState(false);
	const [editPhotoModal, setEditPhotoModal] = useState(false);
	const [editProfilePicModal, setEditProfilePicModal] = useState(false);
	const [canEdit, setCanEdit] = useState(false);
	const [notFound, setNotFound] = useState(false);

	const toggleEditBioModal = () => setEditBioModal(!editBioModal);
	const toggleEditPhotoModal = () => setEditPhotoModal(!editPhotoModal);
	const toggleEditProfilePicModal = () => setEditProfilePicModal(!editProfilePicModal);
	const toggleEditAttributesModal = () => setEditAttributesModal(!editAttributesModal);
	const toggleEditEducationModal = () => {

		if (editEducationModal) {
			hardRefresh();
		}

		setEditEducationModal(!editEducationModal);
	}
	const toggleEditExperienceModal = () => {

		if (editExperienceModal) {
			hardRefresh();
		}

		setEditExperienceModal(!editExperienceModal);
	}


	let { slug } = useParams();
	let now = new Date();
	let fiftyYearsAgo = new Date();
	fiftyYearsAgo.setFullYear(now.getFullYear() - 50);

	React.useEffect(() => {
		populateAdvisor(slug).then((a) => { handleAdvisor(a); });
		populateActiveUser().then((a) => setConsumer(a));
		authService.isAuthenticated().then((a) => setIsAuthenticated(a));

	}, []);

	function handleAdvisor(a) {

		if (a == null || a.length === 0) {
			setNotFound(true);
			return;
		}

		setAdvisor(a);
	}


	React.useEffect(() => {
		if (isAuthenticated && consumer && advisor && consumer.id == advisor.id) {
			setCanEdit(true);
		}
	}, [isAuthenticated, consumer, advisor]);

	function advisorEdited(newAdvisor) {
		newAdvisor.yearsExperience = dateHelper.getFullYearsBetweenDates(newAdvisor.experienceStart, new Date());

		setAdvisor(newAdvisor);
		closeAllModals();
	}

	function closeAllModals() {
		setEditBioModal(false);
		setEditAttributesModal(false);
		setEditEducationModal(false);
		setEditExperienceModal(false);
		setEditPhotoModal(false);
		setEditProfilePicModal(false);
	}

	function hardRefresh() {
		populateAdvisor(slug).then((a) => { setAdvisor(a); });
		closeAllModals();
	}



	function onAgreeClick(e) {
		setButtonEnabled(!buttonEnabled);
	}

	function docSeparator(i, length) {
		if (i == 0 && length == 2)
			return <span> and </span>;
		if (i < length - 2)
			return <span>, </span>;
		if (i < length - 1)
			return <span>, and </span>;
		return <span> </span>;
	}

	const onStateChange = (e) => {
		setState(e.target.value);
		localStorage.setItem('userState', e.target.value);
	}

	function onScheduleClick(e, state) {

		e.preventDefault();
		hideError();

		const agreeToTerms = { StateAbbreviation: state, Product: "TheHour" };

		authHelper.securePost("/api/users/agreetoterms/" + advisor.id, agreeToTerms)
			.then(response => {
				if (!response.ok) {
					return response.json().then(text => { throw new Error(text) })
				}
			})
			.then(() => {
				setRedirect(true);
			})
			.catch(error => {
				showError(error.message);
			});

		return false;
	}

	function showError(text) {
		let errorSection = {};
		errorSection.show = true;
		errorSection.text = text.toString();
		setErrorSection(errorSection);
	}

	function hideError() {
		setErrorSection({ show: false });
	}

	function getRedirectLink() {
		const params = new URLSearchParams(window.location.search);

		const outputParams = new URLSearchParams();
		outputParams.set("email", consumer.email);
		outputParams.set("name", consumer.firstName + " " + consumer.lastName);

		return advisor.calendarLink + "?" + outputParams.toString();
	}

	function getResultsLink() {

		const stateUrl = stateHelper.GetStateNameForUrl(state);
		var url = "/";

		if (stateUrl) {
			url = "/s/" + stateUrl;
		}

		if (sessionStorage.getItem(filteredAttributesSessionKey)) {
			url += "?filters=" + sessionStorage.getItem(filteredAttributesSessionKey).split(',').join('+');
		}

		return url;
	}

	if (redirectToCalendar) {

		window.location.href = getRedirectLink();
		return <LoadingSpinner message="Looking up calendar..." />;
	}

	if (notFound) {
		return <NotFound />;
	}

	return (
		<Container>
			<Helmet>
				{advisor &&
					<title>
						{advisor.firstName} {advisor.lastName}
						{advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ", " + advisor.certifications : ""}
						{" "} - Fiduciary Financial Advisor
					</title>
				}
				{!advisor && <title>Loading... - Nectarine</title>}
			</Helmet>
			<Row>
				<Link to={getResultsLink()}>
					<i className="fa-regular fa-arrow-left back-arrow"></i>	Back</Link>
			</Row>
			{!advisor && <LoadingSpinner />}
			{advisor &&
				<Row className="advisor-details">
					<Col xl="4" lg="5">
						<div className="checkout-box position-relative">
							{canEdit &&
								<div>
									<Button className="end-0 position-absolute me-3" color="light" onClick={toggleEditProfilePicModal}>
										<i className="fa-light fa-pen-to-square"></i>{" "}
										Edit Profile Pic
									</Button>
									<EditProfilePhoto show={editProfilePicModal} toggle={toggleEditProfilePicModal} save={hardRefresh} advisor={advisor} />
								</div>
							}
							<img src={advisor.profilePicUrl}
								className="circle-image headshot center"
								alt={advisor.firstName + " " + advisor.lastName + ", " + advisor.certifications} />


							<h2 className="center">
								{advisor.firstName} {advisor.lastName}
								{advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ", " : ""}
								{advisor.certifications}
							</h2>
							<h3 className="center">{advisor.title}</h3>
							<hr />
							<h2 className="center">
								<span className="price">${Math.round(advisor.hourlyRate).toLocaleString('en-US')}</span>
								<span className="hour"> / one hour</span>
							</h2>

							<ul>
								<li>Video chat with an investing expert</li>
								<li>Get your questions answered</li>
								<li>No commissions, products, or sales pitch</li>
							</ul>

							{!isAuthenticated &&
								<div>
									<Row className="center">
										<Link to={"/register?advisor=" + advisor.slug} className="btn btn-primary btn-lg schedule-btn">Schedule an appointment</Link>
									</Row>
								</div>
							}
							{isAuthenticated &&
								<Form onSubmit={(e) => onScheduleClick(e, state)}>
									<Row className="center agree-section">
										<Label for="state" className="state-label">
											Which state do you live in?
										</Label>
										<select id="state" name="state" className="state-search" required onChange={onStateChange} value={state}>
											<option value="" defaultValue>Which state do you live in?</option>
											{stateHelper.usStatesAndTerritories.map(state => (
												<option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>
											))}
										</select>
										<br />
										<Input id="agreeToTerms"
											name="agreeToTerms"
											type="checkbox"
											onChange={(e) => onAgreeClick(e)}
										/>
										<Label for="agreeToTerms">I agree to the

											{Object.keys(advisor.nectarineHourComplianceDocuments).map((i, keyIndex) => (
												<span key={i}> <a href={advisor.nectarineHourComplianceDocuments[i]["url"]} target="_blank">
													{advisor.nectarineHourComplianceDocuments[i]["displayName"]}
												</a>
													{docSeparator(i, advisor.nectarineHourComplianceDocuments.length)}
												</span>
											))
											}
											of Nectarine
											{advisor.advisorHourComplianceDocuments.length > 0 &&
												<span> and the
													{Object.keys(advisor.advisorHourComplianceDocuments).map((i, keyIndex) => (
														<span key={i}> <a href={advisor.advisorHourComplianceDocuments[i]["url"]} target="_blank">
															{advisor.advisorHourComplianceDocuments[i]["displayName"]}
														</a>
															{docSeparator(i, advisor.advisorHourComplianceDocuments.length)}
														</span>
													))
													}
													of {advisor.firstName} {advisor.lastName}
												</span>
											}
										</Label>
									</Row>
									{errorSection.show &&
										<Alert color="danger" className="mt-4">
											{errorSection.text}
										</Alert>
									}

									<Row className="center">
										<Button
											disabled={!buttonEnabled}
											color="primary"
											size="lg"
											className="schedule-btn">Schedule an appointment
										</Button>
									</Row>
								</Form>
							}

							<Row className="center">
								{((new Date(advisor.nextAvailability)) > now) &&
									<div className="next-available">
										<i className="fa-regular fa-clock att-icon"></i>{" "}
										Next Available: <strong>{dateHelper.casualDateFormat(advisor.nextAvailability)}</strong>
									</div>
								}
							</Row>
						</div>
					</Col>
					<Col xl="8" lg="7">
						<h1>About {advisor.firstName}</h1>
						<Row className="details-highlights">
							{advisor.experienceStart > fiftyYearsAgo && advisor.experienceStart < now &&
								<Col lg="12" xl="4" className="center">
									<i className="fa-regular fa-lightbulb att-icon"></i>{" "}
									<strong>{advisor.yearsExperience}</strong> year{advisor.yearsExperience == 1 ? "" : "s"} of experience
								</Col>
							}
							{advisor.eventCount > 0 &&
								<Col lg="12" xl="4" className="center">
									<i className="fa-regular fa-calendar att-icon"></i>{" "}
									<strong>{advisor.eventCount}</strong> meeting{advisor.eventCount == 1 ? "" : "s"} completed
								</Col>
							}
							{advisor.showReviews && advisor.reviewCount > 0 &&
								<Col lg="12" xl="4" className="center">
									<i className="fa-solid fa-star att-icon"></i>{" "}
									<strong>{advisor.reviewAverage.toFixed(1)}</strong> average review
								</Col>
							}
						</Row>
						{canEdit &&
							<div>
								<Button className="float-end" color="light" onClick={toggleEditBioModal}>
									<i className="fa-light fa-pen-to-square"></i>{" "}
									Edit Bio
								</Button>
								<EditBio show={editBioModal} toggle={toggleEditBioModal} save={hardRefresh} advisor={advisor} />
							</div>
						}
						<p>
							{advisor.longBio}
						</p>
						{canEdit &&
							<div>
								<Button className="float-end" color="light" onClick={toggleEditAttributesModal}>
									<i className="fa-light fa-pen-to-square"></i>{" "}
									Edit Details
								</Button>
								<EditAttributes show={editAttributesModal} toggle={toggleEditAttributesModal} save={hardRefresh} advisor={advisor} />
							</div>
						}

						<h2>I Can Help You With</h2>
						<Row>
							{advisor.iCanHelpYouWith.sort((a, b) => (a.title > b.title) ? 1 : -1).map(item => (
								<Col xs="12" xl="6" className="mb-4" key={item.id}>
									<i className={"fa-light fa-xl att-icon att-details " + item.iconTag}></i>
									<div className="att-caption">
										{item.title}
									</div>
								</Col>
							))}
						</Row>
						<h2>I Enjoy Working With</h2>
						<Row>
							{advisor.iEnjoyWorkingWith.sort((a, b) => (a.title > b.title) ? 1 : -1).map(item => (
								<Col xs="12" xl="6" className="mb-4" key={item.id}>
									<i className={"fa-light fa-xl att-icon att-details " + item.iconTag}></i>
									<div className="att-caption">
										{item.title}
									</div>
								</Col>
							))}
						</Row>
						{canEdit &&
							<Button className="float-end" color="light" onClick={toggleEditBioModal}>
								<i className="fa-light fa-pen-to-square"></i>{" "}
								Edit Personal Stuff
							</Button>
						}
						<h2>Personal Stuff</h2>
						<p>
							{advisor.personalBio}
						</p>
						{canEdit &&
							<div>
								<Button className="float-end" color="light" onClick={toggleEditPhotoModal}>
									<i className="fa-light fa-pen-to-square"></i>{" "}
									Edit Photo
								</Button>
								<EditPersonalPhoto show={editPhotoModal} toggle={toggleEditPhotoModal} save={hardRefresh} advisor={advisor} />
							</div>
						}

						{advisor.photos.length > 0 &&
							<Row>
								{advisor.photos.map(item => (
									<Col xs="12" className="mb-4" key={item.id}>
										<img src={item.url} className="personal-photo" />
									</Col>
								))}
							</Row>

						}

						{canEdit &&
							<div>
								<Button className="float-end" color="light" onClick={toggleEditEducationModal}>
									<i className="fa-light fa-pen-to-square"></i>{" "}
									Edit Education
								</Button>
								<EditCustomAttributes show={editEducationModal}
									toggle={toggleEditEducationModal}
									save={toggleEditEducationModal}
									categoryID={3}
									title="Education &amp; Designations"
									attributes={advisor.education}
								/>
							</div>
						}
						<h2>Education &amp; Designations</h2>
						<Row>
							{advisor.education.sort((a, b) => (a.title > b.title) ? 1 : -1).map(item => (
								<Col xs="12" xl="6" className="mb-4" key={item.id}>
									<i className={"fa-light fa-xl att-icon att-details " + item.iconTag}></i>
									<div className="att-caption">
										<div className="att-title">{item.title}</div>
										<div className="att-subtitle">{item.subtitle}</div>
									</div>
								</Col>
							))}

						</Row>
						{canEdit &&
							<div>
								<Button className="float-end" color="light" onClick={toggleEditExperienceModal}>
									<i className="fa-light fa-pen-to-square"></i>{" "}
									Edit Experience
								</Button>
								<EditCustomAttributes show={editExperienceModal}
									toggle={toggleEditExperienceModal}
									save={toggleEditExperienceModal}
									categoryID={4}
									title="Experience"
									attributes={advisor.experience}
								/>
							</div>
						}
						<h2>Experience</h2>
						<Row>
							{advisor.experience.sort((a, b) => (a.endDate < b.endDate) ? 1 : -1).map(item => (
								<Col xs="12" xl="6" className="mb-4" key={item.id}>
									<i className={"fa-light fa-xl att-icon att-details " + item.iconTag}></i>
									<div className="att-caption">
										<div className="att-title">{item.title}</div>
										<div className="att-subtitle">{item.subtitle}</div>
										<div className="att-subtitle">{dateHelper.printYearRange(item.startDate, item.endDate)}</div>
									</div>
								</Col>
							))}

						</Row>
						{
							advisor.showReviews && advisor.reviews && advisor.reviews.length > 0 &&
							<div>
								<h2>Reviews</h2>
								{advisor.reviews.sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt)).map(review => (
									<div key={review.id} className="details-review">
										{Array.from({ length: review.rating }, (_, index) => (
											<i key={index} className="fa-star fa-solid att-icon"></i>
										))}
										<h3>{review.title}</h3>
										<p>
											{review.body}
										</p>

										<div className="review-footer">
											<div className="reviewer-name">{review.displayName}</div>
											<div className="review-date">{dateHelper.printMonthYearLong(review.modifiedAt)}</div>
										</div>
									</div>
								))}



							</div>
						}
						{
							!advisor.showReviews && advisor.reviewCount > 0 &&
							<div>
								<h2>Reviews</h2>
								<p>
									{advisor.firstName} has received {advisor.reviewCount} review{advisor.reviewCount > 1 && 's'}{' '}
									but {advisor.reviewCount > 1 ? 'they are' : 'it is'} hidden due to state regulations not allowing the posting of testimonials.
								</p>


							</div>
						}

						<h2>Frequently Asked Questions</h2>
						<FAQs />

						<DisclaimerRow />


					</Col>
				</Row >
			}
		</Container >
	);
}


