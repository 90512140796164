import React, { useState } from 'react';
import { Link, useParams, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	Container, Row, Col, Button,
	UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem
} from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';
import stateHelper from '../Helpers/StateHelper';
import typeformParser from '../Helpers/TypeformParser';
import { EnterMeetingNotesModal } from "./EnterMeetingNotesModal";
import DOMPurify from 'dompurify';

async function populateData(clientID) {
	const url = 'api/advisor/client/' + clientID;
	const data = await authHelper.secureGet(url);
	console.log("Client Page Data:", data);
	return data;
}


export const AdvisorClient = (props) => {

	let { clientID } = useParams();


	const [client, setClient] = useState(null);
	const [user, setUser] = useOutletContext();
	const [showEnterNotesModal, setShowEnterNotesModal] = useState(false);
	const [modalEvent, setModalEvent] = useState(null);

	const openModal = (e, event) => {
		console.log("Opening modal for event", event);
		setModalEvent(event);
		setShowEnterNotesModal(true);
	}

	const toggleEnterNotesModal = () => {
		setShowEnterNotesModal(!showEnterNotesModal);
	}

	function hardRefresh() {
		populateData(clientID).then((a) => { setClient(a); });
		setShowEnterNotesModal(false);
	}

	React.useEffect(() => {
		hardRefresh();
	}, [clientID]);

	const currentDateTime = new Date();
	const twoHoursFromNow = new Date(currentDateTime.getTime() + 2 * 60 * 60 * 1000);


	return (
		<Container>
			<Helmet>
				{!client && <title>Nectarine - Loading...</title>}
				{client && <title>Nectarine - {client.fullName}</title>}
			</Helmet>
			<Row>
				<Col className="advisor-consumer-details">
					<div className="breadcrumb-nav">

						<Link to="/dashboard"><i className="fa-regular fa-arrow-left back-arrow"></i> Back</Link>
					</div>
					{!client && <LoadingSpinner message="Loading..." />}
					{client &&
						<div>
							<h1>{client.fullName}</h1>
							<h4><a href={"mailto:" + client.email}>{client.email}</a> - {stateHelper.GetStateNameFromAbbreviation(client.state)}</h4>
							<p>
								<Link to={"/dashboard/compliance/" + client.id + "/report"} className="btn btn-primary btn-lg">
									Compliance Report &nbsp;<i className="fa-light fa-file-check"></i>
								</Link>

							</p>

							{client.intakeResponses && client.intakeResponses.length == 0 &&
								<div>
									<h2>Intake Form Responses</h2>
									<p>
										<i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i> {client.fullName} has not filled out any intake forms.
									</p>
								</div>
							}

							{client.upcomingEvents && client.upcomingEvents.length > 0 &&
								<div>
									<h2>Upcoming Events</h2>
									{user && client.upcomingEvents.map((event, index) =>
										<UncontrolledAccordion className="accordion-primary past-meetings" defaultOpen={index == 0 ? "1" : ""} key={event.id}>
											<AccordionItem>
												<AccordionHeader targetId="1">
													<strong>{dateHelper.longDateFormat(event.startTime)}</strong> &nbsp;with&nbsp; <strong>{event.advisorFullName}</strong>
												</AccordionHeader>
												<AccordionBody accordionId="1">

													<div className="upcoming-buttons">
														{(new Date(event.startTime)) < twoHoursFromNow &&
															<a className="btn btn-primary btn-lg" href={event.location}>Join Meeting</a>
														}
														{(new Date(event.startTime)) >= twoHoursFromNow &&
															<a className="btn btn-secondary disabled btn-lg" href={event.location}>Join Meeting</a>
														}
														{" "}
														<a className="btn btn-light btn-lg" href={event.rescheduleUrl}>Reschedule Meeting</a>{" "}
														<a className="btn btn-light btn-lg" href={event.cancelUrl}>Cancel Meeting</a>
													</div>

													{event.isNectarine &&
														<p>
															<i className="fa-duotone fa-peach light-blue"></i> This engagement will operate under Nectarine's RIA.
														</p>
													}
													{!event.isNectarine &&
														<p>
															<i className="fa-kit-duotone fa-duotone-solid-peach-slash missing-icon"></i> This engagement will not operate under Nectarine's RIA.
														</p>
													}

													<h3>Intake Responses</h3>
													{event.intakeForms.length == 0 &&
														<div className="intake-form">{client.firstName} has not submitted an intake form before this meeting.</div>
													}

													{event.intakeForms.map((ir, index) => {
														const parsedJson = JSON.parse(ir.rawJson);

														return (
															<div className="intake-form" key={index}>
																<p><i className="fa-duotone fa-check-to-slot att-icon"></i> {client.firstName} submitted this form on{' '}
																	<strong>{dateHelper.longDateFormat(ir.submittedAt)}</strong></p>
																<ul>
																	{parsedJson.form_response.definition.fields.map((item, index) => (
																		<li key={index}>
																			<b>{item.title}</b><br />
																			{typeformParser.formatAnswer(parsedJson.form_response.answers[index])}
																		</li>
																	))}
																</ul>
															</div>
														)
													})
													}

												</AccordionBody>
											</AccordionItem>
										</UncontrolledAccordion>
									)}
								</div>


							}

							{client.pastEvents && client.pastEvents.length > 0 &&
								<div>
									<h2>Past Events</h2>
									{client.pastEvents.map((event, index) =>
										<UncontrolledAccordion className="accordion-primary past-meetings" defaultOpen={index == 0 ? "1" : ""} key={event.id}>
											<AccordionItem>
												<AccordionHeader targetId="1">
													<strong>{dateHelper.longDateFormat(event.startTime)}</strong> &nbsp;with&nbsp; <strong>{event.advisorFullName}</strong>
												</AccordionHeader>
												<AccordionBody accordionId="1">

													{event.isNectarine &&
														<p>
															<i className="fa-duotone fa-peach light-blue"></i> This engagement operated under Nectarine's RIA.
														</p>
													}
													{!event.isNectarine &&
														<p>
															<i className="fa-kit-duotone fa-duotone-solid-peach-slash missing-icon"></i> This engagement did not operate under Nectarine's RIA.
														</p>
													}


													<h3>Intake Responses</h3>
													{event.intakeForms.length == 0 &&
														<div className="intake-form">
															<i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i> {client.firstName} did not submit an intake form before this meeting.
														</div>
													}

													{event.intakeForms.map((ir, index) => {
														const parsedJson = JSON.parse(ir.rawJson);

														return (
															<div className="intake-form" key={index}>
																<p><i className="fa-duotone fa-check-to-slot att-icon"></i> {client.firstName} submitted this form on{' '}
																	<strong>{dateHelper.longDateFormat(ir.submittedAt)}</strong></p>
																<ul>
																	{parsedJson.form_response.definition.fields.map((item, index) => (
																		<li key={index}>
																			<b>{item.title}</b><br />
																			{typeformParser.formatAnswer(parsedJson.form_response.answers[index])}
																		</li>
																	))}
																</ul>
															</div>
														)
													})
													}

													{!event.notesSent &&
														<div>
															<h3>Meeting Notes</h3>
															<div className="meeting-notes"><i className="fa-duotone fa-notes missing-icon"></i> {event.advisorFullName} has not yet submitted notes for this meeting.
																<p>
																	<Link to="/dashboard/" className="btn btn-primary btn-lg mt-3">
																		<i className="fa-light fa-pen-to-square"></i>{" "}
																		Enter Notes
																	</Link>
																</p>
															</div>
														</div>
													}
													{event.notesSent &&
														<div>
															<p><i className="fa-duotone fa-notes att-icon"></i> {event.advisorFullName} last updated these notes{' '}
																<strong>{dateHelper.longDateFormat(event.notesUpdated)}</strong></p>
															<p>
																<Button color="primary" onClick={(e) => openModal(e, event)}>
																	<i className="fa-light fa-pen-to-square"></i>{" "}
																	Edit Private Notes
																</Button>
															</p>
															<h3>Private Notes</h3>
															<div className="meeting-notes" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.privateNotes) }}></div>
															<h3>Notes for Client</h3>
															<div className="meeting-notes" dangerouslySetInnerHTML={{ __html: event.publicNotes }}></div>
														</div>
													}
													<EnterMeetingNotesModal show={showEnterNotesModal} toggle={toggleEnterNotesModal} save={hardRefresh} event={modalEvent} />

													{event.recordings && event.recordings.length > 0 &&
														<div>
															<h3>Meeting Recording</h3>
															{event.recordings.map((recording, index) =>
																<div key={index}>
																	{recording && recording.status == "Active" &&
																		<div>
																			<video key={index} controls className="recording-video">
																				<source src={recording.url} type="video/mp4" />
																				Your browser does not support the video tag.
																			</video>
																			<br />
																			<a className="btn btn-light" href={recording.url}>
																				<i className="fa-solid fa-download"></i> Download ({(recording.fileSize / 1024.0 / 1024.0).toFixed(0)}MB)
																			</a>
																		</div>
																	}
																	{recording && recording.status == "Uploading" &&
																		<div>
																			<i className="fa-duotone fa-upload missing-icon"></i> The recording for this meeting is <strong>{recording.durationInMinutes}</strong> minutes long and is still being processed.
																		</div>
																	}
																</div>

															)}

														</div>

													}

												</AccordionBody>
											</AccordionItem>
										</UncontrolledAccordion>
									)}
								</div>


							}


						</div>
					}
				</Col>
			</Row>
		</Container>
	);
}
