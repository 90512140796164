import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import { ComplianceReportContent } from '../ComplianceReportContent';

async function populateComplianceReport(userID) {

	const data = await authHelper.secureGet('api/users/' + userID + "/report");
	console.log("Client:", data);
	return data;
}

export const AdminComplianceReport = () => {

	let { userID } = useParams();

	const [complianceReport, setComplianceReport] = useState(null);

	React.useEffect(() => {
		populateComplianceReport(userID).then((a) => { setComplianceReport(a); });
	}, []);

	return (
		<Container>
			<ComplianceReportContent complianceReport={complianceReport} />
		</Container >
	);
}

