import React, { useState } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, Form, Input, Label } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import { ImpersonateLink } from './Impersonate';
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';

async function getEvent(eventID) {

	const evt = await authHelper.secureGet('api/admin/events/' + eventID);
	console.log("Event:", evt);

	if (evt.advisor === null) {
		evt.advisor = {};
	}

	if (evt.consumer === null) {
		evt.consumer = {};
	}

	evt.advisorID = evt.advisor.id;
	evt.consumerID = evt.consumer.id;

	return evt;
}


export const AdminEditEvent = (props) => {

	let { eventID } = useParams();

	const [event, setEvent] = useState(null);
	const [redirectToAdminList, setRedirect] = useState(false);


	React.useEffect(() => {
		getEvent(eventID).then((a) => { setEvent(a); });
	}, []);

	const onSave = async (e) => {
		e.preventDefault();

		//Little hack to send advisor IDs back in correct spot
		event.advisor = {};
		event.advisor.id = event.advisorID;
		event.consumer = {};
		event.consumer.id = event.consumerID;

		console.log("onSave", event);

		authHelper.securePut("/api/admin/events/" + eventID, event)
			.then(response => {
				console.log("response", response);

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}

			})
			.then(() => {
				console.log("End addItem");
				setRedirect(true);
			})
			.catch(error => { alert(error); console.error('Unable to add item.', error) });

		return false;
	}

	function onFormChange(e) {
		console.log("e", e);
		const { id, type, checked, value } = e.target;
		setEvent(a => ({
			...a,
			[id]: type === 'checkbox' ? checked : value,
		}));
		console.log("onFormChange", e);
	}

	const handleCheckboxChange = (e) => {
		const { value, checked } = e.target;

		console.log("handleCheckboxChange", value, checked, e)

		setEvent(a => ({
			...a,
			[e.target.id]: checked,
		}))

		console.log("event", event);
	};

	if (redirectToAdminList) {
		return <Navigate to='/admin' />
	}

	return (
		<Container>
			<Helmet>
				<title>Edit Event</title>
			</Helmet>
			<Row>
				<Col>
					<h1>Edit Event</h1>
					<p>
						<Link to="/admin/events">
							&lt;- Back to all events
						</Link>
					</p>

					{!event && <LoadingSpinner message="Loading..." />}
					{event &&
						<Form onSubmit={onSave}>
							<Input id="id" name="id" type="hidden" value={event.id} />
							<table className="table">
								<tr>
									<td><strong>Advisor ID</strong></td>
									<td>
										<Input
											id="advisorID"
											name="advisorID"
											placeholder="Long ID goes here"
											type="text"
											value={event.advisorID}
											onChange={(e) => onFormChange(e)}
										/>
									</td>
									{event.advisor &&
										<td>
											<Link to={"/admin/users/" + event.advisor.id + "/edit"}>
												{event.advisor.fullName}
											</Link>
											<ImpersonateLink userId={event.advisor.id} />
										</td>
									}

									{!event.advisor &&
										<td>
											{event.advisorEmail}
										</td>
									}
								</tr>

								<tr>
									<td><strong>Consumer ID</strong></td>
									<td>
										<Input
											id="consumerID"
											name="consumerID"
											placeholder="Long ID goes here"
											type="text"
											value={event.consumerID}
											onChange={(e) => onFormChange(e)}
										/>
									</td>
									{event.consumer &&
										<td>
											<Link to={"/admin/users/" + event.consumer.id + "/edit"}>
												{event.consumer.fullName}
											</Link>

											<ImpersonateLink userId={event.consumer.id} /></td>
									}
									{!event.consumer &&
										<td>
											{event.consumerEmail}
										</td>
									}
								</tr>
								<tr>
									<td><strong>Created At</strong></td>

									<td>
										<Link to={"/admin/events/" + event.id + "/edit"}>
											{dateHelper.mediumDateFormat(event.createdAt)}
										</Link>
									</td>
								</tr>
								<tr>
									<td><strong>Start Time</strong></td>

									<td>{dateHelper.mediumDateFormat(event.startTime)}</td>
								</tr>
								<tr>
									<td><strong>Status</strong></td>
									<td>
										<Input id="status" name="status" type="select"
											onChange={(e) => onFormChange(e)}
											value={event.status}>
											<option value="">Choose a status...</option>
											<option value="Active">Active</option>
											<option value="Scheduled">Scheduled</option>
											<option value="Canceled">Canceled</option>
											<option value="Completed">Completed</option>
											<option value="Refunded">Refunded</option>
										</Input>
									</td>
								</tr>
								<tr>
									<td><strong>Client Paid Amount</strong></td>
									<td>
										<Input
											id="paymentAmount"
											name="paymentAmount"
											type="text"
											value={event.paymentAmount}
											onChange={(e) => onFormChange(e)}
										/>
									</td>
								</tr>
								<tr>
									<td><strong>Payment Successful</strong></td>
									<td>
										<Input
											id="paymentSuccessful"
											name="paymentSuccessful"
											type="checkbox"
											value={event.paymentSuccessful}
											checked={event.paymentSuccessful}
											onChange={(e) => onFormChange(e)}
										/>
										{
											!event.paymentSuccessful && event.paymentAmount > 0 &&
											<span className="text-danger">
												{" "}<strong>FAILED!</strong>
											</span>
										}
									</td>
								</tr>
								<tr>
									<td><strong>Zoom Link</strong></td>
									<td>
										<Input
											id="location"
											name="location"
											type="text"
											value={event.location}

											onChange={(e) => onFormChange(e)}
										/>
									</td>
								</tr>
								<tr>
									<td><i className="fa-duotone fa-peach light-blue"></i></td>
									<td>
										<Label>
											<Input
												id="isNectarine"
												name="isNectarine"
												type="checkbox"
												value={event.isNectarine}
												checked={event.isNectarine}
												onChange={(e) => handleCheckboxChange(e)}
											/>&nbsp;&nbsp;
											<i className={"fa-duotone fa-peach" + (event.isNectarine ? " light-blue" : "")}></i>
											&nbsp;<strong>Is Nectarine</strong> (When checked this event is operating under the Nectarine RIA)
										</Label>
									</td>
								</tr>
								<tr>
									<td><strong>Links</strong></td>

									<td>
										<a href={event.location}>Zoom</a>{" "}
										<a href={event.rescheduleUrl}>Reschedule</a>{" "}
										<a href={event.cancelUrl}>Cancel</a>{" "}
										{event.consumer && event.advisor &&
											<a href={"/leaveareview/" + event.advisor.id + "/" + event.consumer.id}>Review Page</a>
										}
									</td>
								</tr>


							</table>
							<Button color="primary">
								Save
							</Button>
						</Form>
					}
				</Col>
			</Row>
		</Container >
	);
}
