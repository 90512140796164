import React, { Component, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import globeImage from "./../img/sitting-earth.png";




export class NonUnitedStates extends Component {
	static displayName = NonUnitedStates.name;


	render() {

		var applyNowLink = "https://hellonectarine.com/apply";

		return (
			<Container>
				<Helmet>
					<title>Advice-Only Financial Advisors - Nectarine</title>
				</Helmet>

		
				<Row className="py-lg-5">
					<Col lg="2"/>
					<Col lg="3" className="px-3">
						<img src={globeImage} className="booked-image mx-auto d-block" alt="Illustrated Person Sitting on Globe" />
						</Col>
					<Col lg="5" className="text-center my-xxl-auto">
							<h2>Live outside of the US?</h2>
							<p>
							At the moment we are only licensed to provide advice to US residents, but we plan to expand in the future. Join the waitlist to get notified when Nectarine is available in your country!
							</p>
								<div>
							<a href={"https://hellonectarine.typeform.com/to/Xmvv9ltF"} className="btn btn-primary">Join the waitlist</a>
								</div>
							
						</Col>
						<Col lg="2"></Col>
					</Row>

				</Container>

		);
	}
}