import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { AdminPayments } from "./Payments";

export const AdminAffiliatePayments = (props) => {

	const getPayPeriods = () => {
		const currentDate = new Date('2023-10-01T00:00:00'); // First day of first ever pay period
		const today = new Date(); // Current date
		const payPeriods = [];

		while (currentDate < today) {
			const lastDayOfMonth = new Date(currentDate);
			lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
			lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);

			const period = {
				start: new Date(currentDate),
				end: new Date(lastDayOfMonth)
			};
			payPeriods.push(period);

			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		// Sort pay periods in descending order
		payPeriods.sort((a, b) => b.start - a.start);
		return payPeriods;
	};

	return (
		<Container>
			<Helmet>
				<title>Nectarine - Affiliate Payments</title>
			</Helmet>
			<Row>
				<Col>
					<Link to="/admin"><i className="fa-regular fa-arrow-left back-arrow"></i> Back</Link>
					<h1>Affiliate Payments</h1>

					<AdminPayments
						userTitle="Affiliate"
						payPeriods={getPayPeriods()}
					/>
				</Col>
			</Row>
		</Container>
	);
}
