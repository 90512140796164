import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import authHelper from '../Helpers/AuthorizeHelper';
import authService from '../api-authorization/AuthorizeService';

async function populateActiveUser() {
	var data = authHelper.secureGet('api/account/user');
	console.log("ActiveUser", data);
	return data;
}

export const AdminLayout = () => {

	const [user, setUser] = useState();
	const [loggedInUser, setLoggedInUser] = useState();
	const navigate = useNavigate();

	React.useEffect(() => {
		populateActiveUser().then((a) => setUser(a));
		authService.getUser().then((a) => setLoggedInUser(a));
	}, []);

	const onSearch = (event) => {
		event.preventDefault(); // Prevent default form submission

		const formData = new FormData(event.target);
		const searchQuery = formData.get('q');

		navigate(`/admin/search?q=${encodeURIComponent(searchQuery)}`);
	};



	//const [isAuthenticated, loggedInUser] = await Promise.all([authService.isAuthenticated(),]);
	console.log("Logged in", loggedInUser);

	return (
		<div>
			<div className="impersonate-label">
				{loggedInUser && user && loggedInUser.name != user.userName && <strong>Impersonating {user.firstName}</strong>}
			</div>
			<div className="admin-menu">
				<Link to="/admin">Admin Home</Link> |{" "}
				<Link to="/account">Account Home</Link> |{" "}
				<Link to="/dashboard">Advisor Home</Link>
				<form onSubmit={onSearch}>
					<input className="admin-search" type="text" name="q" placeholder="Search name, email, ID, IP address, etc..." />
				</form>
			</div>
			<div>
			</div>
			<Outlet />
		</div >
	);
}
