import React, { useState } from 'react';
import {
	Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form, Alert
} from 'reactstrap';
import { LoadingSpinner, LoadingDots } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';

async function populateData() {
	const data = await authHelper.secureGet('api/advisor/sharedattributes');
	return data;
}

export const EditAttributes = (props) => {

	const [advisor, setAdvisor] = useState(props.advisor);
	const [errorSection, setErrorSection] = useState({ show: false, text: null });
	const [sharedAttributes, setSharedAttributes] = useState(null);
	const [selectedAttributes, setSelectedAttributes] = useState([]);
	const [saveAttributesLoading, setSaveAttributesLoading] = useState(false);

	React.useEffect(() => {
		populateData().then((a) => { setSharedAttributes(a); });

		//Populate selected attiributes based on advisor sharedAttribute IDs
		const allAttributes = advisor.iCanHelpYouWith.map((a) => a.id).concat(advisor.iEnjoyWorkingWith.map((a) => a.id));
		setSelectedAttributes(allAttributes);

	}, []);

	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;
		if (checked) {
			setSelectedAttributes((prevSelected) => [...prevSelected, value]);

		} else {
			setSelectedAttributes((prevSelected) =>
				prevSelected.filter((att) => att !== value)
			);
		}
	};

	const onSave = async (event) => {
		event.preventDefault()

		setSaveAttributesLoading(true);

		authHelper.securePut("/api/advisor/sharedattributes", selectedAttributes)
			.then(response => {

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}

			})
			.then(() => {
				setSaveAttributesLoading(false);
				props.save(advisor);
			})
			.catch(error => {
				showError(error);
				setSaveAttributesLoading(false);
			});

		return false;
	}

	function showError(text) {
		let errorSection = {};

		errorSection.show = true;
		errorSection.text = text.toString();

		setErrorSection(errorSection);
	}

	function hideError() {
		setErrorSection({ show: false });
	}


	return (
		<Modal isOpen={props.show} toggle={props.toggle}>
			<Form onSubmit={onSave}>
				<ModalHeader toggle={props.toggle}>Edit Experience Details</ModalHeader>
				<ModalBody>
					{errorSection.show &&
						<Alert color="danger">
							{errorSection.text}
						</Alert>
					}


					<h3>I Can Help You With (Choose up to 8)</h3>
					{sharedAttributes &&
						<Row>
							{sharedAttributes.iCanHelpYouWith.sort((a, b) => (a.title > b.title) ? 1 : -1).map(item => (
								<Col key={item.id} sm="6" xs="12">
									<Input type="checkbox"
										id={item.id}
										value={item.id}
										checked={selectedAttributes.includes(item.id)}
										onChange={handleCheckboxChange} />{" "}
									<Label for={item.id}>
										<i className={"fa-light att-icon " + item.iconTag}></i>{" "}
										{item.title}
									</Label>
								</Col>
							))}
						</Row>
					}

					<h3>I Enjoy Working With (Choose up to 6)</h3>
					{sharedAttributes &&
						<Row>
							{sharedAttributes.iEnjoyWorkingWith.sort((a, b) => (a.title > b.title) ? 1 : -1).map(item => (
								<Col key={item.id} sm="6" xs="12">
									<Input type="checkbox"
										id={item.id}
										value={item.id}
										checked={selectedAttributes.includes(item.id)}
										onChange={handleCheckboxChange} />{" "}
									<Label for={item.id}>
										<i className={"fa-light att-icon " + item.iconTag}></i>{" "}
										{item.title}
									</Label>
								</Col>
							))}
						</Row>
					}


				</ModalBody>
				<ModalFooter>
					<Button color="primary" disabled={saveAttributesLoading}>
						{saveAttributesLoading ? <LoadingDots /> : "Save Details"}
					</Button>{' '}
				</ModalFooter>
			</Form>
		</Modal>

	);
}
