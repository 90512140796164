import React, { useState } from 'react';
import { LandingPageTemplate } from './LandingPageTemplate';

export const LandingPage_FlatFeeFinancialPlanners = (props) => {


	const blurb = <div>
		<p>
			<strong>No Surprises:</strong> Clear and predictable pricing, so you always know what to expect.
		</p>
		<p>
			<strong>Transparency:</strong> We believe in total transparency of fees, ensuring you only pay our one up front price.
		</p>
		<p>
			<strong>Customized Solutions:</strong> Our flat-fee structure allows for personalized financial planning tailored to your unique goals and situation.
		</p>
		<p>
			<strong>Financial Empowerment:</strong> Pay for just the advice you need without any pressure of an ongoing commitment. It's your financial journey, and you're in control.
		</p>
	</div>;

	return (
		<LandingPageTemplate
			title="Flat-Fee Financial Planners"
			plural="flat-fee financial planners"
			singular="flat-fee financial planner"
			blurb={blurb}
		/>
	);
}
