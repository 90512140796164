import React, { useState } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, Form, Input } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import { LoadingDots } from "../LoadingAnimations";
import { ImpersonateLink } from './Impersonate';
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import authService from '../api-authorization/AuthorizeService';
import dateHelper from '../Helpers/DateHelper';

async function getRecording(recordingID) {

	const recording = await authHelper.secureGet('api/admin/recordings/' + recordingID);

	if (recording.event) {
		recording.eventId = recording.event.id;
	}
	console.log("Recording:", recording);

	return recording;
}


export const AdminEditRecording = (props) => {

	let { recordingID } = useParams();

	const [recording, setRecording] = useState(null);
	const [redirectToAdminList, setRedirect] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [isSaving, setIsSaving] = useState(false);

	React.useEffect(() => {
		getRecording(recordingID).then((a) => { setRecording(a); });
	}, []);


	const handleFileSelect = (event) => {
		setSelectedFile(event.target.files[0]);
		console.log("SelectedFile!", selectedFile);
	};

	const handleFileUpload = async (event) => {

		event.preventDefault();

		setIsSaving(true);

		if (selectedFile) {
			console.log("handleFileUpload - SelectedFile!", selectedFile);


			const formData = new FormData();

			console.log("handleFileUpload formData", formData);

			var url = '/api/zoom/manualuploadrecording/' + recording.id;

			console.log("url", url);
			formData.append('file', selectedFile);

			const token = await authService.getAccessToken();

			fetch(url, {
				method: 'POST',
				body: formData,
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			})
				.then(response => {
					console.log("handleFileUpload response", response);

					if (!response.ok) {
						console.error("Nectarine Error", response);
						return response.text().then(text => { throw new Error(response.statusText) })
					}

				})
				.then((data) => {
					console.log("handleFileUpload data", data);
					// Handle response data or success message
					getRecording(recordingID).then((a) => { setRecording(a); });
					setIsSaving(false);
				})
				.catch((error) => {
					alert('Error:', error);
					// Handle error

					setIsSaving(false);
				});
		}
	};



	const onSave = async (e) => {
		e.preventDefault();

		console.log("onSave", recording);

		if (!recording.event) {
			recording.event = {};
		}
		recording.event.id = recording.eventId;

		console.log("onSave json", recording);

		authHelper.securePut("/api/admin/recordings/" + recordingID, recording)
			.then(response => {
				console.log("response", response);

				if (!response.ok) {
					console.error("Nectarine Error", response);
					return response.text().then(text => { throw new Error(text) })
				}

			})
			.then(() => {
				console.log("End addItem");
				setRedirect(true);
			})
			.catch(error => { alert(error); console.error('Unable to add item.', error) });

		return false;
	}

	function onFormChange(e) {
		console.log("e", e);
		setRecording(a => ({
			...a,
			[e.target.id]: e.target.value,
		}))
		console.log("onFormChange", e);
	}


	if (redirectToAdminList) {
		return <Navigate to='/admin/recordings' />
	}

	return (
		<Container>
			<Helmet>
				<title>Edit Recording</title>
			</Helmet>
			<Row>
				<Col>
					<Link to="/admin/recordings"><i className="fa-regular fa-arrow-left back-arrow"></i> Back</Link>
					<h1>Edit Recording</h1>

					{!recording && <LoadingSpinner message="Loading..." />}
					{recording &&
						<Form onSubmit={onSave}>
							<Input id="id" name="id" type="hidden" value={recording.id} />
							<table className="table">
								<tr>
									<td><strong>Event ID</strong></td>
									<td>
										<Input
											id="eventId"
											name="eventId"
											placeholder="Long EventID goes here"
											type="text"
											value={recording.eventId}
											onChange={(e) => onFormChange(e)}
										/>
									</td>
									{recording.event &&
										<td>
											<Link to={"/admin/events/" + recording.event.id + "/edit"}>
												<Link to={"/admin/events/" + recording.event.id + "/edit"}>
													{recording.event.advisorEmail} + {recording.event.consumerEmail}
												</Link>
											</Link>
										</td>
									}
								</tr>
								<tr>
									<td><strong>Start Time</strong></td>
									<td>{dateHelper.mediumDateFormat(recording.startTime)}</td>
								</tr>
								<tr>
									<td><strong>Duration</strong></td>
									<td>{recording.durationInMinutes} minutes</td>
								</tr>
								<tr>
									<td><strong>Size</strong></td>
									<td>{(recording.fileSize / 1024.0 / 1024.0).toFixed(1)}MB</td>
								</tr>
								<tr>
									<td><strong>File Extension</strong></td>
									<td>{recording.fileExtension}</td>
								</tr>
								<tr>
									<td><strong>Host Email</strong></td>

									<td>{recording.hostEmail}</td>
								</tr>
								<tr>
									<td><strong>Status</strong></td>
									<td>
										{recording.status}
									</td>
								</tr>
								<tr>
									<td><strong>Links</strong></td>

									<td>
										<a href={recording.playUrl}>Play</a>{" "}
										<a href={recording.downloadUrl}>Download</a>{" "}
										<a href={recording.url}>S3</a>
									</td>
								</tr>
							</table>
							<Button color="primary">
								Save
							</Button>
						</Form>
					}

					<hr />
					<h1>Upload a New Video</h1>
					<p>
						Uploading a video using the button below will replace the existing video if it exists and update the status.
						Not that this <span className="text-danger">does not</span> update the duration, file size, extension, etc. It's to be used
						only to move the correct Zoom file to S3. This may take like 5+ minutes for a 400MBish file depending on your internet speed.
					</p>
					<div>
						<Form onSubmit={handleFileUpload}>
							<Input type="file" onChange={handleFileSelect} />

							<Button color="primary" className="btn-lg">{isSaving ? <LoadingDots /> : "Upload"}</Button>
						</Form>
					</div>

				</Col>
			</Row>
		</Container >
	);
}
