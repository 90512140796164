import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'reactstrap'
import barbellImage from './../img/barbell-guy.png'
import { BeforeYourMeeting } from './BeforeYourMeeting'
import authHelper from './Helpers/AuthorizeHelper'

async function populateActiveUser() {
  return authHelper.secureGet('api/account/user')
}

export const Booked = () => {
  const [user, setUser] = useState(null)

  React.useEffect(() => {
    populateActiveUser().then((a) => setUser(a))
  }, [])

  return (
    <Container className="booked-page">
      <Helmet>
        <title>Your Appointment Has Been Booked! Nectarine</title>
      </Helmet>
      <Row>
        <Col lg="2" />
        <Col lg="3">
          <img src={barbellImage} className="booked-image mx-auto d-block" alt="Illustrated Man Lifting Barbell" />
        </Col>
        <Col lg="5" className="text-center px-4">
          <h1>Your appointment has been booked!</h1>
          <p>
            We’ve sent a calendar invite to your email! Please fill out the short intake form below so your advisor can prepare for your
            meeting.
          </p>
          {!user && <p>Loading...</p>}
          {user && (
            <div>
              <a
                href={'https://hellonectarine.typeform.com/to/QdrDHmPg#email=' + user.email + '&name=' + user.firstName}
                className="btn btn-lg btn-primary"
              >
                Fill Out Intake Form
              </a>
            </div>
          )}
        </Col>
        <Col lg="2"></Col>
      </Row>
      <Row>
        <Col lg="3" md="1"></Col>
        <Col lg="6" md="10">
          <BeforeYourMeeting />
        </Col>
        <Col lg="3" md="1"></Col>
      </Row>
    </Container>
  )
}
