import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';

async function populateData() {
	const data = await authHelper.secureGet('api/admin/orphanintakeforms');
	console.log("Data:", data);
	return data;
}


export const AdminIntakeForms = (props) => {

	const [data, setData] = useState(null);

	React.useEffect(() => {
		populateData().then((a) => { setData(a); });
	}, []);


	return (
		<Container>
			<Helmet>
				<title>Nectarine - Intake Forms</title>
			</Helmet>
			<Row>
				<Col>
					<h1>Intake Forms</h1>

					{!data && <LoadingSpinner message="Loading..." />}
					{data &&
						<ul>
							{data.map(item => (
								<li key={item.id}>{item.email}</li>
							))}
						</ul>
					}
				</Col>
			</Row>
		</Container>
	);
}
