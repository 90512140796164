import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, UncontrolledTooltip } from 'reactstrap';
import { LoadingSpinner } from './LoadingAnimations';
import { DisclaimerRow } from './DisclaimerRow';
import { FilterResultsModal } from './FilterResultsModal';
import { NotFound } from './404';
import { SortResultsDropDown } from './SortResultsDropDown';
import dateHelper from './Helpers/DateHelper';
import './AdvisorResults.css';
import { orderBy } from 'lodash';

async function populateAdvisors(urlState) {
  let query = new URLSearchParams(window.location.search);

  if (urlState) {
    query.set('state', urlState);
  }

  // Convert the query object to a string and build the full API endpoint
  const queryString = query.toString() ? `?${query.toString()}` : '';
  const apiEndpoint = `/api/advisorresults${queryString}`;

  const response = await fetch(apiEndpoint);

  if (response.status === 204) {
    return null;
  }

  const data = await response.json();

  return data;
}

export const AdvisorsResults = (props) => {
  const navigate = useNavigate();
  const filteredAttributesSessionKey = 'filteredAttributes';

  const [advisors, setAdvisors] = useState(null);
  const [filteredAdvisors, setFilteredAdvisors] = useState(null);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [noMatch, setNoMatch] = React.useState(null);
  const [filterModal, setFilterModal] = useState(false);
  const [allAttributes, setAllAttributes] = useState(null);

  const toggleFilterModal = () => setFilterModal(!filterModal);

  let { urlState, singleFilter } = useParams();
  let now = new Date();

  var threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  var stateTitle = '';

  if (urlState) {
    stateTitle = urlState
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  function getSavedFilteredAttributes() {
    //First check if there's something in the URL in the filters parameter
    const urlParams = new URLSearchParams(window.location.search);
    var filters = urlParams.get('filters');

    if (!filters && singleFilter) {
      filters = singleFilter;
    }

    if (filters) {
      const fa = filters.split(' ');
      return fa;
    }

    return [];
  }

  React.useEffect(() => {
    setFilteredAttributes(getSavedFilteredAttributes());
    populateAdvisors(urlState).then((a) => {
      handleAdvisorsResponse(a);
    });

    // Function to handle popstate event
    const handlePopState = () => {
      setFilteredAttributes(getSavedFilteredAttributes());
    };

    // Event listener for popstate event
    window.addEventListener('popstate', handlePopState);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  React.useEffect(() => {
    setFilteredAdvisors(getMatchingAdvisors(filteredAttributes, advisors));
  }, [advisors]);

  React.useEffect(() => {
    sessionStorage.setItem(filteredAttributesSessionKey, filteredAttributes);
    setFilteredAdvisors(getMatchingAdvisors(filteredAttributes, advisors));
  }, [filteredAttributes]);

  function handleAdvisorsResponse(advisors) {
    if (advisors == null) {
      setNoMatch(true);
    } else {
      setAdvisors(advisors);
      setFilteredAdvisors(advisors);

      //Get the list of all attributes
      const allSet = new Set();
      var all = [];

      // Iterate through advisors and their items
      advisors.forEach((advisor) => {
        advisor.iCanHelpYouWith.forEach((item) => {
          if (!allSet.has(item.slug)) {
            allSet.add(item.slug);
            all.push(item);
          }
        });

        advisor.iEnjoyWorkingWith.forEach((item) => {
          if (!allSet.has(item.slug)) {
            allSet.add(item.slug);
            all.push(item);
          }
        });
      });

      setAllAttributes(all);
    }
  }

  function toTitleCase(str) {
    return str
      .split(' ') // Split the string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a single string
  }

  function getTitleTag() {
    //If there's exactly one filtered attribute, return a custom title tag
    if (allAttributes && filteredAttributes && filteredAttributes.length == 1) {
      const attribute = allAttributes.find((item) => item.slug === filteredAttributes[0]);

      if (attribute) {
        return toTitleCase(attribute.title) + ' Fiduciary Flat-Fee Financial Advisors';
      }
    }

    return getTitle() + ' ' + getSubtitle();
  }

  function getTitle() {
    if (!advisors || !stateTitle) {
      return 'Fiduciary Flat-Fee Financial Advisors';
    }

    return stateTitle + ' Fiduciary Flat-Fee Financial Advisors';
  }

  function getSubtitle() {
    if (!filteredAttributes || filteredAttributes.length == 0 || !allAttributes) {
      return '';
    }

    //Loop through allAttributes and get the titles of the selected attributes
    const filteredAttributesTitles = allAttributes.filter((item) => filteredAttributes.includes(item.slug)).map((item) => item.title);

    //Loop through filteredAttributeTitles and make them all Title Case
    filteredAttributesTitles.forEach((item, index) => {
      filteredAttributesTitles[index] = toTitleCase(item);
    });

    //Sort filteredAttributesTitles alphabetically
    filteredAttributesTitles.sort();

    return 'Specializing in ' + filteredAttributesTitles.join(', ');
  }

  //Remove anything after /advisors/XXXX
  function trimPathname(pathname) {
    let pathParts = pathname.split('/');

    if (pathParts.length < 2) return pathname;

    //State search should maintain the state name
    if (pathParts[1] == 's' && pathParts.length > 2) {
      return `/${pathParts[1]}/${pathParts[2]}`;
    }

    if (pathParts.length > 0) {
      return `/${pathParts[1]}`;
    }
    return pathname; // Return the original pathname if it doesn't match the pattern
  }

  function filtersSaved(event, incomingAdvisors, incomingAttributes) {
    event.preventDefault();
    setFilterModal(false);
    setFilteredAdvisors(incomingAdvisors);
    setFilteredAttributes(incomingAttributes);

    //Update the URL to reflect the selected attributes, like ?filters=real-estate+retirement
    var newUrl = trimPathname(window.location.pathname);

    if (incomingAttributes.length == 1) {
      newUrl += `/${incomingAttributes[0]}`;
    } else if (incomingAttributes.length > 0) {
      newUrl += `?filters=${incomingAttributes.join('+')}`;
    }

    navigate(newUrl);
    return false;
  }

  function getMatchingAdvisors(selectedAttributes, advisors) {
    //If no selected attributes, show all advisors
    if (!advisors || !selectedAttributes || selectedAttributes.length == 0) {
      return advisors;
    }

    const matchingAdvisors = advisors.filter((currAdvisior) => {
      return [...currAdvisior.iCanHelpYouWith, ...currAdvisior.iEnjoyWorkingWith].some((v) => selectedAttributes.includes(v.slug));
    });

    return matchingAdvisors;
  }

  function getMatchingAdvisorAttributes(advisor) {
    //If no selected attributes, show all advisors
    if (!filteredAttributes || !filteredAttributes.length) {
      return [...advisor.iCanHelpYouWith, ...advisor.iEnjoyWorkingWith];
    }

    return [...advisor.iCanHelpYouWith, ...advisor.iEnjoyWorkingWith].filter((v) => filteredAttributes.includes(v.slug));
  }

  function areAdvisorAttributesAPerfectMatchWithFilteredAttributes(advisor, selectedAttributes) {
    const advisorAttributes = [...advisor.iCanHelpYouWith, ...advisor.iEnjoyWorkingWith];
    return selectedAttributes.every((v) => advisorAttributes.some((m) => m.slug === v));
  }

  function getSortedAdvisors(filteredAdvisors) {
    //Get the sort order from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const sort = urlParams.get('sort');

    const orderByFunctions = [];
    const orderByDirections = [];

    // We want to show advisors who best matched the filters, if they're set
    if (filteredAttributes.length) {
      orderByFunctions.push((currAdvisor) => {
        const numberOfMatchingFilters = [...currAdvisor.iCanHelpYouWith, ...currAdvisor.iEnjoyWorkingWith].filter((v) =>
          filteredAttributes.includes(v.slug)
        ).length;

        // Multiple it by -1 so it sorts descending
        return numberOfMatchingFilters;
      });
      orderByDirections.push('desc');
    }

    if (sort === 'price') {
      orderByFunctions.push((v) => v.hourlyRate);
      orderByDirections.push('asc');
    } else {
      orderByFunctions.push((v) => new Date(v.nextAvailability));
      orderByDirections.push('asc');
    }

    orderByFunctions.push((v) => v.reviewAverage);
    orderByDirections.push('desc');

    // Sort by last name, then first
    orderByFunctions.push((v) => `${v.lastName} ${v.firstName} `);
    orderByDirections.push('asc');

    return orderBy(filteredAdvisors, orderByFunctions, orderByDirections);
  }

  function getSearchResultsSortingText() {
    let message = ' sorted by ';

    const urlParams = new URLSearchParams(window.location.search);
    const sort = urlParams.get('sort');
    const sortString = sort === 'price' ? 'lowest price' : 'next available';

    if (filteredAttributes.length) {
      message += `best match / `;
    }

    message += `${sortString}.`;

    return message;
  }

  function getCountOfAdvisorsWhoMatchFiltersPerfectly() {
    return filteredAdvisors.filter((v) => areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, filteredAttributes)).length;
  }

  function getCountOfAdvisorsWhoDoNotMatchFiltersPerfectly() {
    return filteredAdvisors.filter((v) => !areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, filteredAttributes)).length;
  }

  function getAdvisorsDisplayTemplate(advisorsType) {
    const arrayToDisplay =
      advisorsType === 'perfect-match'
        ? filteredAdvisors.filter((v) => areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, filteredAttributes))
        : filteredAdvisors.filter((v) => !areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, filteredAttributes));

    return (
      <Row className="advisor-results-row">
        {getSortedAdvisors(arrayToDisplay).map((advisor) => (
          <Col sm="4" className="advisor-results-cell" key={advisor.id}>
            <div className="advisor-image">
              <Link to={'/advisor/' + advisor.slug}>
                <img
                  src={advisor.profilePicUrl}
                  className="circle-image headshot"
                  alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
                />
              </Link>
            </div>
            <div className="results-content">
              <h1 className="results-name">
                <Link to={'/advisor/' + advisor.slug}>
                  {advisor.firstName} {advisor.lastName}
                  {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ', ' : ''}
                  {advisor.certifications}
                </Link>
              </h1>
              <h2>{advisor.title}</h2>
              <div className="results-reviews">
                {advisor.reviewAverage > 0 && (
                  <span href="#" id="AverageReviewTooltip">
                    <i className="fa-star fa-solid"></i> {advisor.reviewAverage.toFixed(1)} ({advisor.reviewCount})
                  </span>
                )}
                {!advisor.showReviews && advisor.reviewCount > 0 && (
                  <span href="#" id={'CantShowReviewsTooltip-' + advisor.id}>
                    <i className="fa-star fa-solid"></i> <i className="fa-duotone fa-eye-slash"></i> ({advisor.reviewCount})
                    <UncontrolledTooltip target={'CantShowReviewsTooltip-' + advisor.id}>
                      {advisor.firstName} has received {advisor.reviewCount} review
                      {advisor.reviewCount > 1 && 's'} but {advisor.reviewCount > 1 ? 'they are' : 'it is'} hidden due to state regulations
                      not allowing the posting of testimonials
                    </UncontrolledTooltip>
                  </span>
                )}
                {advisor.eventCount > 0 && (
                  <span href="#" id="EventCountTooltip">
                    <i className="fa-calendar fa-solid"></i>&nbsp; {advisor.eventCount}
                  </span>
                )}
                {new Date(advisor.createdAt) > threeMonthsAgo && (
                  <span>
                    <i class="fa-star fa-solid light-blue"></i>&nbsp;Newly Added!
                  </span>
                )}
              </div>
              <ul>
                {advisor.bio.split('\n').map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>

              {filteredAttributes && (
                <div>
                  {filteredAttributes.length ? (
                    <>
                      {filteredAttributes.length > 1 &&
                      areAdvisorAttributesAPerfectMatchWithFilteredAttributes(advisor, filteredAttributes) ? (
                        <>
                          <i className={'fa-solid att-icon matches-all-filters-icon fa-circle-check'}></i>
                          <span className="ms-2">Matches All Filters</span>
                        </>
                      ) : (
                        <></>
                      )}

                      {getMatchingAdvisorAttributes(advisor).map((currAttribute) => (
                        <div key={currAttribute.slug}>
                          <i className={'fa-light att-icon results-att ' + currAttribute.iconTag}></i> {currAttribute.title}
                        </div>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              <div>
                <span className="results-rate">${Math.round(advisor.hourlyRate).toLocaleString('en-US')}</span>
                <span className="results-product"> / one hour</span>
              </div>
              <div>
                <Link className="btn btn-light results-btn center" to={'/advisor/' + advisor.slug}>
                  <span className="view-profile">View Profile</span>

                  {new Date(advisor.nextAvailability) > now && (
                    <span className="next-available">
                      <br />
                      Next Available: {dateHelper.casualDateFormat(advisor.nextAvailability)}
                    </span>
                  )}
                </Link>
              </div>
            </div>
          </Col>
        ))}

        <span href="#" id="AverageReviewTooltip" className="d-none">
          <i className="fa-star fa-solid"></i>
        </span>
        <span href="#" id="EventCountTooltip" className="d-none">
          <i className="fa-calendar fa-solid"></i>
        </span>
        <UncontrolledTooltip target="EventCountTooltip">Number of meetings this advisor has hosted on Nectarine</UncontrolledTooltip>
        <UncontrolledTooltip target="AverageReviewTooltip">Average review score left for this advisor</UncontrolledTooltip>
      </Row>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>{getTitleTag()}</title>
      </Helmet>
      <Row>
        {noMatch && <NotFound />}
        {!noMatch && !advisors && (
          <Container>
            <LoadingSpinner />
          </Container>
        )}
        {filteredAdvisors && (
          <Container>
            <Row className="match-title">
              <h1>{getTitle()}</h1>
              <h2>{getSubtitle()}</h2>
            </Row>
            <Row>
              <Col xs="12" className="filters-row d-flex justify-content-center center mb-3">
                <Button color="light" onClick={toggleFilterModal}>
                  <i className="fa-duotone fa-sliders"></i> Filters
                  {filteredAttributes && filteredAttributes.length > 0 && (
                    <span>
                      {' '}
                      (<strong>{filteredAttributes.length}</strong>)
                    </span>
                  )}
                </Button>{' '}
                <FilterResultsModal
                  show={filterModal}
                  toggle={toggleFilterModal}
                  advisors={advisors}
                  selectedAttributes={filteredAttributes}
                  getMatchingAdvisors={getMatchingAdvisors}
                  areAdvisorAttributesAPerfectMatchWithFilteredAttributes={areAdvisorAttributesAPerfectMatchWithFilteredAttributes}
                  onSave={filtersSaved}
                />
                <SortResultsDropDown />
              </Col>
            </Row>
            <Row>
              <Col className="center mb-5">
                Showing <strong>{filteredAdvisors.length}</strong> matching advisor{filteredAdvisors.length != 1 ? 's' : ''}.
              </Col>
            </Row>

            {getCountOfAdvisorsWhoMatchFiltersPerfectly() > 0 ? (
              <>
                {filteredAttributes.length > 1 ? (
                  <h2 className="mb-3 mb-4 text-center">Advisors Matching All Filters ({getCountOfAdvisorsWhoMatchFiltersPerfectly()}) </h2>
                ) : (
                  <></>
                )}

                {getAdvisorsDisplayTemplate('perfect-match')}

                {/* On mobile, display a horizontal line, to break up the visual continuity, so the user is aware they're in a new "section" of data */}
                <div className="d-md-none">
                  <hr />
                  <div className="mb-5"></div>
                </div>
              </>
            ) : (
              <></>
            )}

            {getCountOfAdvisorsWhoDoNotMatchFiltersPerfectly() > 0 ? (
              <>
                {filteredAttributes.length > 1 ? (
                  <h2 className="mt-3 mb-4 text-center">
                    Advisors Matching Some Filters ({getCountOfAdvisorsWhoDoNotMatchFiltersPerfectly()})
                  </h2>
                ) : (
                  <></>
                )}
                {getAdvisorsDisplayTemplate('partial-match')}
              </>
            ) : (
              <></>
            )}

            <DisclaimerRow />
          </Container>
        )}
      </Row>
    </Container>
  );
};
