import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	Form,
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import heroImage from "../../img/investing-experts.png";
import { DisclaimerRow } from "../DisclaimerRow";
import stateHelper from '../Helpers/StateHelper';
import authHelper from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FAQs } from '../HomeElements/FAQs';

import '../Home.css';

async function populateDashboard() {
	const data = await authHelper.secureGet('api/home/dashboard');
	console.log("Dashboard:", data);
	return data;
}


async function populateActiveUser() {
	return authHelper.secureGet('api/account/user');
}

export const LandingPageTemplate = (props) => {

	let navigate = useNavigate();
	const [dashboard, setDashboard] = useState(null);
	const now = new Date();

	const defaultState = localStorage.getItem('userState') ? localStorage.getItem('userState') : '';
	const [state, setState] = useState(defaultState);

	const testimonialData = [
		{
			slug: "tommy-modec",
			client: "Julie R.",
			advisor: "Tommy Modec",
			text: <p>Tommy is our "guy" now, <b>we won't be looking for advice from anyone else</b> going forward. If you're on the fence about booking a meeting, just do it. Nectarine is seriously the BEST!</p>,
		},
		{
			slug: "michelle-onaka",
			client: "Romana C.",
			advisor: "Michelle Onaka",
			text: <p>Meeting with Michelle was an <b>outstanding experience</b>. In just an hour, we covered a tremendous amount of ground. I left the meeting with a much <b>greater peace</b> of mind about my finances.</p>,
		},
		{
			slug: "kelly-palmer",
			client: "Nicole M.",
			advisor: "Kelly Palmer",
			text: <p>This was incredibly informative and helpful -- and left me with a <b>clear plan for next steps</b>. So grateful to find an advisory option that does not commit me to investment products or services. Highly recommend!</p>,
		},
	];

	React.useEffect(() => {
		populateActiveUser().then((a) => {
			!state && a && setState(a.state);
		});
		populateDashboard().then((a) => { setDashboard(a); });
	}, []);

	function onSearchClick(e) {
		e.preventDefault();

		if (state == "international") {
			navigate('/non-us');
			return;
		}

		const urlState = stateHelper.GetStateNameForUrl(state);
		navigate('/s/' + urlState);
	}

	const onStateChange = (e) => {
		setState(e.target.value);
		localStorage.setItem('userState', e.target.value);
	}

	function printPriceRange() {

		if (!dashboard) {
			return <Skeleton width={80} count={1} inline={true} />
		}

		if (dashboard.priceRange.low === dashboard.priceRange.high) {
			return `$${Math.round(dashboard.priceRange.low).toLocaleString('en-US')}`
		}

		return `$${Math.round(dashboard.priceRange.low).toLocaleString('en-US')}-$${Math.round(dashboard.priceRange.high).toLocaleString('en-US')}`
	}

	return (
		<Container className="home-page">
			<Helmet>
				<title>{props.title || "Flat-Fee Hourly Fiduciary Financial Advisors"} - Nectarine</title>
			</Helmet>
			<Row className="hero-row" md="2" sm="1" xs="1">
				<Col>
					<h1 id="home">{props.title || "Just Investing Advice"}<span className="light-blue">.</span></h1>
					<h3>We don’t manage your money, sell any products, or earn any commissions. Pay for just the advice.</h3>
					<div className="cta">
						<p>From
							<strong>
								{' '}{printPriceRange()}
							</strong>
							/hour,{" "}
							<strong className="green">no strings attached.</strong>
						</p>
						<Form onSubmit={onSearchClick}>
							<select name="state" className="state-search" required onChange={onStateChange} value={state}>
								<option value="" disabled>Which state do you live in?</option>
								<option value="international">I live outside the US</option>
								{stateHelper.usStatesAndTerritories.map(state => (
									<option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>
								))}
							</select>
							<Button color="primary" size="lg" className="search-button">Find your {props.singular}</Button>
						</Form>
					</div>
				</Col>
				<Col>
					<img src={heroImage} className="hero center" alt="Nectarine Investing Experts Illustration" />
				</Col>
			</Row>
			{props.disclaimer &&
				<Row>
					<Col className="affiliate-disclaimer">
						{props.disclaimer}
					</Col>
				</Row>
			}

			<Row className="home-section-head">
				<h2 id="howwecanhelp">How we can help</h2>
				<p>
					Meet with an expert US-based {props.singular} and get advice on various areas of finance and investing.
				</p>
			</Row>
			<Row className="how-we-can-help gy-4" md="4" sm="2" xs="2">
				<Col>
					<i className="fa-duotone fa-solid fa-island-tropical"></i>
					<p>Retirement planning</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-chart-pie"></i>
					<p>Portfolio review</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-people-roof"></i>
					<p>Estate planning</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-money-bill-transfer"></i>
					<p>401k rollovers</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-file-invoice-dollar"></i>
					<p>Tax planning</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-display-chart-up-circle-dollar"></i>
					<p>Getting started with investing</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-baby-carriage"></i>
					<p>Investing for kids</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-circle-plus"></i>
					<p>...and more!</p>
				</Col>
			</Row>
			<Row className="home-section-head">
				<h2 id="howwecanhelp">Our expert {props.plural}</h2>
				<p>
					All Nectarine {props.plural} are registered and licensed fiduciaries who have been vetted for their for their stellar knowledge, competency, and investment philosophy.
					We have accepted <strong>less than 10%</strong> of the {props.plural} who have applied to join Nectarine!
				</p>
			</Row>

			{!dashboard &&
				<Row className="our-experts g-4 mb-4" md="3" sm="1" xs="1">
					<Col><Skeleton width={400} height={580} /></Col>
					<Col><Skeleton width={400} height={580} /></Col>
					<Col><Skeleton width={400} height={580} /></Col>
				</Row>
			}
			{dashboard &&
				<Row className="our-experts g-4" md="3" sm="1" xs="1">
					{dashboard.advisors.map(advisor => (
						<Col key={advisor.id}>
							<div className="advisor-home-cell d-flex flex-column h-100">
								<div className="advisor-home-image">
									<Link to={"/advisor/" + advisor.slug}>
										<img src={advisor.profilePicUrl}
											className="circle-image headshot"
											alt={advisor.firstName + " " + advisor.lastName + ", " + advisor.certifications} />
									</Link>
								</div>
								<h1 className="results-name">
									<Link to={"/advisor/" + advisor.slug}>
										{advisor.firstName} {advisor.lastName}
										{advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ", " : ""}
										{advisor.certifications}
									</Link>
								</h1>
								<Row>
									<Col><h2>{advisor.title}</h2></Col>
									<Col className="text-end">
										<div className="results-reviews">
											{advisor.reviewAverage > 0 &&
												<span href="#" id="AverageReviewTooltip">
													<i className="fa-star fa-solid"></i> {advisor.reviewAverage.toFixed(1)} ({advisor.reviewCount})
												</span>
											}
										</div>

									</Col>
								</Row>

								<ul>
									{advisor.bio.split('\n').map((item, key) => (
										<li key={key}>{item}</li>
									))}
								</ul>
								<div className="mt-auto">
									<div>
										<span className="results-rate">${Math.round(advisor.hourlyRate).toLocaleString('en-US')}</span>
										<span className="results-product"> / one hour</span>
									</div>
									<div>
										<Link className="btn btn-light results-btn center" to={"/advisor/" + advisor.slug}>
											<span className="view-profile">View Profile</span>

											{(new Date(advisor.nextAvailability)) > now &&
												<span className="next-available"><br />Next Available: {dateHelper.casualDateFormat(advisor.nextAvailability)}</span>
											}
										</Link>
									</div>
								</div>
							</div>
						</Col>
					))}

				</Row>
			}
			<Row className="center mt-4">
				<Col>
					<Link className="btn btn-primary btn-lg" to="/advisors">View all {props.plural}</Link>
				</Col>
			</Row>


			<Row className="home-section-head">
				<h2 id="howitworks">How it works</h2>
			</Row>
			<Row className="how-it-works" md="3" sm="1" xs="1">
				<Col>
					<div className="how-it-works-step">
						<i className="fa-solid fa-arrow-pointer hiw-icon"></i>
						<h3>1. Select</h3>
						<p>
							Choose from a list of vetted & licensed {props.plural}.
						</p>
					</div>
				</Col>
				<Col>
					<div className="how-it-works-step">
						<i className="fa-duotone fa-solid fa-calendar-lines-pen hiw-icon"></i>
						<h3>2. Schedule</h3>
						<p>
							Pick a time to meet with your {props.singular} and pay for the session.
						</p>
					</div>
				</Col>
				<Col>
					<div className="how-it-works-step">
						<i className="fa-duotone fa-solid fa-comments hiw-icon"></i>
						<h3>3. Get advice</h3>
						<p>
							Video chat, share your screen, and get your questions answered.
						</p>
					</div>
				</Col>
			</Row>
			{props.blurb &&
				<Row>
					<h2>Why {props.title || "Advice-Only Financial Advisors"}</h2>
					<div className="text-start">
						{props.blurb}
					</div>
				</Row>
			}



			<Row className="home-section-head">
				<h2 id="howwecanhelp">What they're saying</h2>
				{dashboard &&
					<p>
						Nectarine advisors have served <strong className="light-blue">{dashboard.meetingsCompleted.toLocaleString()}</strong>{" "}
						clients and have received an average review of <strong className="light-blue">{dashboard.averageReview.toFixed(2)}</strong> stars!
					</p>
				}
				{!dashboard &&
					<p>
						<Skeleton count={0.75} />
					</p>
				}
				<div className="home-stars">
					<i className="fa-solid fa-star"></i>
					<i className="fa-solid fa-star"></i>
					<i className="fa-solid fa-star"></i>
					<i className="fa-solid fa-star"></i>
					<i className="fa-solid fa-star"></i>
				</div>
			</Row>
			<Row>
				{testimonialData.map(testimonial => (
					<Col lg="4" md="12" className="mb-4" key={testimonial.slug}>
						<div className="testimonial d-flex flex-column h-100">
							{!dashboard &&
								<div className="testimonial-pics mb-3">
									<Skeleton width={90} height={90} circle={true} inline={true} />
									<Skeleton width={90} height={90} circle={true} inline={true} />
								</div>
							}
							{dashboard &&
								<div className="testimonial-pics mb-3">
									<Row>
										<Col className="text-end">
											<i className="fa-duotone fa-solid fa-circle-user"></i>
										</Col>
										<Col className="text-start">
											<Link to={"/advisor/" + testimonial.slug}>
												<img src={dashboard.profilePics[testimonial.slug]}
													className="circle-image headshot" />
											</Link>
										</Col>
									</Row>

								</div>
							}
							<div className="testimonial-stars">
								<i className="fa-solid fa-star"></i>
								<i className="fa-solid fa-star"></i>
								<i className="fa-solid fa-star"></i>
								<i className="fa-solid fa-star"></i>
								<i className="fa-solid fa-star"></i>
							</div>
							{testimonial.text}
							<p>
								<strong>{testimonial.client}</strong> met with <Link to={"/advisor/" + testimonial.slug}>{testimonial.advisor}</Link>
							</p>
						</div>
					</Col>
				))}
			</Row>
			<DisclaimerRow />

			<Row className="home-section-head">
				<h2 id="howwecanhelp">Our Philosophy</h2>
				<p>
					We never sell any products or manage your money so our only incentive is doing the <strong>best for you</strong>.
				</p>
			</Row>
			<Row className="how-we-can-help g-4" md="5" sm="2" xs="2">
				<Col>
					<i className="fa-duotone fa-solid fa-money-bill-trend-up"></i>
					<p>Buy &amp; hold index funds</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-cash-register"></i>
					<p>Minimize fees</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-list-check"></i>
					<p>Minimize taxes</p>
				</Col>
				<Col>
					<i className="fa-duotone fa-solid fa-circle-check"></i>
					<p>Keep it simple</p>
				</Col>
				<Col sm="12" xs="12">
					<i className="fa-duotone fa-solid fa-scale-balanced"></i>
					<p>Do the right thing</p>
				</Col>
			</Row>


			<Row className="home-section-head">
				<h2 id="pricing">Pricing</h2>
				<p>Simple flat-fee options to get <strong>1:1 customized advice</strong> from a dedicated fiduciary with no hidden fees,
					management fees, or ulterior motives.</p>
			</Row>
			<Row className="pricing">
				<Col xxl="2" xl="12"></Col>
				<Col className="price-box" xxl="4" xl="6" xs="12">
					<div className="price-header">
						<h3>The Hour</h3>
						<p>Share a screen and ask an expert {props.singular} your investing questions for an hour.</p>
					</div>

					<div className="price-details">
						<ul className="plan-features">
							<li>1 hour engagement</li>
							<li>1 meeting</li>
							<li>1 recap email with an actionable to-do list</li>
							<li>1:1 customized advice</li>
							<li>No commissions</li>
							<li>No products</li>
							<li>No sales pitch</li>
							<li>No recurring fees</li>
						</ul>
						<p>
							This is for you if you have questions on 1-2 topics about your investments
							or want to check-in with an expert to make sure you're on the right track.
						</p>
						<hr />
						<h2 className="center">
							<span className="price">
								{printPriceRange()}
							</span>
							<span className="hour"> / one hour</span>
						</h2>


						<Form onSubmit={onSearchClick}>
							<select name="state" className="state-search center" required onChange={onStateChange} value={state}>
								<option value="" disabled>Which state do you live in?</option>
								<option value="international">I live outside the US</option>
								{stateHelper.usStatesAndTerritories.map(state => (
									<option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>
								))}
							</select>
							<Button color="primary" size="lg" className="search-button center">Find your {props.singular}</Button>
						</Form>
					</div>
				</Col>
				<Col className="price-box" xxl="4" xl="6" xs="12">
					<div className="price-header">
						<h3>The Plan</h3>
						<p>An 8 week program to achieve a strategic plan for your financial life.</p>
					</div>

					<div className="price-details">
						<ul className="plan-features">
							<li>8 week engagement consisting of:</li>
							<li>3 face to face meetings</li>
							<li>5 hours of document review</li>
							<li>16 hours expert planning &amp; analysis</li>
							<li>4+ hours of implementation help</li>
							<li>Unlimited email support</li>
							<li><strong>28+</strong> hours total {props.singular} expertise</li>
							<li>Custom delivered financial plan</li>
						</ul>
						<p>This is for you if you want a comprehensive analysis of your financial life, Roth conversions, estate plan,
							life insurance, equity compensation, real estate, retirement & everything in between.
						</p>
						<div className="price-footer">
							<hr />
							<h2 className="center">
								<span className="price">$2,500</span>
								<span className="hour"> / eight weeks</span>
							</h2>

							<a href="https://calendly.com/nectarine-shane-sideris/nectarine-financial-plan-chat" className="btn btn-lg btn-primary cta-link">Book a free chat</a>
						</div>
					</div>
				</Col>
				<Col xxl="2" xl="12"></Col>
			</Row>

			<Row className="home-section-head">
				<h2>Our promise</h2>
				<p>Nectarine is committed to doing the right thing and prioritizing your best interests.</p>
			</Row>
			<Row className="how-it-works" md="3" sm="1" xs="1">
				<Col>
					<div className="how-it-works-step">
						<i className="fa-duotone fa-badge-check hiw-icon"></i>
						<h3>Our pledge</h3>
						<p>
							If you’re not satisfied with your meeting, we’ll give you <b>your money back</b>.
						</p>
					</div>
				</Col>
				<Col>
					<div className="how-it-works-step">
						<i className="fa-duotone fa-solid fa-calendar hiw-icon"></i>
						<h3>Flexible scheduling</h3>
						<p>
							<b>Free</b> reschedules &amp; cancellations with 24 hours notice.
						</p>
					</div>
				</Col>
				<Col>
					<div className="how-it-works-step">
						<i className="fa-duotone fa-solid fa-person-chalkboard hiw-icon"></i>
						<h3>No sales pitch</h3>
						<p>
							We’ll <b>never pitch</b> any investment or insurance products.
						</p>
					</div>
				</Col>
			</Row>

			<Row className="home-section-head">
				<h2>Frequently Asked Questions</h2>
			</Row>
			<FAQs />

			<Row className="center last-button">
				<Form onSubmit={onSearchClick}>
					<select name="state" className="state-search" required onChange={onStateChange} value={state}>
						<option value="" disabled>Which state do you live in?</option>
						<option value="international">I live outside the US</option>
						{stateHelper.usStatesAndTerritories.map(state => (
							<option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>
						))}
					</select><br />
					<Button color="primary" size="lg" className="search-button">Find your {props.singular}</Button>
				</Form>
				<div>
					<Link to="/non-us" className="cta-link">Live outside the US?</Link>
				</div>

			</Row>
		</Container >
	);
}
