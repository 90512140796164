import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import { Outlet } from 'react-router-dom';
import './Layout.css';

export const Layout = ({ children }) => {

	return (
		<div>
			<NavMenu />
			<Container tag="main">
				<Outlet />
				{children}
			</Container>
			<Footer />
		</div>
	);
}

