import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes } from './../constants';
import wateringImage from './../img/watering-plants.png';
import authHelper from './Helpers/AuthorizeHelper';
import stateHelper from './Helpers/StateHelper';
import { LoadingSpinner } from './LoadingAnimations';
import authService from './api-authorization/AuthorizeService';

async function populateAdvisors(urlState) {
  //Just hardcoding CA for now until we have a no state search
  var query = '/api/advisorresults?state=California';

  const response = await fetch(query);

  if (response.status === 204) {
    return null;
  }

  const data = await response.json();

  //Sort data by first name
  data.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

  return data;
}

async function populateAdvisor(slug) {
  const advisor = await authHelper.secureGet('api/users/slug/' + slug);

  if (!advisor || advisor.length === 0) {
    return null;
  }

  return advisor;
}

export const ThePlanTerms = (props) => {
  const [advisor, setAdvisor] = useState(null);
  const [advisors, setAdvisors] = useState(null);
  const [data, setData] = useState(null);
  const [state, setState] = useState(localStorage.getItem('userState') ?? undefined);
  const [errorSection, setErrorSection] = useState({ show: false, text: null });
  const [success, setSuccess] = useState(false);
  const [agreeIsChecked, setAgreeIsChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  let { slug } = useParams();
  let navigate = useNavigate();

  React.useEffect(() => {
    const getAuthStatus = async () => {
      const isAuthenticatedResult = await authService.isAuthenticated();
      setIsAuthenticated(isAuthenticatedResult);
    };

    getAuthStatus();
  }, []);

  React.useEffect(() => {
    if (!slug) {
      populateAdvisors().then((a) => {
        setAdvisors(a);
      });
    } else {
      populateAdvisor(slug).then((a) => {
        setAdvisor(a);
      });
    }
  }, []);

  function onAgreeChecked(e) {
    setAgreeIsChecked(e.target.checked);
  }

  const onStateChange = (e) => {
    setState(e.target.value);
    localStorage.setItem('userState', e.target.value);
  };

  function docSeparator(i, length) {
    if (i == 0 && length == 2) return <span> and </span>;
    if (i < length - 2) return <span>, </span>;
    if (i < length - 1) return <span>, and </span>;
    return <span> </span>;
  }

  function onAdvisorClick(e, advisorSlug) {
    e.preventDefault();
    populateAdvisor(advisorSlug).then((a) => {
      setAdvisor(a);
    });

    navigate(`${ApplicationRoutes.ThePlan_Terms}/${advisorSlug}`);

    return false;
  }

  function showError(text) {
    let errorSection = {};
    errorSection.show = true;
    errorSection.text = text.toString();
    setErrorSection(errorSection);
  }

  function isAgreeButtonEnabled() {
    return agreeIsChecked && isAuthenticated;
  }

  function hideError() {
    setErrorSection({ show: false });
  }

  async function onAgreeToTermsSubmit(e, state) {
    e.preventDefault();
    hideError();

    const agreeToTerms = { StateAbbreviation: state, Product: 'ThePlan' };

    try {
      const agreeToTermsResponse = await authHelper.securePost('/api/users/agreetoterms/' + advisor.id, agreeToTerms);

      if (!agreeToTermsResponse.ok) {
        const json = await agreeToTermsResponse.json();

        if (json.notLoggedIn) {
          setIsAuthenticated(false);
          hideError();
        } else {
          throw new Error(json);
        }
      } else {
        setSuccess(true);
      }
    } catch (error) {
      showError(error.message);
    }

    return false;
  }

  return (
    <Container>
      <Helmet>
        <title>Nectarine - The Plan - Agree To Terms</title>
      </Helmet>
      <Row>
        <Col md="3">
          <img src={wateringImage} className="" alt="Watering plants" style={{ width: '80%' }} />
        </Col>
        {success && (
          <Col>
            <h1>Thank you!</h1>
            <p>Your agreements have been logged and you're ready for the plan. We look forward to meeting with you!</p>
            <p>You may now close this window.</p>
          </Col>
        )}
        {advisor && !success && (
          <Col md="9" className="center pt-5">
            <Form onSubmit={(e) => onAgreeToTermsSubmit(e, state)}>
              <h1>Nectarine Contract</h1>
              <img
                src={advisor.profilePicUrl}
                className="circle-image headshot center small-headshot"
                alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
              />
              <h3>
                {advisor.firstName} {advisor.lastName}
                {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ', ' : ''}
                {advisor.certifications}
              </h3>
              <Label for="state" className="state-label">
                Which state do you live in?
              </Label>
              <select id="state" name="state" className="state-search" required onChange={onStateChange} value={state}>
                <option value="" defaultValue>
                  Which state do you live in?
                </option>
                {stateHelper.usStatesAndTerritories.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </select>
              <p>Please read and agree to the linked documents below so we can get started with The Plan!</p>
              <Input id="agreeToTerms" name="agreeToTerms" type="checkbox" onChange={(e) => onAgreeChecked(e)} />
              &nbsp;
              {advisor.isNectarine && (
                <Label for="agreeToTerms" className="ms-2">
                  I agree to Nectarine's
                  {Object.keys(advisor.nectarinePlanComplianceDocuments).map((i, keyIndex) => (
                    <span key={i}>
                      {' '}
                      <a href={advisor.nectarinePlanComplianceDocuments[i]['url']} target="_blank">
                        {advisor.nectarinePlanComplianceDocuments[i]['displayName']}
                      </a>
                      {docSeparator(i, advisor.nectarinePlanComplianceDocuments.length)}
                    </span>
                  ))}
                </Label>
              )}
              {!isAuthenticated ? (
                <div>
                  <Alert color="warning" className="mt-4">
                    You are not currently logged in.
                    <br />
                    Please
                    <Link to={ApplicationRoutes.Login}> log in</Link> or
                    <Link to={ApplicationRoutes.Register}> create a new account</Link> to continue.
                  </Alert>
                </div>
              ) : (
                <></>
              )}
              {errorSection.show && (
                <Alert color="danger" className="mt-4">
                  {errorSection.text}
                </Alert>
              )}
              <p className="center mt-4">
                <Button disabled={!isAgreeButtonEnabled()} color="primary" size="lg" className="schedule-btn">
                  I Agree
                </Button>
              </p>
            </Form>
          </Col>
        )}
        {!advisor && advisors && (
          <Col>
            <h1>Choose Your Advisor</h1>
            <ul>
              {advisors.map((advisor) => (
                <li key={advisor.id}>
                  <a href="#" onClick={(e) => onAdvisorClick(e, advisor.slug)}>
                    {advisor.firstName} {advisor.lastName}
                    {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ', ' : ''}
                    {advisor.certifications}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        )}
        {!advisor && !advisors && (
          <Col>
            <LoadingSpinner />
          </Col>
        )}
      </Row>
    </Container>
  );
};
