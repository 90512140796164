import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LoadingSpinner } from "../LoadingAnimations";
import {
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';
import { ImpersonateLink } from './Impersonate';


async function populateUsers() {

	const data = await authHelper.secureGet('api/users');
	console.log("Data:", data);
	return data;
}

async function populateUsersInRole(roleName) {

	const data = await authHelper.secureGet('api/users/roles/' + roleName);
	console.log("Data:", data);
	return data;
}


export const AdminUsers = () => {

	const navigate = useNavigate();

	const [users, setUsers] = useState(null);
	const [filterRole, setFilterRole] = useState(null);

	React.useEffect(() => {
		filter(null, "Advisor");
	}, []);

	const filter = (e, roleName) => {
		setUsers(null);
		setFilterRole(roleName);
		populateUsersInRole(roleName).then((a) => { setUsers(a); });
	}


	return (
		<Container>
			<Helmet>
				<title>Admin / All Users</title>
			</Helmet>
			<Row>
				<Col>
					<h1>Users</h1>
					<p>
						Show only:{" "}
						<Button color={filterRole == "Advisor" ? "info" : "light"} onClick={(e) => filter(e, "Advisor")} >Advisors</Button>{" "}
						<Button color={filterRole == "Affiliate" ? "info" : "light"} onClick={(e) => filter(e, "Affiliate")} >Affiliates</Button>{" "}
						<Button color={filterRole == "Consumer" ? "info" : "light"} onClick={(e) => filter(e, "Consumer")} >Consumers</Button>{" "}
						<Button color={filterRole == "Admin" ? "info" : "light"} onClick={(e) => filter(e, "Admin")} >Admins</Button>{" "}
						<Button color={filterRole == "All" ? "info" : "light"} onClick={(e) => populateUsers().then((a) => { setUsers(a); setFilterRole("All") })} >All</Button>{" "}
					</p>

					{users &&
						<p>Showing <strong>{users.length}</strong> results</p>
					}

					<table className="table admin-users">
						<thead>
							<tr>
								<th>Last</th>
								<th>First</th>
								<th>Email</th>
								<th>State</th>
								{filterRole == "Advisor" &&
									<th><i class="fa-duotone fa-peach light-blue"></i></th>
								}
								{filterRole == "Advisor" &&
									<th>Next Available</th>
								}
								{(filterRole == "Consumer" || filterRole == "Affiliate") &&
									<th>Report</th>
								}
								<th>Impersonate</th>
							</tr>
						</thead>
						{!users && <tbody><tr><td colSpan="99"><LoadingSpinner message="Loading..." /></td></tr></tbody>}
						{users &&
							<tbody>
								{users.map(user => (
									<tr key={user.id}>
										<td>
											<Link to={"/admin/users/" + user.id + "/edit"}>
												{user.formattedLastName}
											</Link>
										</td>
										<td>
											<Link to={"/admin/users/" + user.id + "/edit"}>
												{user.formattedFirstName}
											</Link>
										</td>
										<td>
											<Link to={"/admin/users/" + user.id + "/edit"}>
												{user.email}
											</Link>
										</td>
										<td>
											{user.state}
										</td>
										{filterRole == "Advisor" &&
											<td>
												{user.isNectarine ? <i class="fa-duotone fa-peach light-blue"></i> : ""}
											</td>
										}
										{filterRole == "Advisor" &&
											<td>
												{(new Date(user.nextAvailability)) > (new Date()) &&
													<span>{dateHelper.casualDateFormat(user.nextAvailability)}</span>
												}
											</td>
										}
										{(filterRole == "Consumer" || filterRole == "Affiliate") &&
											<td>
												<Link to={"/admin/users/" + user.id + "/report"}>
													Compliance Report
												</Link>
											</td>
										}
										<td>
											<ImpersonateLink userId={user.id} />
										</td>
									</tr>
								))}
							</tbody>
						}
					</table>
				</Col>
			</Row>
		</Container>
	);
}
