import React, { Component } from 'react';

export class LoadingSpinner extends Component {
	static displayName = LoadingSpinner.name;

	constructor(props) {
		super(props);
	}


	render() {
		return (
			<div className="full-screen-loading">
				<div className="lds-ripple"><div></div><div></div></div>
				<h1>{this.props.message}</h1>
			</div>
		);
	}
}

export class LoadingDots extends Component {
	static displayName = LoadingDots.name;

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
		);
	}
}

