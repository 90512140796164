import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "./LoadingAnimations";
import UAParser from 'ua-parser-js';
import dateHelper from './Helpers/DateHelper';
import typeformParser from './Helpers/TypeformParser';
import stateHelper from './Helpers/StateHelper';
import DOMPurify from 'dompurify';

export const ComplianceReportContent = ({ complianceReport }) => {

	const handlePrint = (e) => {
		e.preventDefault();
		window.print();
		return false;
	};

	return (
		<Container>
			<Helmet>
				{complianceReport && complianceReport.client &&
					<title>Nectarine Compliance Report - {complianceReport.client.fullName}</title>
				}
			</Helmet>
			<Row>
				{!complianceReport && <LoadingSpinner message="Loading Compliance Report..." />}
				{complianceReport && !complianceReport.client &&
					<div>
						<h1>Uh oh</h1>
						<p>
							It looks like this client hasn't agreed to your documents. Please contact support.
						</p>
					</div>

				}
				{complianceReport && complianceReport.client &&
					<Col className="compliance-report">
						<h1><span className="thin">Compliance Report</span> | <strong>{complianceReport.client.fullName}</strong></h1>
						<hr />

						{complianceReport.userAgreements && complianceReport.userAgreements.map(ua => {
							const browser = new UAParser().setUA(ua.userAgent).getResult();
							return (
								<div key={ua.id}>
									<p>
										On <b>{dateHelper.longDateFormat(ua.agreedAtTime)}</b>, <b>{ua.firstName} {ua.lastName}</b> using
										the email address <b>{ua.emailAddress}</b> from IP
										address <b>{ua.ipAddress}</b> using
										browser <b>{browser.browser.name}</b> version <b>{browser.browser.version}</b> on <b>{browser.os.name} {browser.os.version}</b> with
										a <b>{browser.device.vendor} {browser.device.type} {browser.device.model}</b> device

										{
											ua.stateAbbreviation &&
											<span> reported their state of residence
												as <strong>{stateHelper.GetStateNameFromAbbreviation(ua.stateAbbreviation)}</strong> and
											</span>
										}

										{" "}checked agree
										to the following terms and conditions:
									</p>
									<ul>
										{ua.complianceDocuments.map(doc => (
											<li key={doc.id}><b>
												{doc.advisorFullName && !doc.isNectarine &&
													<span>{doc.advisorFullName}</span>
												}
												{
													doc.isNectarine &&
													<span>Nectarine</span>
												}
												{" "}{doc.displayName}</b>{" "}

												uploaded <b>{new Date(doc.timestamp).toLocaleDateString('en-US')}</b> located
												at <a href={doc.url}>{doc.url}</a>
											</li>
										))}
									</ul>
									<hr />

								</div>
							)
						})
						}

						<h1 className="page-break"><span className="thin">Intake</span> <strong>Forms</strong></h1>
						<hr />
						{complianceReport.intakeResponses && complianceReport.intakeResponses.length === 0 &&
							<p>
								{complianceReport.client.fullName} has not filled out any intake forms.
							</p>
						}
						{complianceReport.intakeResponses && complianceReport.intakeResponses.map(ir => {

							const parsedJson = JSON.parse(ir.rawJson);
							console.log("parsedJson", parsedJson);

							return (

								<div key={ir.id}>
									<p>On <b>{dateHelper.longDateFormat(ir.submittedAt)}</b>, <strong>{complianceReport.client.fullName}</strong> filled
										out the following intake form.
									</p>

									<ul>
										{parsedJson.form_response.definition.fields.map((item, index) => (
											<li key={index}>
												<b>{item.title}</b><br />
												{typeformParser.formatAnswer(parsedJson.form_response.answers[index])}
											</li>
										))}
									</ul>

								</div>


							)
						})}

						<h1 className="page-break"><span className="thin">Client</span> <strong>Meetings</strong> <span className="thin">({complianceReport.events && complianceReport.events.length})</span></h1>
						<hr />
						{complianceReport.events && complianceReport.events.length === 0 &&
							<p>
								{complianceReport.client.fullName} has not had any meetings.
							</p>
						}
						{complianceReport.events && complianceReport.events.map(event => {
							return (
								<div key={event.id}>
									<p>On <b>{dateHelper.longDateFormat(event.startTime)}</b>, <strong>{complianceReport.client.fullName}</strong> had
										a meeting with <strong>{event.advisorFullName}</strong> at <strong>{event.location}</strong> and paid <strong>${event.paymentAmount}</strong> for this meeting.
									</p>
									{event.status === "Refunded" &&
										<p>The payment for this meeting was <strong>refunded</strong> to the client.</p>
									}
									{!event.isNectarine &&
										<p><i className="fa-kit-duotone fa-duotone-solid-peach-slash missing-icon"></i> Nectarine served as a <strong>promoter</strong> for a third party RIA for this engagement.</p>
									}


									{!event.notesSent &&
										<p>
											No notes have been sent to the client for this meeting.
										</p>
									}
									{event.notesSent &&
										<div>
											<p><i className="fa-duotone fa-notes att-icon"></i> {event.advisorFullName} first sent these notes on{" "}
												<strong>{dateHelper.longDateFormat(event.notesSentAt)}</strong> and last updated these notes{' '}
												<strong>{dateHelper.longDateFormat(event.notesUpdated)}</strong></p>
											<h3>Private Notes</h3>
											<div className="meeting-notes" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.privateNotes) }}></div>
											<h3>Notes for Client</h3>
											<div className="meeting-notes" dangerouslySetInnerHTML={{ __html: event.publicNotes }}></div>
										</div>
									}


									<hr />
								</div>
							)
						})}


						<div className="compliance-menu">
							<Link to={"/dashboard"}>
								My Account
							</Link> | <a href="#" onClick={handlePrint}>Print This Page</a>
						</div>

					</Col>
				}

			</Row>
		</Container>
	);
}
