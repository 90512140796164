import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';
import { EnterMeetingNotesModal } from "./EnterMeetingNotesModal";


async function populateDashboardData() {

	const data = await authHelper.secureGet('api/advisor/dashboard');
	console.log("Dashboard:", data);

	//Log the total size of data payload to the console
	console.log("Data size:", JSON.stringify(data).length);

	return data;
}


export const AdvisorIndex = () => {

	const [users, setUsers] = useState(null);
	const [upcomingEvents, setUpcomingEvents] = useState(null);
	const [eventsNeedingNotes, setEventsNeedingNotes] = useState(null);
	const [showEnterNotesModal, setShowEnterNotesModal] = useState(false);
	const [modalEvent, setModalEvent] = useState(null);

	const openModal = (e, event) => {
		setModalEvent(event);
		setShowEnterNotesModal(true);
	}

	const toggleEnterNotesModal = () => {
		setShowEnterNotesModal(!showEnterNotesModal);
	}

	function hardRefresh() {
		populateDashboardData().then((d) => {
			setUsers(d.clients);
			setUpcomingEvents(d.upcomingEvents);
			setEventsNeedingNotes(d.eventsNeedingNotes);
		});
		setShowEnterNotesModal(false);
	}

	React.useEffect(() => {
		hardRefresh();
	}, []);

	//Make a const date that's two hours from now
	const currentDateTime = new Date();
	const twoHoursFromNow = new Date(currentDateTime.getTime() + 2 * 60 * 60 * 1000);


	return (
		<Container>
			<Helmet>
				<title>Nectarine - My Account</title>
			</Helmet>
			<Row md="12">
				<Col>
					<h1>Upcoming Events</h1>

					{!upcomingEvents && <LoadingSpinner message="Loading..." />}
					{upcomingEvents && upcomingEvents.length == 0 &&
						<p>
							You have no upcoming events.
						</p>
					}
					{upcomingEvents && upcomingEvents.length > 0 &&
						<div>
							<table className="table advisor-events">
								<thead>
									<tr>
										<th>
											<i className="fa-duotone fa-peach light-blue"></i>
										</th>
										<th>Name</th>
										<th>Email</th>
										<th>State</th>
										<th>Meeting Time</th>
										<th>Intake Form</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{upcomingEvents.map(event => (
										<tr key={event.id}>
											<td>
												{event.isNectarine &&
													<i className="fa-duotone fa-peach light-blue"></i>
												}
											</td>
											<td>
												<Link to={"/dashboard/client/" + event.consumerId}>
													{event.consumerLastName}, {event.consumerFirstName}
												</Link>
												{event.eventCount > 1 &&
													<span> ({event.eventCount})</span>
												}
											</td>
											<td>
												{event.consumerEmail}
											</td>
											<td>
												{event.consumerState}
											</td>
											<td>
												{dateHelper.mediumDateFormat(event.startTime)}
											</td>
											<td>
												{event.intakeForm &&
													<i className="fa-duotone fa-check-to-slot att-icon"></i>
												}
												{!event.intakeForm &&
													<i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i>
												}
											</td>
											<td>
												{(new Date(event.startTime)) < twoHoursFromNow &&
													<a className="btn btn-primary" href={event.location}>Join Meeting</a>
												}
												{(new Date(event.startTime)) >= twoHoursFromNow &&
													<a className="btn btn-secondary disabled" href={event.location}>Join Meeting</a>
												}
											</td>
										</tr>
									))}
								</tbody>
							</table>

							<p>
								Events will show the <i className="fa-duotone fa-peach light-blue"></i> icon {" "}
								when they are being operated under Nectarine's RIA.

							</p>
						</div>
					}

					{!eventsNeedingNotes && <LoadingSpinner message="Loading..." />}
					{eventsNeedingNotes && eventsNeedingNotes.length > 0 &&
						<div>
							<h1>Notes to enter</h1>
							<p>The meetings below still need your notes recorded.</p>

							<table className="table advisor-events">
								<thead>
									<tr>
										<th>Name</th>
										<th>Email</th>
										<th>State</th>
										<th>Meeting Time</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{eventsNeedingNotes.map(event => (
										<tr key={event.id}>
											<td>
												<Link to={"/dashboard/client/" + event.consumerId}>
													{event.consumerLastName}, {event.consumerFirstName}
												</Link>
											</td>
											<td>
												{event.consumerEmail}
											</td>
											<td>
												{event.consumerState}
											</td>
											<td>
												{dateHelper.mediumDateFormat(event.startTime)}
											</td>
											<td>

												<Button color="primary" onClick={(e) => openModal(e, event)}>
													<i className="fa-light fa-pen-to-square"></i>{" "}
													Enter Notes
												</Button>


											</td>
										</tr>
									))}
								</tbody>
							</table>
							<EnterMeetingNotesModal show={showEnterNotesModal} toggle={toggleEnterNotesModal} save={hardRefresh} event={modalEvent} />
						</div>
					}

					<h1>My Clients</h1>

					{!users && <LoadingSpinner message="Loading..." />}
					{users && users.length == 0 &&
						<p>
							You have not yet matched with any clients. When you do they'll appear here!
						</p>
					}
					{users && users.length > 0 &&
						<table className="table advisor-users">
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>State</th>
									<th>Last Meeting</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{users.map(user => (
									<tr key={user.id}>
										<td>
											<Link to={"/dashboard/client/" + user.id}>
												{user.lastName}, {user.firstName}
											</Link>
											{user.eventCount > 1 &&
												<span> ({user.eventCount})</span>
											}
										</td>
										<td>
											{user.email}
										</td>
										<td>
											{user.state}
										</td>
										<td>
											{
												dateHelper.mediumDateFormat(user.mostRecentEventStart)
											}
										</td>
										<td>
											<Link to={"/dashboard/compliance/" + user.id + "/report"}>
												Compliance Report
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					}

				</Col>
			</Row>
		</Container>
	);
}
