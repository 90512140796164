import authService from '../api-authorization/AuthorizeService';

export class RolesHelper {

	async getRoles() {

		var user = await authService.getUser();

		if (!user) {
			return [];
		}

		var roles = user.role;

		if (!Array.isArray(user.role)) {
			roles = [user.role];
		}

		return roles;
	}
}


const rolesHelper = new RolesHelper();
export default rolesHelper;
