import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "./LoadingAnimations";
import { ReviewForm } from "./ReviewForm";
import authHelper, { AuthorizeHelper } from './Helpers/AuthorizeHelper';


function getUserID(userType) {

	//URL should be of the format hellonectarine.com/leaveareview/[ADVISORID]/[CONSUMERID]
	//For testing: https://localhost:44455/leaveareview/10f0bbc6-dec0-4b49-8c4c-ad5de6aef755/80d12f36-e7df-42c6-b898-f0df8a776bd0
	const currentURL = window.location.href;
	const urlParts = currentURL.split('/');


	var offset;

	switch (userType) {
		case "Advisor":
			offset = 2;
			break;
		default:
			offset = 1;
			break;
	}

	const userID = urlParts[urlParts.length - offset];

	console.log("UserID", userType, userID);

	return userID;
}

async function populateUser(userType) {

	const userID = getUserID(userType);

	const user = await authHelper.secureGet('/api/home/users/' + userID);

	console.log("User:", user);

	return user;
}


export const LeaveAReview = (props) => {

	const [consumer, setConsumer] = useState(null);
	const [advisorBeingReviewed, setAdvisorBeingReviewed] = useState(null);
	const [review, setReview] = useState({}); //The review this user is editing
	const [showSavedMessage, setShowSavedMessage] = useState(false);


	React.useEffect(() => {
		populateUser("Advisor").then((a) => {
			setAdvisorBeingReviewed(a);
			setReview(prevReview => ({ ...prevReview, revieweeID: a.id }));
		});
		populateUser("Consumer").then((a) => {
			setConsumer(a);
			setReview(prevReview => ({
				...prevReview,
				reviewerID: a.id
			}));
		});
	}, []);


	const showSuccess = () => {
		setShowSavedMessage(true);
		//		setTimeout(() => setShowSavedMessage(false), 3000);
	}


	return (
		<Container>
			<Helmet>
				<title>Leave a Review - Nectarine</title>
			</Helmet>
			<Row>
				<Col lg="12" xl="2"></Col>
				<Col>
					{!(advisorBeingReviewed || consumer) && <LoadingSpinner message="Loading..." />}
					{advisorBeingReviewed && consumer && !showSavedMessage &&
						<ReviewForm
							consumer={consumer}
							advisor={advisorBeingReviewed}
							review={review}
							onSuccessfulSave={showSuccess}
							putUrl="/api/home/review/" />
					}
					{advisorBeingReviewed && showSavedMessage &&
						<div>
							<h1>Thank you!</h1>
							<p>
								Thank you so much for taking a moment to review {advisorBeingReviewed.fullName}. Your review will
								help future investors choose their best advisor!
							</p>
							{!consumer.hasRegistered &&
								<p>
									It looks like you haven't created your account yet! If you'd like to create your account visit
									our <a href="/register">registration page</a> to set your password. Your account allows you to
									book meetings with your advisor, see your reviews and more.
								</p>
							}
						</div>
					}

				</Col>
				<Col lg="12" xl="2"></Col>
			</Row>
		</Container>
	);
}
