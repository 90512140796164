import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import { EventsTable } from './EventsTable';

async function populateData() {

	const dashboard = await authHelper.secureGet('api/admin/dashboard');
	console.log("Data:", dashboard);
	return dashboard;
}

export const AdminIndex = () => {

	const [dashboardData, setDashboardData] = useState(null);

	React.useEffect(() => {
		populateData().then((a) => { setDashboardData(a); });
	}, []);


	return (
		<Container>
			<Helmet>
				<title>Admin - Nectarine</title>
			</Helmet>
			{dashboardData && dashboardData.unlinkedEvents && dashboardData.unlinkedEvents.length > 0 &&
				<Row>
					<Col>
						<h1>Action Needed!</h1>
						<p>
							There are {dashboardData.unlinkedEvents.length} events that need to be linked to a user. Please review and link them to the appropriate user.
						</p>
						<EventsTable events={dashboardData.unlinkedEvents} />
					</Col>
				</Row>
			}
			<Row>
				<Col md="6">
					<h1>Admin Links</h1>
					<ul>
						<li><Link to="/admin/users"><i className="fa-duotone fa-user admin-menu-icon"></i> Users</Link></li>
						<li><Link to="/admin/roles"><i className="fa-duotone fa-hat-cowboy admin-menu-icon"></i> Roles</Link></li>
						<li><Link to="/admin/advisorattributes"><i className="fa-duotone fa-sliders admin-menu-icon"></i> Advisor Attributes</Link></li>
						<li><Link to="/admin/documents"><i className="fa-sharp fa-regular fa-file-invoice admin-menu-icon"></i> Nectarine Compliance Docs</Link></li>
						<li><Link to="/admin/useragreements"><i className="fa-duotone fa-handshake admin-menu-icon"></i> User Agreements</Link></li>
						<li><Link to="/admin/reviews"><i className="fa-solid fa-star admin-menu-icon"></i> Reviews</Link></li>
						<li><Link to="/admin/events"><i className="fa-duotone fa-calendar-days admin-menu-icon"></i> Events</Link></li>
						<li><Link to="/admin/recordings"><i className="fa-duotone fa-film admin-menu-icon"></i> Recordings</Link></li>
						<li><Link to="/admin/intakeforms"><i className="fa-duotone fa-solid fa-person-to-door admin-menu-icon"></i> Orphan Intake Forms</Link></li>
						<li><Link to="/admin/advisorpayments"><i className="fa-duotone fa-money-bill-transfer admin-menu-icon"></i> Advisor Payments</Link></li>
						<li><Link to="/admin/affiliatepayments"><i className="fa-regular fa-money-bill-transfer admin-menu-icon"></i> Affiliate Payments</Link></li>
						<li><Link to="/admin/reports"><i className="fa-duotone fa-table admin-menu-icon"></i> Admin Reports</Link></li>
					</ul>
				</Col>
				<Col md="6">
					<h1>State Coverage</h1>

					{!dashboardData && <LoadingSpinner message="Loading..." />}
					{dashboardData && dashboardData.stateCoverage &&
						<table className="table">
							<thead>
								<tr>
									<th>State</th>
									<th>Coverage</th>
								</tr>
							</thead>
							<tbody>
								{dashboardData.stateCoverage.map(state => (

									<tr key={state.stateAbbreviation}>
										<td>
											{state.stateAbbreviation}</td>
										<td>
											{state.userCount}
										</td>
									</tr>

								))}
							</tbody>
						</table>
					}
				</Col>
			</Row>
		</Container>
	);
}
