import React, { Component, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import heroImage from "./../img/investing-experts.png";
import briefcaseImage from "./../img/person-with-briefcase.png";
import meditationImage from "./../img/meditation.png";
import bicycleImage from "./../img/bicycle-rider.png";
import joinImage from "./../img/join-icon.png";
import setupImage from "./../img/setup-icon.png";
import chatImage from "./../img/chat-icon.png";
import wateringImage from "./../img/watering-plants.png";
import './Recruit.css';

export class Recruit extends Component {
	static displayName = Recruit.name;


	render() {

		var applyNowLink = "https://hellonectarine.com/apply";

		return (
			<Container>
				<Helmet>
					<title>Advice-Only Financial Advisors - Nectarine</title>
				</Helmet>
				<Row className="hero-row" md="2" sm="1" xs="1">
					<Col>
						<h1>Focus on what you do best</h1>
						<h3>You’ll provide stellar financial advice, and we’ll take care of the rest.</h3>
						<div className="cta">
							<Button color="primary" size="lg" href={applyNowLink}>Become a Nectarine advisor</Button>
						</div>
					</Col>
					<Col>
						<img src={heroImage} className="hero center" alt="Nectarine Investing Experts Illustration" />
					</Col>
				</Row>
				<Row>
					<Col className="why-work-with-nectarine">
						<h2>Why work with Nectarine?</h2>
						<Container>
							<Row md="3" sm="1" xs="1">
								<Col>

									<img src={briefcaseImage} className="" alt="Just show up icon" />
									<h2>Just show up</h2>
									<p>
										No need for marketing or advertising. We find a good fit and send them your way.
									</p>

								</Col>
								<Col>
									<img src={bicycleImage} className="" alt="Set your own schedule icon" />
									<h2>Set your own schedule</h2>
									<p>
										Make extra income in your free time. Just tell us your available hours.
									</p>

								</Col>
								<Col>
									<img src={meditationImage} className="" alt="Less paperwork icon" />
									<h2>Less paperwork</h2>
									<p>
										Spend more time doing what matters. We’ll take care of billing, emails, and documentation.
									</p>
								</Col>

							</Row>

						</Container>
						<div className="cta">
							<Button color="primary" size="lg" href={applyNowLink}>Apply now</Button>
						</div>

					</Col>

				</Row>


				<Row>
					<Col className="how-it-works-recruit">
						<h2>How it works</h2>
						<Container>
							<Row md="3" sm="1" xs="1">
								<Col>
									<div className="how-it-works-recruit-step">
										<img src={joinImage} className="" alt="Join the platform icon" />
										<h2>Apply online</h2>
										<p>
											Join an info session and get interviewed</p>
									</div>
								</Col>
								<Col>
									<div className="how-it-works-recruit-step">
										<img src={setupImage} className="" alt="Get set up icon" />
										<h2>Get onboarded</h2>
										<p>
											Update your disclosure documents and attend a training
										</p>
									</div>
								</Col>
								<Col>
									<div className="how-it-works-recruit-step">
										<img src={chatImage} className="" alt="Advise & get paid icon" />
										<h2>Advise & get paid</h2>
										<p>
											We’ll book clients for you, you give them advice, and you get paid.
										</p>
									</div>

								</Col>
							</Row>
						</Container>
					</Col>
				</Row>

				<Row>
					<Col className="what-were-looking-for">
						<h2>What we're looking for</h2>
						<Container>
							<Row md="2" sm="1" xs="1">
								<Col>
									<div className="what-were-looking-for-step">
										<img src={wateringImage} className="" alt="Watering plants" />

									</div>
								</Col>
								<Col>
									<div className="what-were-looking-for-step">
										<p>We are recruiting the <b>industry's top advisors</b> to join our mission of making quality financial advice accessible. Let's connect if:</p>
										<ul>
											<li>You are <b>licensed to provide financial advice</b> in the U.S.</li>
											<li>You are able to <b>register or dual register</b> as a Nectarine IAR</li>
											<li>You are <b>fee-only</b> and do not offer any products</li>
											<li>You believe in buying and holding index funds, keeping things <b>simple</b>, and doing the <b>right thing</b></li>
										</ul>
										<div className="cta">
											<Button color="primary" size="lg" href={applyNowLink}>Apply now</Button>
										</div>
									</div>
								</Col>
							</Row>

						</Container>

					</Col>
				</Row>



				<Row>
					<Col className="faq">
						<h2>You may be wondering...</h2>
						<Container>
							<Row md="3" sm="1" xs="1">
								<Col>
									<div className="faq-step">
										<h2>Is Nectarine SEC registered?</h2>
										<p>Yes. Nectarine is SEC registered.</p>
									</div>
								</Col>
								<Col>
									<div className="faq-step">
										<h2>Am I an IAR under Nectarine?</h2>
										<p>Yes, you will be registered as a Nectarine IAR.</p>
									</div>
								</Col>
								<Col>
									<div className="faq-step">
										<h2>What licenses are required?</h2>
										<p>You are required to be licensed to provide financial advice in the U.S.</p>
									</div>
								</Col>

							</Row>

							<Row md="3" sm="1" xs="1">
								<Col>
									<div className="faq-step">
										<h2>I work for a firm. Am I allowed to join Nectarine?</h2>
										<p>Yes, but you'll need permission from your firm.</p>
									</div>
								</Col>
								<Col>
									<div className="faq-step">
										<h2>How much do I get paid?</h2>
										<p>You will get paid 70% of each engagement. We plan to explore other pricing models soon!</p>
									</div>
								</Col>
								<Col>
									<div className="faq-step">
										<h2>Can I get leads through Nectarine?</h2>
										<p>No, Nectarine is not to be used as a lead generation tool.</p>
									</div>

								</Col>

							</Row>

						</Container>

					</Col>
				</Row>
				<Row className="hero-row">

					<h1>Ready to be a part of something big?</h1>
					<h3>Join our mission of making financial advice affordable, simple, and accessible.</h3>
					<div className="cta">
						<Button color="primary" size="lg" href={applyNowLink}>Become a Nectarine advisor</Button>
					</div>


				</Row>
			</Container >

		);
	}
}