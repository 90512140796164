import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import logoImage from "./../img/nectarine-logo.png";

export class NavMenu extends Component {
	static displayName = NavMenu.name;

	constructor(props) {
		super(props);

		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			collapsed: true
		};
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	render() {
		return (
			<header>
				<Navbar className="home-top-navbar navbar-expand-md navbar-toggleable-md ng-white mb-3" container light>
					<NavbarBrand tag={Link} to="/"><img src={logoImage} alt="Nectarine" className="logo" /></NavbarBrand>
					<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
					<Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!this.state.collapsed} navbar>
						<ul className="navbar-nav flex-grow">
							<NavItem>
								<NavLink href="/#howitworks">How it Works</NavLink>
							</NavItem>
							<NavItem>
								<NavLink href="/#pricing">Pricing</NavLink>
							</NavItem>
							<NavItem>
								<NavLink href="/#experts">Experts</NavLink>
							</NavItem>
							<LoginMenu location="top" />
							<NavItem>
								<Button className="header-button nav-link" color="primary" href="/#home">Get Started</Button>
							</NavItem>
						</ul>
					</Collapse>
				</Navbar>
			</header>
		);
	}
}
