import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';

async function populateRecordings() {
	const data = await authHelper.secureGet('api/admin/recordings');
	console.log("Data:", data);
	return data;
}

export const AdminRecordings = (props) => {

	const [recordings, setRecordings] = useState(null);

	React.useEffect(() => {
		populateRecordings().then((a) => { setRecordings(a); });
	}, []);


	const onDelete = (e, rec) => {
		e.preventDefault();

		const isConfirmed = window.confirm("Are you sure you want to delete this recording? This is permanent and can't be undone.");

		if (!isConfirmed) {
			return false;
		}

		const updatedRecordings = [...recordings];
		const index = updatedRecordings.indexOf(rec);
		if (index !== -1) {
			updatedRecordings.splice(index, 1);
		}

		console.log("deleting", index, rec, updatedRecordings);

		authHelper.secureDelete('api/zoom/recording/' + rec.id)
			.then(response => {
				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}
			})
			.then(() => {
				setRecordings(updatedRecordings);
				alert("Recording deleted successfully.")
			})
			.catch(error => {
				alert(error);
				console.error('Unable to delete item.', error)
			});


		return false;
	}


	return (
		<Container>
			<Helmet>
				<title>Nectarine - Recordings</title>
			</Helmet>
			<Row>
				<Col>
					<Link to="/admin"><i className="fa-regular fa-arrow-left back-arrow"></i> Back</Link>
					<h1>View All Recordings</h1>

					{!recordings && <LoadingSpinner message="Loading..." />}
					{recordings &&
						<table className="table">
							<thead>
								<tr>
									<th>Start Time</th>
									<th>Duration</th>
									<th>Event</th>
									<th>Host Email</th>
									<th>Size</th>
									<th>Created At</th>
									<th>Status</th>
									<th>Links</th>
									<th className="text-danger">Danger</th>
								</tr>
							</thead>
							<tbody>
								{recordings.map(rec => (
									<tr key={rec.id}>
										<td>
											<Link to={"/admin/recordings/" + rec.id + "/edit"}>
												{dateHelper.mediumDateFormat(rec.startTime)}
											</Link>
										</td>
										<td>
											{rec.durationInMinutes} minutes
										</td>
										<td>
											{rec.event &&
												<Link to={"/admin/events/" + rec.event.id + "/edit"}>
													{rec.event.advisorEmail} + {rec.event.consumerEmail}
												</Link>
											}
											{!rec.event &&
												<strong className="red">No Linked Event!</strong>
											}
										</td>
										<td>
											{rec.hostEmail}
										</td>
										<td>
											{(rec.fileSize / 1024.0 / 1024.0).toFixed(1)}MB
										</td>
										<td>
											{dateHelper.mediumDateFormat(rec.createdAt)}
										</td>
										<td>
											{rec.status}
										</td>
										<td>
											<a href={rec.downloadUrl}>Download</a>, <a href={rec.playUrl}>Play</a>, <a href={rec.url}>S3</a>
										</td>
										<td>
											<Button color="danger" onClick={(e) => onDelete(e, rec)} >Delete</Button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					}
				</Col>
			</Row>
		</Container >
	);
}
