import React, { useState } from 'react';
import {
	Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form, Alert
} from 'reactstrap';
import { LoadingSpinner, LoadingDots } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';

export const EditBio = (props) => {

	const [saveBioLoading, setSaveBioLoading] = useState(false);
	const [advisor, setAdvisor] = useState(props.advisor);
	const [errorSection, setErrorSection] = useState({ show: false, text: null });

	React.useEffect(() => {
		advisor.experienceStartString = advisor.experienceStart.toLocaleDateString('en-CA');
	}, []);

	function onFormChange(e) {
		hideError();
		setAdvisor(a => ({
			...a,
			[e.target.id]: e.target.value,
		}))
	}

	const onSetDate = (e) => {

		var newDate = advisor.experienceStart;

		if (dateHelper.isValidDate(e.target.value)) {
			newDate = new Date(e.target.value);
		}

		setAdvisor(a => ({
			...a,
			[e.target.id]: e.target.value,
			experienceStart: newDate,
		}))
	}

	const onSave = async (event) => {
		event.preventDefault()

		setSaveBioLoading(true);

		authHelper.securePut("/api/advisor/details", advisor)
			.then(response => {

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}

			})
			.then(() => {
				setSaveBioLoading(false);
				props.save(advisor);
			})
			.catch(error => {
				showError(error);
				setSaveBioLoading(false);
			});

		return false;
	}

	function showError(text) {
		let errorSection = {};

		errorSection.show = true;
		errorSection.text = text.toString();

		setErrorSection(errorSection);
	}

	function hideError() {
		setErrorSection({ show: false });
	}


	return (
		<Modal isOpen={props.show} toggle={props.toggle}>
			<Form onSubmit={onSave}>
				<ModalHeader toggle={props.toggle}>Edit Bio</ModalHeader>
				<ModalBody>
					{errorSection.show &&
						<Alert color="danger">
							{errorSection.text}
						</Alert>
					}

					<Label>Experience Start Date</Label>
					<Input
						id="experienceStartString"
						name="experienceStartString"
						type="date"
						style={{ width: "150px" }}
						value={advisor.experienceStartString}
						onChange={(e) => onSetDate(e)}
					/>

					<Label>Bio (1,000 max characters)</Label>
					<Input
						id="longBio"
						name="LongBio"
						type="textarea"
						rows="5"
						required
						maxLength={1000}
						placeholder="e.g. Hi, I’m Shane! I'm such a personal finance nerd that I kept a budget on our family trips at age 7. After working at a Wall Street firm for eight years, I started my own financial planning firm and also became a personal finance content creator. My main goal is to make you feel more confident with your money. And I will always encourage you to pursue your dreams, whether it is being a successful entrepreneur or achieving financial independence. I’d love to learn about your story, how you got to where you are today, and how I can help!"
						value={advisor.longBio}
						onChange={(e) => onFormChange(e)}
					/>
					<Label>Personal Stuff (500 max characters)</Label>
					<Input
						id="personalBio"
						name="personalBio"
						type="textarea"
						rows="5"
						maxLength={500}
						placeholder="e.g. I’ve lived all over the US - from LA to NYC, Boston, SF, Austin, and Chicago. But this guy likes my backyard in the suburbs so I guess I’m staying put."
						value={advisor.personalBio}
						onChange={(e) => onFormChange(e)}
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" disabled={saveBioLoading}>
						{saveBioLoading ? <LoadingDots /> : "Save Bio"}
					</Button>{' '}
				</ModalFooter>
			</Form>
		</Modal>

	);
}
