import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { Home } from "./components/Home";
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';


export default class App extends Component {
	static displayName = App.name;

	render() {
		return (
			<AppRoutes />
		);
	}
}

// This originally returned
//<Routes>
//    <Route path="/" element={<Layout />}>
//        {AppRoutes.map((route, index) => {
//            const { element, requireAuth, ...rest } = route;
//            return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
//        })}
//    </Route>
//</Routes>
