import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { Alert, Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import './FilterResultsModal.css';
import { LoadingDots } from './LoadingAnimations';

export const FilterResultsModal = (props) => {
  const [saveFilterLoading, setSaveFilterLoading] = useState(false);
  const [errorSection, setErrorSection] = useState({ show: false, text: null });
  const [advisors, setAdvisors] = useState(props.advisors);
  const [iCanHelpYouWith, setICanHelpYouWith] = useState(null);
  const [iEnjoyWorkingWith, setIEnjoyWorkingWith] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState(props.selectedAttributes ? props.selectedAttributes : []);
  const [matchingAdvisors, setMatchingAdvisors] = useState([]);

  React.useEffect(() => {
    populateAvailableFilters();
  }, []);

  React.useEffect(() => {
    setMatchingAdvisors(props.getMatchingAdvisors(selectedAttributes, advisors));
  }, [selectedAttributes]);

  React.useEffect(() => {
    setSelectedAttributes(props.selectedAttributes);
  }, [props.selectedAttributes]);

  const handleCheckboxChange = (event) => {
    hideError();

    const { value, checked } = event.target;
    if (checked) {
      setSelectedAttributes((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedAttributes((prevSelected) => prevSelected.filter((att) => att !== value));
    }
  };

  function clearFilters(e) {
    e.preventDefault();
    setSelectedAttributes([]);

    return false;
  }

  function populateAvailableFilters() {
    //Go through each advisor and populate the iCanHelpYouWith array to include any attribute that any advisor has
    const allICanHelpYouWithSet = new Set();
    var allICanHelpYouWith = [];
    const allIEnjoyWorkingWithSet = new Set();
    var allIEnjoyWorkingWith = [];

    // Iterate through advisors and their items
    advisors.forEach((advisor) => {
      advisor.iCanHelpYouWith.forEach((item) => {
        if (!allICanHelpYouWithSet.has(item.slug)) {
          allICanHelpYouWithSet.add(item.slug);
          allICanHelpYouWith.push(item);
        }
      });

      advisor.iEnjoyWorkingWith.forEach((item) => {
        if (!allIEnjoyWorkingWithSet.has(item.slug)) {
          allIEnjoyWorkingWithSet.add(item.slug);
          allIEnjoyWorkingWith.push(item);
        }
      });
    });

    setICanHelpYouWith(allICanHelpYouWith);
    setIEnjoyWorkingWith(allIEnjoyWorkingWith);
  }

  const onSave = async (event) => {
    event.preventDefault();

    setSaveFilterLoading(true);
    setSaveFilterLoading(false);

    return false;
  };

  function showError(text) {
    let errorSection = {};

    errorSection.show = true;
    errorSection.text = text.toString();

    setErrorSection(errorSection);
  }

  function hideError() {
    setErrorSection({ show: false });
  }

  function getCountOfAdvisorsWhoMatchFiltersPerfectly() {
    return matchingAdvisors.filter((v) => props.areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, selectedAttributes)).length;
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <Form onSubmit={(e) => props.onSave(e, matchingAdvisors, selectedAttributes)}>
        <ModalHeader toggle={props.toggle}>Filters</ModalHeader>
        <ModalBody>
          {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}

          <h2>What do you need help with?</h2>
          {iCanHelpYouWith && (
            <Row>
              {sortBy(iCanHelpYouWith, (v) => v.title).map((item) => (
                <Col key={item.slug} sm="6" xs="12">
                  <Input
                    type="checkbox"
                    id={item.slug}
                    value={item.slug}
                    checked={selectedAttributes.includes(item.slug)}
                    onChange={handleCheckboxChange}
                  />{' '}
                  <Label for={item.slug}>
                    <i className={'fa-light att-icon results-att ' + item.iconTag}></i> {item.title}
                  </Label>
                </Col>
              ))}
            </Row>
          )}
          <h2>Show advisors who enjoy working with</h2>
          {iEnjoyWorkingWith && (
            <Row>
              {sortBy(iEnjoyWorkingWith, (v) => v.title).map((item) => (
                <Col key={item.slug} sm="6" xs="12">
                  <Input
                    type="checkbox"
                    id={item.slug}
                    value={item.slug}
                    checked={selectedAttributes.includes(item.slug)}
                    onChange={handleCheckboxChange}
                  />{' '}
                  <Label for={item.slug}>
                    <i className={'fa-light att-icon results-att ' + item.iconTag}></i> {item.title}
                  </Label>
                </Col>
              ))}
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="flex-grow-1 ms-2">
            {selectedAttributes.length > 0 ? (
              <>
                {matchingAdvisors && <strong>{matchingAdvisors.length}</strong>} Matching Advisor
                {matchingAdvisors && matchingAdvisors.length != 1 && 's'}
                {selectedAttributes.length > 1 ? (
                  <div className="perfect-filters-match-text">
                    {getCountOfAdvisorsWhoMatchFiltersPerfectly()} Perfect Match
                    {getCountOfAdvisorsWhoMatchFiltersPerfectly() === 1 ? '' : 'es'}
                  </div>
                ) : (
                  <div className="perfect-filters-match-text">&nbsp;</div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          <Button color="light" onClick={(e) => clearFilters(e)}>
            <i className="fa-duotone fa-eraser"></i> Clear Filters
          </Button>
          <Button color="primary" disabled={saveFilterLoading}>
            {saveFilterLoading ? (
              <LoadingDots />
            ) : (
              <div>
                View {matchingAdvisors && <strong>{matchingAdvisors.length}</strong>} Advisor
                {matchingAdvisors && matchingAdvisors.length != 1 && 's'}
              </div>
            )}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
