import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';


export const FAQs = (props) => {


	return (
		<Row className="details-faq mb-4">
			<UncontrolledAccordion defaultOpen={[]}>
				<AccordionItem>
					<AccordionHeader targetId="1">
						What am I allowed to ask?
					</AccordionHeader>
					<AccordionBody accordionId="1">
						You can ask anything related to investing or personal finance! We are here
						to help you put your money in the right places so you can reach your financial goals.
					</AccordionBody>
				</AccordionItem>
				<AccordionItem>
					<AccordionHeader targetId="2">
						How can I prepare for my meeting?
					</AccordionHeader>
					<AccordionBody accordionId="2">
						<p><b>The Hour:</b> You’ll fill out a short intake form so we can learn about you! We also recommend that you fill out a net worth statement (we'll show you how), know your income and expenses, and have any necessary account login information ready.</p>

						<p><b>The Plan:</b> You’ll get access to a portal where you can share your numbers, link your accounts, and upload the requested documents (we’ll share a list).</p>
					</AccordionBody>
				</AccordionItem>
				<AccordionItem>
					<AccordionHeader targetId="3">
						Do I get a summary after the meeting?
					</AccordionHeader>
					<AccordionBody accordionId="3">
						<p><b>The Hour:</b> Yes, you will receive an email summary with important takeaways and resources so you'll know what to do next.</p>

						<p><b>The Plan:</b> Yes, you will receive a 6+ page roadmap on your full financial picture with detailed information on the next steps. Your advisor will be available to help you implement any items.</p>
					</AccordionBody>
				</AccordionItem>
				<AccordionItem>
					<AccordionHeader targetId="4">
						Can I reschedule or cancel my appointment?
					</AccordionHeader>
					<AccordionBody accordionId="4">
						Yes, you can reschedule or cancel with 24 hours notice before appointment.  Just click the reschedule link in your confirmation email
						or in <Link to="/account">your Nectarine account</Link>.
					</AccordionBody>
				</AccordionItem>
				<AccordionItem>
					<AccordionHeader targetId="5">
						Will the meeting be recorded?
					</AccordionHeader>
					<AccordionBody accordionId="5">
						Yes, the meeting can be recorded for your records and made available in <Link to="/account">your Nectarine account</Link> shortly after your meeting.
						You can request for the recording to be turned off at any time or message us
						or your advisor before your meeting to request that the recording is never turned on. You can also request
						for the recording to be permanently deleted at any time.
					</AccordionBody>
				</AccordionItem>
				<AccordionItem>
					<AccordionHeader targetId="6">
						What if I am not satisfied with my meeting?
					</AccordionHeader>
					<AccordionBody accordionId="6">
						We are confident that you will have a great experience, but if it did not meet your
						expectations just let us we'll refund 100% of your money. We're trying to make you rich, not sell you junk!
					</AccordionBody>
				</AccordionItem>
			</UncontrolledAccordion>
		</Row>
	);
}


