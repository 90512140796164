import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import { ImpersonateLink } from './Impersonate';
import dateHelper from '../Helpers/DateHelper';

async function populateData() {

	const data = await authHelper.secureGet('api/admin/reviews');
	console.log("Data:", data);
	return data;
}

async function deleteReview(review) {

	const data = await authHelper.secureDelete('api/admin/reviews/' + review.id);
	console.log("Data:", data);
	return data;
}


export const AdminReviews = () => {

	const [reviews, setReviews] = useState(null);

	React.useEffect(() => {
		populateData().then((a) => { setReviews(a); });
	}, []);

	const onDelete = (review, event) => {
		event.preventDefault();

		const isConfirmed = window.confirm("Are you sure you want to delete this review? This is permanent and can't be undone.");

		if (!isConfirmed) {
			return false;
		}

		const updatedReviews = [...reviews];
		const index = updatedReviews.indexOf(review);
		if (index !== -1) {
			updatedReviews.splice(index, 1);
		}

		setReviews(updatedReviews);
		deleteReview(review);

		return false;
	}


	return (
		<Container>
			<Helmet>
				<title>Nectarine - Reviews</title>
			</Helmet>
			<Row>
				<Col>
					<h1>All Reviews</h1>

					{!reviews && <LoadingSpinner message="Loading..." />}

					{reviews &&
						<p>Showing <strong>{reviews.length}</strong> results</p>
					}

					{reviews &&
						<table className="table">
							<thead>
								<tr>
									<th>Reviewer</th>
									<th>Reviewee</th>
									<th>Rating</th>
									<th>Title</th>
									<th>Display Name</th>
									<th>Modified</th>
									<td>Danger</td>
								</tr>
							</thead>
							<tbody>
								{reviews.map(review => (

									<tr key={review.id}>
										<td>
											<Link to={"/admin/users/" + review.reviewerId + "/edit"}>
												{review.reviewerFullName}
											</Link>
											<ImpersonateLink userId={review.reviewerId} /></td>
										<td>
											<Link to={"/admin/users/" + review.revieweeId + "/edit"}>
												{review.revieweeFullName}
											</Link>
											<ImpersonateLink userId={review.revieweeId} /></td>
										<td>{review.rating}</td>
										<td>{review.title}</td>
										<td>{review.displayName}</td>
										<td>{dateHelper.mediumDateFormat(review.createdAt)}</td>
										<td><Button color="danger" onClick={(event) => onDelete(review, event)} >Delete</Button></td>
									</tr>

								))}
							</tbody>
						</table>
					}
				</Col>
			</Row>
		</Container>
	);
}
