import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LoadingSpinner } from "../LoadingAnimations";
import {
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import { ImpersonateLink } from './Impersonate';

export class AdminUserAgreements extends Component {
	static displayName = AdminUserAgreements.name;

	constructor(props) {
		super(props);
		this.state = {
			ua: null
		};
	}

	componentDidMount() {
		this.populateUserAgreements();
	}

	async populateUserAgreements() {
		const response = await fetch('/api/useragreements/recent');
		const data = await response.json();
		console.log("UserAgreements", data);
		this.setState({ ua: data, loading: false });
	}

	render() {
		return (
			<Container>
				<Helmet>
					<title>Admin / UserAgreements</title>
				</Helmet>
				<Row md="1" sm="1" xs="1">
					<Col>
						<h1>User Agreements</h1>
						<p>Showing most recent <strong>100</strong> User Agrements.</p>
						<table className="table">
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>State</th>
									<th>IP&nbsp;Address</th>
									<th>Timestamp</th>
									<th>Advisor</th>
									<th>Documents</th>
								</tr>
							</thead>

							{!this.state.ua && <tbody><tr><td colSpan="6"><LoadingSpinner message="Loading..." /></td></tr></tbody>}
							{this.state.ua &&
								<tbody>
									{this.state.ua.map(ua => (
										<tr key={ua.id}>
											<td>
												<Link to={"/admin/users/" + ua.consumer.id + "/edit"}>
													{ua.firstName} {ua.lastName}
												</Link> <ImpersonateLink userId={ua.consumer.id} />
											</td>
											<td>
												<Link to={"/admin/users/" + ua.consumer.id + "/edit"}>
													{ua.emailAddress}
												</Link>
											</td>
											<td>
												{ua.stateAbbreviation}
											</td>
											<td>
												{ua.ipAddress}
											</td>
											<td>
												{new Date(ua.agreedAtTime).toLocaleString()}
											</td>
											<td>
												{ua.advisor &&
													<div>
														<Link to={"/admin/users/" + ua.advisor.id + "/edit"}>
															{ua.advisor.firstName} {ua.advisor.lastName}
														</Link> <ImpersonateLink userId={ua.advisor.id} />
													</div>
												}
											</td>
											<td>
												{ua.complianceDocuments.map(doc => (
													<span key={doc.id}>
														<a href={doc.url} target="_blank">
															{doc.displayName}
														</a>
														<span>, </span>
													</span>
												))}
											</td>
										</tr>
									))}
								</tbody>
							}
						</table>
					</Col>
				</Row>
			</Container>
		);
	}
}
