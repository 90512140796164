import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, Form, Input } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';

async function populateAdvisorAttributes() {
	const data = await authHelper.secureGet('api/admin/advisorattributes');
	console.log("Data:", data);
	return data;
}

async function deleteAttribute(att) {

	const data = await authHelper.secureDelete('api/admin/advisorattributes/' + att.id);
	console.log("Delete Attribute:", data);
	return data;
}


export const AdvisorAttributes = (props) => {

	const [attributes, setAttributes] = useState(null);
	const [attribute, setAttribute] = useState(null);

	React.useEffect(() => {
		populateAdvisorAttributes().then((a) => { setAttributes(a); });
	}, []);

	const onSave = async (e) => {
		e.preventDefault();

		console.log("onSave", attribute);
		setAttributes(null);
		setAttribute(null);

		authHelper.securePut("/api/admin/advisorattributes/" + attribute.id, attribute)
			.then(response => {
				console.log("response", response);

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}

			})
			.then(() => {
				console.log("End addItem");
				populateAdvisorAttributes().then((a) => { setAttributes(a); });
			})
			.catch(error => { alert(error); console.error('Unable to add item.', error) });

		return false;
	}
	const onEdit = (e, att) => {
		e.preventDefault();

		setAttribute(att);

		return false;
	}
	const onDelete = (e, att) => {
		e.preventDefault();

		const isConfirmed = window.confirm("Are you sure you want to delete this attribute? This is permanent and can't be undone.");

		if (!isConfirmed) {
			return false;
		}

		const updatedAttributes = [...attributes];
		const index = updatedAttributes.indexOf(att);
		if (index !== -1) {
			updatedAttributes.splice(index, 1);
		}

		console.log("deleting", index, att, updatedAttributes);

		setAttributes(updatedAttributes);
		deleteAttribute(att);

		return false;
	}


	function onNewAttribute(e) {
		setAttribute({});
	}
	function onCancel(e) {
		setAttribute(null);
	}


	function onFormChange(e) {
		console.log("e", e);
		setAttribute(a => ({
			...a,
			[e.target.id]: e.target.value,
		}))
		console.log("onFormChange", e);
	}



	return (
		<Container>
			<Helmet>
				<title>Nectarine - Advisor Attributes</title>
			</Helmet>
			<Row>
				<Col>
					<h1>Advisor Attributes</h1>

					<p>
						Use this page to add, edit, or remove the default attributes that advisors can select to list on their profile.
					</p>
					{!attribute &&
						<p>
							<Button color="primary" onClick={onNewAttribute}>New Advisor Attribute</Button>
						</p>
					}

					{
						attribute &&
						<Form onSubmit={onSave}>
							<table className="table">
								<tr>
									<td><strong>Category</strong></td>
									<td>
										<Input id="categoryID" name="categoryID" type="select"
											onChange={(e) => onFormChange(e)}

											value={attribute.categoryID}>
											<option value="">Choose a category...</option>
											<option value="1">What I Can Help You With</option>
											<option value="2">I Enjoy Working With</option>
										</Input>
									</td>
								</tr>
								<tr>
									<td><strong>Title</strong></td>
									<td>
										<Input
											id="title"
											name="title"
											placeholder="e.g. Families with children"
											type="text"
											required
											value={attribute.title}
											onChange={(e) => onFormChange(e)}
										/>
									</td>
								</tr>
								<tr>
									<td><strong>Slug</strong></td>
									<td>
										<Input
											id="slug"
											name="slug"
											placeholder="e.g. pre-retirees"
											type="text"
											value={attribute.slug}
											onChange={(e) => onFormChange(e)}
										/>
									</td>
								</tr>
								<tr>
									<td><strong>Icon Tag</strong></td>
									<td>
										<Input
											id="iconTag"
											name="iconTag"
											placeholder="e.g. fa-lightbulb"
											type="text"
											value={attribute.iconTag}
											onChange={(e) => onFormChange(e)}
										/>
									</td>
								</tr>
							</table>
							<Button color="primary">
								Save
							</Button>{" "}
							<Button onClick={onCancel}>Cancel</Button>
						</Form>
					}

					{!attributes && <LoadingSpinner message="Loading..." />}
					{attributes &&
						<table className="table">
							<thead>
								<tr>
									<th>Category</th>
									<th>Icon</th>
									<th>Title</th>
									<th>Slug</th>
									<th>Icon Tag</th>
									<th className="text-danger">Danger</th>
								</tr>
							</thead>

							{attributes.map(item => (
								<tr>
									<td>
										{item.categoryID == 1 && <span>What I Can Help You With</span>}
										{item.categoryID == 2 && <span>I Enjoy Working With</span>}

									</td>
									<td className="center">
										<i className={"fa-light " + item.iconTag}></i>
									</td>
									<td>
										<a href="#" onClick={(e) => { return onEdit(e, item) }}>
											{item.title}
										</a>
									</td>
									<td>{item.slug}</td>
									<td>{item.iconTag}</td>
									<td><Button color="danger" onClick={(e) => onDelete(e, item)} >Delete</Button></td>
								</tr>
							))}
						</table>
					}
				</Col>
			</Row>
		</Container >
	);
}
