import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	Container
} from 'reactstrap';
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import { LandingPageTemplate } from './LandingPageTemplate';
import { LoadingSpinner } from "../LoadingAnimations";
import Cookies from 'js-cookie';

async function populateAffiliate(slug) {

	const affiliate = await authHelper.secureGet('api/home/affiliate/' + slug);
	return affiliate;
}

export const AffiliateLandingPage = (props) => {

	// Get the slug from the URL
	const navigate = useNavigate();
	let { slug } = useParams();
	const [affiliate, setAffiliate] = useState(null);

	React.useEffect(() => {
		populateAffiliate(slug).then((a) => { handleAffiliate(a); });

	}, []);

	function handleAffiliate(a) {

		if (a == null || a.length === 0) {
			navigate('/');
			return;
		}

		setAffiliate(a);

		if (a) {
			const thirtyDaysFromNow = new Date();
			thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
			Cookies.set('klpxqgwlwpao', a.id, { expires: 30 });
		}
	}

	return (
		<Container>
			<Helmet>
				{/* Give the SEO juice from this page to our home page */}
				<link rel="canonical" href="/" />
			</Helmet>
			{!affiliate && <LoadingSpinner />}
			{affiliate &&
				<LandingPageTemplate
					plural="financial advisors"
					singular="advisor"
					disclaimer={affiliate.fullName
						+ " is not an employee of Nectarine. By using this link, "
						+ affiliate.fullName + " will be compensated and therefore has an incentive to endorse Nectarine. All opinions are their own and may not express the views of Nectarine, nor are they a guarantee of a similar outcome. "
						+ affiliate.fullName + " will receive a 10% compensation of all of your purchases for the first year of you signing up."}
				/>
			}
		</Container>
	);
}
