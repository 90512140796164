import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';

export const ImpersonateLink = (props) => {



	const onImpersonate = async (event, userID) => {

		console.log("onImpersonate", event, userID);

		event.preventDefault();

		authHelper.securePut("/api/users/" + userID + "/impersonate")
			.then(response => {
				console.log("response", response);

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}

			})
			.then(() => {
				console.log("End onImpersonate");

				window.location.href = event.target.href;
			})
			.catch(error => { console.error('Unable to impersonate user.', error) });

		return true;
	}

	return (
		<Link to="/admin" onClick={(e) => onImpersonate(e, props.userId)} className="impersonate">🥸</Link>
	);
}
