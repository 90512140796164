import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import { EventsTable } from './EventsTable';

async function populateData() {

	const data = await authHelper.secureGet('api/admin/events');
	console.log("Data:", data);

	//Log the total size of data payload to the console
	console.log("Data size:", JSON.stringify(data).length);

	return data;
}

async function deleteEvent(event) {

	const data = await authHelper.secureDelete('api/admin/events/' + event.id);
	console.log("Data:", data);
	return data;
}


export const AdminEvents = () => {

	const [events, setEvents] = useState(null);
	const [filteredEvents, setFilteredEvents] = useState(null);
	const [filters, setFilters] = useState(null);

	React.useEffect(() => {
		populateData().then((a) => { setEvents(a); setFilteredEvents(a); });
	}, []);

	const onDelete = (e, event) => {
		e.preventDefault();

		const isConfirmed = window.confirm("Are you sure you want to delete this event? This is permanent and can't be undone.");

		if (!isConfirmed) {
			return false;
		}

		const updatedEvents = [...events];
		const index = updatedEvents.indexOf(event);
		if (index !== -1) {
			updatedEvents.splice(index, 1);
		}

		console.log("deleting", index, event, updatedEvents);

		setEvents(updatedEvents);
		setFilteredEvents(updatedEvents);
		deleteEvent(event);

		return false;
	}

	const filter = (e, type) => {

		var filteredEvents;

		switch (type) {
			case "paid":
				filteredEvents = events.filter(event => event.paymentAmount > 0
					&& (event.status == "Active" || event.status == "Scheduled" || event.status == "Completed")); break;
			default:
				filteredEvents = [...events]; break;
		}

		console.log(type);
		setFilteredEvents(filteredEvents);

	}


	return (
		<Container>
			<Helmet>
				<title>Nectarine - Events</title>
			</Helmet>
			<Row>
				<Col>
					<h1>All Events</h1>
					<p>
						Show only:{" "}
						<Button color="light" onClick={(e) => filter(e, "paid")} >Paid</Button>{" "}
						<Button color="light" onClick={(e) => filter(e, "all")} >All</Button>
					</p>

					{!filteredEvents && <LoadingSpinner message="Loading..." />}
					{filteredEvents &&
						<div>
							<p>
								Showing <strong>{filteredEvents.length}</strong> results. {" "}
								Total Revenue:{" "}
								<strong>${filteredEvents.reduce((sum, event) =>
									sum + (event.paymentSuccessful
										&& (event.status == "Active" || event.status == "Scheduled" || event.status == "Completed")
										? event.paymentAmount : 0), 0).toLocaleString()}</strong>
							</p>
							<EventsTable events={filteredEvents} onDelete={onDelete} />
						</div>
					}
				</Col>
			</Row>
		</Container >
	);
}
