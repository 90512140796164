import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import wateringImage from "./../img/watering-plants.png";

export const NotFound = (props) => {

	return (
		<Container>
			<Helmet>
				<title>Nectarine - Page Not Found</title>
			</Helmet>
			<Row>
				<h1>Uh oh! Page Not Found.</h1>
				<Col md="6">
					<p>
						For some reason the page you're looking for can't be found. Try visiting the <Link to="/">home page</Link> and navigating from there.
					</p>
					<p className="center mt-4">
						<Link className="btn btn-primary" to="/">Go Back Home</Link>
					</p>
				</Col>
				<Col md="6">
					<img src={wateringImage} className="" alt="Watering plants" style={{ width: '80%' }} />
				</Col>
			</Row>
		</Container>
	);
}
