import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { ImpersonateLink } from './Impersonate';
import dateHelper from '../Helpers/DateHelper';


export const EventsTable = (props) => {

	return (
		<table className="table">
			<thead>
				<tr>
					<th>Advisor</th>
					<th>Consumer</th>
					<th>
						<i class="fa-duotone fa-peach light-blue"></i>
					</th>
					<th>State</th>
					<th>Intake</th>
					<th>Created At</th>
					<th>Start Time</th>
					<th>Status</th>
					<th>Links</th>
					<th>Paid</th>
					<th className="text-danger">Danger</th>
				</tr>
			</thead>
			<tbody>
				{props.events.map(event => (
					<tr key={event.id}>
						<td>
							<Link to={"/admin/users/" + event.advisorId + "/edit"}>
								{event.advisorFullName}
							</Link>

							{!event.advisorFullName &&
								<span>{event.advisorEmail}</span>
							}

							{event.advisorId &&
								<ImpersonateLink userId={event.advisorId} />
							}
						</td>

						<td>
							<Link to={"/admin/users/" + event.consumerId + "/edit"}>
								{event.consumerFullName}
							</Link>
							{!event.consumerFullName &&
								<span>{event.consumerEmail}</span>
							}

							{event.consumerId &&
								<ImpersonateLink userId={event.consumerId} />
							}
						</td>
						<td>
							{event.isNectarine &&
								<i class="fa-duotone fa-peach light-blue"></i>
							}
						</td>
						<td>
							{event.consumerState}
						</td>
						<td>
							{event.intakeForm &&
								<i className="fa-duotone fa-check-to-slot att-icon"></i>
							}
							{!event.intakeForm &&
								<i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i>
							}
						</td>
						<td>
							<Link to={"/admin/events/" + event.id + "/edit"}>
								{dateHelper.mediumDateFormat(event.createdAt)}
							</Link>
						</td>
						<td>{dateHelper.mediumDateFormat(event.startTime)}</td>
						<td>{event.status}</td>

						<td>
							<a href={event.location}>Zoom</a>{" "}
							<a href={event.rescheduleUrl}>Reschedule</a>{" "}
							<a href={event.cancelUrl}>Cancel</a>{" "}
							{event.consumerId && event.advisorId &&
								<a href={"/leaveareview/" + event.advisorId + "/" + event.consumerId}>Review Page</a>
							}
						</td>

						<td>
							{new Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: event.paymentCurrency ? event.paymentCurrency : "USD",
								maximumFractionDigits: 0
							}).format(event.paymentAmount)}
							{
								!event.paymentSuccessful && event.paymentAmount > 0 &&
								<span className="text-danger">
									{" "}<strong>FAILED!</strong>
								</span>
							}
						</td>
						<td><Button color="danger" onClick={(e) => props.onDelete(e, event)} >Delete</Button></td>
					</tr>
				))}
			</tbody>
		</table>

	);
}
