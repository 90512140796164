import React, { useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LoadingSpinner } from "../LoadingAnimations";
import {
	Container, Row, Col, Form, FormGroup, Label, Input, Button, Badge, Alert, Collapse
} from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import './Admin.css';
import { ChooseStates } from '../Advisor/ChooseStates';
import stateHelper from '../Helpers/StateHelper';
import { ImpersonateLink } from './Impersonate';
import dateHelper from '../Helpers/DateHelper';

async function getUser(userID) {
	const data = await authHelper.secureGet('/api/users/' + userID);
	console.log("User", data);
	console.log("UserKeys", Object.keys(data));
	return data;
}

async function populateRoles() {
	const data = await authHelper.secureGet('/api/admin/roles');
	console.log("Roles", data);
	return data;
}

async function populateUserRoles(userID) {
	const data = await authHelper.secureGet('/api/users/' + userID + '/roles');
	console.log("UserRoles", data);
	return data;
}

export const AdminEditUser = () => {

	const [redirectToAdminList, setRedirect] = useState(false);
	const [user, setUser] = useState(null);
	const [roles, setRoles] = useState(null);
	const [userRoles, setUserRoles] = useState(null);
	const [errorSection, setErrorSection] = useState({ show: false, text: null });
	const [selectedFile, setSelectedFile] = useState(null);

	let { userID } = useParams();

	console.log("userID", userID);

	React.useEffect(() => {
		getUser(userID).then((a) => setUser(a));
		populateRoles().then((a) => setRoles(a));;
		populateUserRoles(userID).then((a) => setUserRoles(a));
	}, []);

	// effect runs when user state is updated
	React.useEffect(() => {
		// reset form with user data
		//reset(user);
		user && console.log("useEffect User", user);

	}, [user]);

	if (redirectToAdminList) {
		return <Navigate to='/admin/users' />
	}

	function onFormChange(e) {
		hideError();
		setUser(a => ({
			...a,
			[e.target.id]: e.target.value,
		}))
		console.log("onFormChange", e);
	}

	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;

		console.log("handleCheckboxChange", value, checked, event)

		setUser(a => ({
			...a,
			[event.target.id]: checked,
		}))

		console.log("user", user);
	};

	function showError(text) {
		let errorSection = {};

		errorSection.show = true;
		errorSection.text = text.toString();

		console.error("showError", text);

		setErrorSection(errorSection);
	}

	function hideError() {
		setErrorSection({ show: false });
	}

	const handleFileSelect = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleFileUpload = async (event) => {

		event.preventDefault();

		if (selectedFile) {
			const formData = new FormData();
			formData.append('file', selectedFile);

			console.log("handleFileUpload formData", formData);

			var displayName = event.target.elements["displayName"].value;
			const params = new URLSearchParams();
			params.append("displayName", displayName)

			var url = '/api/files/compliancedocument/' + userID.toString() + "?" + params.toString();

			console.log("url", url);

			const token = await authService.getAccessToken();

			fetch(url, {
				method: 'POST',
				body: formData,
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			})
				.then(response => {
					console.log("handleFileUpload response", response);
				})
				.then((data) => {
					console.log("handleFileUpload data", data);
					// Handle response data or success message
					getUser(userID).then((a) => setUser(a));
				})
				.catch((error) => {
					console.error('Error:', error);
					// Handle error
				});
		}
	};

	const onSave = async (event) => {
		event.preventDefault()

		// extract form data
		// const formdata = new FormData(event.target);

		// console.log("onSubmit formData", formdata);

		// // convert FormData to json object
		// // SOURCE: https://stackoverflow.com/a/46774073
		// const formItem = {}
		// formdata.forEach(function (value, prop) {
		// 	formItem[prop] = value
		// })

		//Little hack to send password back without a separate object
		user.passwordHash = user.password;

		console.log("onSave json", user);

		authHelper.securePut("/api/users/" + user.id, user)
			.then(response => {
				console.log("response", response);

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}

			})
			.then(() => {
				console.log("End addItem");
				setRedirect(true);
			})
			.catch(error => { showError(error); console.error('Unable to add item.', error) });

		return false;
	}

	const onDelete = async (event) => {
		event.preventDefault()

		const formdata = new FormData(event.target);
		const formItem = {}
		formdata.forEach(function (value, prop) {
			formItem[prop] = value
		})

		authHelper.secureDelete("/api/users/" + formItem.UserID, null)
			.then(response => {
				console.log("DELETE response", response);
			})
			.then(() => {
				console.log("End onDelete");
				setRedirect(true);
			})
			.catch(error => { alert('Unable to delete item.', error); setRedirect(false); });

		return false;
	}

	const onSetDocumentActive = async (event, docID, showToTheHourBuyers) => {
		event.preventDefault();

		console.log("onSetDocumentActive", docID, showToTheHourBuyers);

		//setUser(null);

		authHelper.securePut("/api/files/compliancedocument/setshowtothehourbuyers/" + docID, showToTheHourBuyers)
			.then(response => {
				console.log("Set Active response", response);
			}
			)
			.then(() => {
				console.log("End onSetDocumentInactive");
				getUser(userID).then((a) => setUser(a));
			})
			.catch(error => {
				alert('Unable to set document active.', error);
				getUser(userID).then((a) => setUser(a));
			});
	}

	const onDeleteRole = async (event) => {
		event.preventDefault()

		const formdata = new FormData(event.target);
		const formItem = {}
		formdata.forEach(function (value, prop) {
			formItem[prop] = value
		})

		authHelper.secureDelete("/api/users/" + userID + "/roles", formItem.RoleName)
			.then(response => {
				console.log("DELETE ROLE response", response);
			})
			.then(() => {
				console.log("End onDeleteRole");
				populateUserRoles(userID).then((a) => setUserRoles(a));
			})
			.catch(error => { alert('Unable to delete item.', error); setRedirect(false); });

		return false;
	}

	const onAddRole = async (event) => {
		event.preventDefault();

		// extract form data
		const formdata = new FormData(event.target);

		console.log("onAddRole formData", formdata);

		// convert FormData to json object
		// SOURCE: https://stackoverflow.com/a/46774073
		const formItem = {}
		formdata.forEach(function (value, prop) {
			formItem[prop] = value
		})

		console.log("onAddRole json", formItem);

		authHelper.securePost("/api/users/" + formItem.UserID + "/roles", formItem.RoleName)
			.then(response => {
				console.log("response", response);
				//response.json()
			})
			.then(() => {
				console.log("End addItem");

				populateUserRoles(userID).then((a) => setUserRoles(a));
			})
			.catch(error => console.log('Unable to add item.', error));

		return false;
	}



	return (
		<Container>
			<Helmet>
				<title>Admin / Edit User</title>
			</Helmet>
			{errorSection.show &&
				<Alert color="danger">
					An error happened! {errorSection.text}
				</Alert>
			}
			{!user && <LoadingSpinner message="Loading..." />}
			{user && userRoles &&
				<Row>
					<Col md="6" sm="12" xs="12">
						<Form onSubmit={onSave}>
							<h2>Edit {user.userName} from {user.state}</h2>
							<Input id="id" name="id" type="hidden" value={user.id} />
							<Row>
								<Col md={12}>
									<FormGroup>
										<Label>
											<Input
												id="isNectarine"
												name="isNectarine"
												type="checkbox"
												value={user.isNectarine}
												checked={user.isNectarine}
												onChange={(e) => handleCheckboxChange(e)}
											/>&nbsp;&nbsp;
											<i className={"fa-duotone fa-peach" + (user.isNectarine ? " light-blue" : "")}></i>
											&nbsp;<strong>Is Nectarine</strong> (When checked this advisor is operating under the Nectarine RIA)
										</Label>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<FormGroup>
										<Label for="firstName">
											First Name
										</Label>
										<Input
											id="firstName"
											name="FirstName"
											placeholder="Jane"
											type="text"
											value={user.firstName}
											onChange={(e) => onFormChange(e)}
										/>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label for="userName">
											Username
										</Label>
										<Input
											id="userName"
											name="userName"
											placeholder="myusername"
											value={user.userName}
											onChange={(e) => onFormChange(e)}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<FormGroup>
										<Label for="lastName">
											Last Name
										</Label>
										<Input
											id="lastName"
											name="LastName"
											placeholder="Doe"
											type="text"
											value={user.lastName}
											onChange={(e) => onFormChange(e)}
										/>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label for="email">
											Email
										</Label>
										<Input
											id="email"
											name="email"
											placeholder="name@example.com"
											type="email"
											value={user.email}
											onChange={(e) => onFormChange(e)}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<FormGroup>
										<Label for="state">
											State
										</Label>
										<Input id="state" name="state" type="select"
											onChange={(e) => onFormChange(e)}

											value={user.state}>
											<option value="">Choose a state...</option>
											{stateHelper.usStatesAndTerritories.map((state) => (
												<option key={state.abbreviation} value={state.abbreviation}>
													{state.name}
												</option>
											))}
										</Input>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label for="password">
											Password
										</Label>
										<Input
											id="password"
											name="password"
											placeholder="Leave blank to leave unchanged"
											type="text"
											value={user.password} /* Never show the password here*/
											onChange={(e) => onFormChange(e)}
										/>
									</FormGroup>
								</Col>
							</Row>
							{userRoles.includes("Advisor") &&
								<div id="AdvisorStuff">
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label for="certifications">
													Certifications
												</Label>
												<Input
													id="certifications"
													name="Certifications"
													placeholder="CFP, CFA"
													value={user.certifications}
													onChange={(e) => onFormChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="title">
													Title
												</Label>
												<Input
													id="title"
													name="Title"
													placeholder="Financial Planner"
													value={user.title}
													onChange={(e) => onFormChange(e)}
												/>
											</FormGroup>
										</Col>
									</Row>
									<FormGroup>
										<Label for="calendarLink">
											Calendly Link
										</Label>
										<Input
											id="calendarLink"
											name="CalendarLink"
											placeholder="https://calendly.com/advisorname"
											value={user.calendarLink}
											onChange={(e) => onFormChange(e)}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="calendarLink">
											Calendly ID
										</Label>
										<Input
											id="calendlyID"
											name="CalendlyID"
											placeholder=""
											disabled={true}
											value={user.calendlyID}
											onChange={(e) => onFormChange(e)}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="bio">
											Advisor Bio (Exactly three lines, one bullet per line)
										</Label>
										<Input
											id="bio"
											name="Bio"
											type="textarea"
											rows="3"
											placeholder={'9 years experience\nEnjoys those dolla bills\nNever fakes the funk on a nasty dunk'}
											value={user.bio}
											onChange={(e) => onFormChange(e)}
										/>
									</FormGroup>
								</div>
							}
							{(userRoles.includes("Advisor") || userRoles.includes("Affiliate")) &&
								<FormGroup>
									<Label for="slug">
										URL slug
									</Label>
									<Input
										id="slug"
										name="slug"
										required
										placeholder="e.g. shane-sideris"
										value={user.slug}
										onChange={(e) => onFormChange(e)}
									/>
								</FormGroup>
							}
							<Button color="primary" className="btn-lg">
								Save
							</Button>
						</Form>
					</Col>
					<Col md="6" sm="12" xs="12">
						<Row>
							<h2>User Roles</h2>

							{!(roles && user && userRoles) && <LoadingSpinner message="Loading..." />}
							{roles && user && userRoles &&
								<div>
									<Form onSubmit={onDeleteRole}>

										{userRoles.map(role =>
											<Badge color="info" key={role}>
												<Label for={"roleName-" + role}>{role}</Label>&nbsp;
												<Input id={"roleName-" + role} name="RoleName" type="radio" value={role} />
											</Badge>
										)}
										<Button color="danger">Remove Role</Button>
									</Form>

									<Form onSubmit={onAddRole}>
										<Input id="userID" name="UserID" type="hidden" value={user.id} />
										<Input id="roleName" name="RoleName" type="select">
											<option value="">Choose a role...</option>
											{roles.map(role => (
												<option key={role.id} value={role.name}>
													{role.name}
												</option>
											))}
										</Input>
										<Button color="primary" className="mt-4">Add Role to {user.userName}</Button>
									</Form>
								</div>
							}
						</Row>
					</Col>

					{userRoles.includes("Advisor") &&
						<Row>
							<h3>Advisor States</h3>

							<ChooseStates userID={user.id} />

							<h3>Advisor Documents</h3>

							<p>When uploading a new document, it should automatically become active and show
								the ✅. It should also make inactive all other documents with the same name. But if you happen
								to need to make an old document active or inactive (for example you're replacing a document
								with a differently named document) use can use the "Set Active" buttons.
							</p>

							<table className="table">
								<thead>
									<tr>
										<th></th>
										<th>Date</th>
										<th>Document</th>
										<th>Original Name</th>
										<th>Set Active</th>
									</tr>
								</thead>
								<tbody>
									{user && user.complianceDocuments &&
										Object.keys(user.complianceDocuments).sort((a, b) => user.complianceDocuments[b]["timestamp"].localeCompare(user.complianceDocuments[a]["timestamp"])).map((i, keyIndex) => (
											<tr key={i}>
												<td>
													{user.complianceDocuments[i]["showToTheHourBuyers"] ? "✅" : "-"}
												</td>
												<td>
													{new Date(user.complianceDocuments[i]["timestamp"]).toLocaleDateString()}
												</td>
												<td>
													<a href={user.complianceDocuments[i]["url"]} target="_blank">
														{user.complianceDocuments[i]["displayName"]}
													</a>
												</td>
												<td>
													{user.complianceDocuments[i]["originalFileName"]}
												</td>
												<td>
													{user.complianceDocuments[i]["showToTheHourBuyers"] ?
														<a href="#" onClick={(e) => onSetDocumentActive(e, user.complianceDocuments[i].id, false)}>Set Inactive</a>
														:
														<a href="#" onClick={(e) => onSetDocumentActive(e, user.complianceDocuments[i].id, true)}>Set Active</a>
													}

												</td>
											</tr>
										))
									}

								</tbody>
							</table>


							<h3>Upload A New Document</h3>
							<div>
								<Form onSubmit={handleFileUpload}>
									<Input id="displayName" name="displayName" type="select">
										<option value="">Choose a contract...</option>
										<option key="Contract" value="Contract">Contract</option>
										<option key="ADV Part 2" value="ADV Part 2">ADV Part 2</option>
										<option key="Privacy Policy" value="Privacy Policy">Privacy Policy</option>
										<option key="Form CRS" value="Form CRS">Form CRS</option>
									</Input>
									<Input type="file" onChange={handleFileSelect} />
									<Button color="primary">Upload</Button>
								</Form>
							</div>
						</Row>
					}

					<h3>Events as Consumer</h3>

					<table className="table">
						<thead>
							<tr>
								<th>Advisor</th>
								<th>Created At</th>
								<th>Start Time</th>
								<th>Status</th>
								<th>Links</th>
								<th>Paid</th>
							</tr>
						</thead>
						<tbody>
							{user.eventsAsGuest.map(event => (
								<tr key={event.id}>
									{event.advisor &&
										<td>
											<Link to={"/admin/users/" + event.advisor.id + "/edit"}>
												{event.advisor.fullName}
											</Link>
											<ImpersonateLink userId={event.advisor.id} />
										</td>
									}

									{!event.advisor &&
										<td>
											{event.advisorEmail}
										</td>
									}
									<td>{dateHelper.mediumDateFormat(event.createdAt)}</td>
									<td>{dateHelper.mediumDateFormat(event.startTime)}</td>
									<td>{event.status}</td>

									<td>
										<a href={event.location}>Zoom</a>{" "}
										<a href={event.rescheduleUrl}>Reschedule</a>{" "}
										<a href={event.cancelUrl}>Cancel</a>{" "}
										{event.consumer && event.advisor &&
											<a href={"/leaveareview/" + event.advisor.id + "/" + event.consumer.id}>Review Page</a>
										}
									</td>

									<td>
										{new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency: event.paymentCurrency ? event.paymentCurrency : "USD",
											maximumFractionDigits: 0
										}).format(event.paymentAmount)}
										{
											!event.paymentSuccessful && event.paymentAmount > 0 &&
											<span className="text-danger">
												{" "}<strong>FAILED!</strong>
											</span>
										}
									</td>
								</tr>
							))}
						</tbody>
					</table>


					<h3>Intake Form Responses</h3>
					<table className="table">
						<tbody>
							{user && user.intakeResponses &&
								Object.keys(user.intakeResponses).map((i, keyIndex) => (
									<tr key={i}>
										<td><strong>{new Date(user.intakeResponses[i]["submittedAt"]).toDateString()} - {user.intakeResponses[i]["email"]}</strong><br />
											<pre>{JSON.stringify(JSON.parse(user.intakeResponses[i].rawJson), null, 2)}</pre>
										</td>
									</tr>
								))
							}

						</tbody>
					</table>
					<h3>Other User Details</h3>
					<table className="table">
						<tbody>
							{user &&
								Object.keys(user).map((keyName, keyIndex) => (
									<tr key={keyName}>
										<td>{keyName}</td><td>{user[keyName] ? user[keyName].toString() : "-"}</td>
									</tr>
								))
							}

						</tbody>
					</table>
					<h3>Danger Zone</h3>
					<Form onSubmit={onDelete}>
						<Input id="userID" name="UserID" type="hidden" value={user.id} />
						<Button color="danger">
							Delete
						</Button>
					</Form>
				</Row>
			}
		</Container >
	);
}

