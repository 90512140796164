import React, { useState } from 'react';
import {
	Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form, Alert
} from 'reactstrap';
import { LoadingSpinner, LoadingDots } from "../LoadingAnimations";
import authService from "../api-authorization/AuthorizeService";

export const EditProfilePhoto = (props) => {

	const [savePhotoLoading, setSavePhotoLoading] = useState(false);
	const [advisor, setAdvisor] = useState(props.advisor);
	const [errorSection, setErrorSection] = useState({ show: false, text: null });
	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileSelect = (event) => {
		setSelectedFile(event.target.files[0]);
		hideError();
	};

	//Post the selected file to the API at /api/advisor/photo
	const onSave = async (event) => {
		event.preventDefault();

		if (selectedFile) {
			hideError();
			setSavePhotoLoading(true);
			const formData = new FormData();
			formData.append('file', selectedFile);

			const token = await authService.getAccessToken();

			fetch('/api/advisor/profilepic',
				{
					method: 'POST',
					body: formData,
					headers: !token ? {} :
						{
							'Authorization': `Bearer ${token}`
						}
				})
				.then(response => {

					if (!response.ok) {
						return response.text().then(text => { throw new Error(text) })
					}

					return response.json();

				})
				.then((photo) => {
					//Update advisor with returned photo object
					advisor.photos = [photo];
					props.save(advisor);
					setSavePhotoLoading(false);
				})
				.catch((error) => {
					showError(error);
					setSavePhotoLoading(false);
				});
		}

	}

	function showError(text) {
		let errorSection = {};

		errorSection.show = true;
		errorSection.text = text.toString();

		console.error("showError", text);

		setErrorSection(errorSection);
	}

	function hideError() {
		setErrorSection({ show: false });
	}


	return (
		<Modal isOpen={props.show} toggle={props.toggle}>
			<Form onSubmit={onSave}>
				<ModalHeader toggle={props.toggle}>Upload New Profile Pic</ModalHeader>
				<ModalBody>
					{errorSection.show &&
						<Alert color="danger">
							{errorSection.text}
						</Alert>
					}
					<p>
						Please make sure you are looking directly at the camera, and your face and part of your shoulders are visible.
					</p>

					<Input type="file" onChange={handleFileSelect} />


				</ModalBody>
				<ModalFooter>
					<Button color="primary" disabled={savePhotoLoading}>
						{savePhotoLoading ? <LoadingDots /> : "Save Profile Pic"}
					</Button>{' '}
				</ModalFooter>
			</Form>
		</Modal>

	);
}
