import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import rolesHelper from './Helpers/RolesHelper';
import { LoadingSpinner } from './LoadingAnimations';
import authService from './api-authorization/AuthorizeService';

export const RoleRestriction = ({ allowedRoles, element }) => {

	const [userRoles, setUserRoles] = useState(null);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	React.useEffect(() => {
		authService.isAuthenticated().then((a) => setIsAuthenticated(a));
		async function getRoles() {
			var roles = await rolesHelper.getRoles();
			setUserRoles(roles);
		}
		getRoles();
	}, []);

	if (!allowedRoles || allowedRoles.trim() === '') {
		throw new Error("Must supply the allowedRoles attribute when using RoleRestriction");
	}

	console.log("userRoles", userRoles);

	if (!userRoles) {
		return <LoadingSpinner />;
	}

	const hasAccess = userRoles.some((role) => allowedRoles.includes(role));

	console.log("hasAccess", hasAccess);


	if (hasAccess) {
		return element;
	} else if (!isAuthenticated) {
		return <Navigate to="/authentication/login" />;
	} else {
		return <div>
			<h1>Uh oh!</h1>
			<p className="p-4">It looks like you're not supposed to be here.
				You may need to <Link to="/authentication/login">login</Link> first.
				Please <Link to="/contact">contact us</Link> if you think that might be a mistake!
			</p>
		</div>;
	}
};

export default RoleRestriction;
