import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "./LoadingAnimations";
import authHelper, { AuthorizeHelper } from './Helpers/AuthorizeHelper';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


export const TestPage = (props) => {



	return (
		<Container>
			<Helmet>
				<title>Nectarine - Page Name</title>
			</Helmet>
			<Row>
				<Col>
					<h1>Page Name</h1>
					<Skeleton />


				</Col>
			</Row>
		</Container>
	);
}
