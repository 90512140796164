import React, { Component, Fragment } from 'react';
import { NavItem, NavLink, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import rolesHelper from '../Helpers/RolesHelper';

export class LoginMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false,
			roles: null,
			userName: null
		};
	}

	componentDidMount() {
		this._subscription = authService.subscribe(() => this.populateState());
		this.populateState();
	}

	componentWillUnmount() {
		authService.unsubscribe(this._subscription);
	}

	async populateState() {
		const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
		const roles = await rolesHelper.getRoles();
		this.setState({
			isAuthenticated,
			userName: user && user.name,
			roles: roles
		});
	}

	render() {
		const { isAuthenticated, userName } = this.state;
		if (!isAuthenticated) {
			const registerPath = `${ApplicationPaths.Register}`;
			const loginPath = `${ApplicationPaths.Login}`;
			return this.anonymousView(registerPath, loginPath);
		} else {
			const profilePath = `${ApplicationPaths.Profile}`;
			const logoutPath = `${ApplicationPaths.LogOut}`;
			const logoutState = { local: true };
			return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
		}
	}

	authenticatedView(userName, profilePath, logoutPath, logoutState) {
		if (this.props.location == "top") {
			return (
				<Fragment>
					{this.state.roles.includes("Consumer") &&
						<NavItem>
							<NavLink tag={Link} className="btn btn-light login-button" to='/account'>My Account</NavLink>
						</NavItem>
					}
					{(this.state.roles.includes("Advisor") || this.state.roles.includes("Admin")) &&
						<NavItem>
							<NavLink tag={Link} className="btn btn-light login-button" to='/dashboard'>Advisor Home</NavLink>
						</NavItem>
					}
					{this.state.roles.includes("Admin") &&
						<NavItem>
							<NavLink tag={Link} className="btn btn-light login-button" to='/admin'>Admin</NavLink>
						</NavItem>
					}
				</Fragment>
			);
		}
		return (<Fragment>
			<NavItem>
				<NavLink tag={Link} className="" to={profilePath}>Hello {userName}</NavLink>
			</NavItem>
			<NavItem>
				<NavLink replace tag={Link} className="" to={logoutPath} state={logoutState}>Logout</NavLink>
			</NavItem>
		</Fragment>);
	}

	anonymousView(registerPath, loginPath) {
		if (this.props.location == "top") {
			return (
				<Fragment>
					<NavItem>
						<NavLink tag={Link} className="btn btn-light login-button" to={loginPath}>Login</NavLink>
					</NavItem>
				</Fragment>
			);
		}
		return (<Fragment>
			<NavItem>
				<NavLink tag={Link} className="" to={registerPath}>Register</NavLink>
			</NavItem>
			<NavItem>
				<NavLink tag={Link} className="" to={loginPath}>Login</NavLink>
			</NavItem>
		</Fragment>);
	}
}
