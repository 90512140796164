import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	Alert, Container, Row, Col, Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import { LoadingSpinner, LoadingDots } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import { ChooseStates } from './ChooseStates';
import { EditHourlyRate } from './EditHourlyRate';

async function populateUser() {

	const data = await authHelper.secureGet('api/account/user');
	console.log("Advisor:", data);
	return data;
}

export const AdvisorProfile = () => {

	const [user, setUser] = useState(null);
	const [redirectToCalendar, setRedirect] = React.useState(false);
	const [saveShowReviewsLoading, setSaveShowReviewsLoading] = useState(false);
	const [showSavedMessage, setShowSavedMessage] = useState(false);
	const [errorSection, setErrorSection] = useState({ show: false, text: null });
	const [shortBioLines, setShortBioLines] = useState(null);
	const [saveResultsBulletsLoading, setSaveResultsBulletsLoading] = useState(false);

	const numShortBioLines = 3;

	React.useEffect(() => {
		populateUser().then((a) => {
			setUser(a);
			const shortBio = a.bio || "\n\n";
			var array = shortBio.split("\n");
			// Ensure array has exactly three elements
			if (array.length < numShortBioLines) {
				// If array has less than three elements, pad with empty strings until it has three elements
				while (array.length < numShortBioLines) {
					array.push("");
				}
			} else if (array.length > numShortBioLines) {
				// If array has more than three elements, truncate it to have only the first three elements
				array = array.slice(0, numShortBioLines);
			}

			setShortBioLines(array);
		});
	}, []);


	const onShortBioChanged = (index, event) => {
		const updatedBioLines = [...shortBioLines];
		updatedBioLines[index] = event.target.value;
		setShortBioLines(updatedBioLines);
	};


	const onFormChange = (e) => {
		const { value, checked } = e.target;
		setUser(a => ({
			...a,
			[e.target.id]: checked,
		}))
	};

	const onSaveShortBio = async (event) => {
		event.preventDefault();
		hideError();

		console.log("onSave json", user);

		user.bio = shortBioLines.join("\n");

		setSaveResultsBulletsLoading(true);

		authHelper.securePut("/api/account/user", user)
			.then(response => {
				console.log("response", response);

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}
			})
			.then(() => {
				setShowSavedMessage(true);
				setTimeout(() => setShowSavedMessage(false), 3000);
				setSaveResultsBulletsLoading(false);
			})
			.catch(error => {
				showError(error);
				setSaveResultsBulletsLoading(false);
				console.error('Unable to add item.', error)
			});

		return false;
	}


	const onSaveShowReviews = async (event) => {
		event.preventDefault();
		hideError();

		console.log("onSave json", user);

		setSaveShowReviewsLoading(true);

		authHelper.securePut("/api/account/user", user)
			.then(response => {
				console.log("response", response);

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}
			})
			.then(() => {
				setShowSavedMessage(true);
				setTimeout(() => setShowSavedMessage(false), 3000);
				setSaveShowReviewsLoading(false);
			})
			.catch(error => {
				showError(error);
				setSaveShowReviewsLoading(false);
				console.error('Unable to add item.', error)
			});

		return false;
	}

	function showError(text) {
		let errorSection = {};

		errorSection.show = true;
		errorSection.text = text.toString();

		console.error("showError", text);

		setErrorSection(errorSection);
	}

	function hideError() {
		setErrorSection({ show: false });
	}

	if (redirectToCalendar) {
		window.location.href = user.calendarLink;
		return <LoadingSpinner message="Looking up calendar..." />;
	}


	return (
		<Container>
			<Helmet>
				<title>Nectarine - Advisor Profile</title>
			</Helmet>
			{showSavedMessage &&
				<Alert className="saved-alert">
					Your preference has been saved!
				</Alert>
			}
			<Row>
				<Col className="advisor-account">
					<h1>My Profile</h1>

					{!user && <LoadingSpinner message="Loading..." />}
					{user &&
						<div>
							<p className="py-4">
								View and edit your public <strong>profile page</strong> below and <strong>profile pic</strong>. This is what clients will see when they search for you and book a call.
							</p>
							<p>
								<Link to={"/advisor/" + user.slug}>
									<img src={user.profilePicUrl} alt="Profile" className="circle-image headshot" style={{ maxWidth: '200px' }} />
								</Link>
							</p>
							<p>
								<Link className="btn btn-primary" to={"/advisor/" + user.slug}>View and edit public profile page for {user.firstName} {user.lastName}</Link>
							</p>
							<h2>
								My Rate
							</h2>
							<EditHourlyRate advisor={user} save={setUser} />
							<h2>My Results Bullets</h2>
							<p>
								Edit the three bullets that will be shown below your photo on the search results page.
							</p>
							<Form onSubmit={onSaveShortBio}>
								<FormGroup>
									<Input type="text" className="short-bio" id="shortBio1" value={shortBioLines[0]} onChange={(event) => onShortBioChanged(0, event)} maxLength={60} />
									<Input type="text" className="short-bio" id="shortBio2" value={shortBioLines[1]} onChange={(event) => onShortBioChanged(1, event)} maxLength={60} />
									<Input type="text" className="short-bio" id="shortBio3" value={shortBioLines[2]} onChange={(event) => onShortBioChanged(2, event)} maxLength={60} />
								</FormGroup>
								<Button color="primary" disabled={saveResultsBulletsLoading}>
									{saveResultsBulletsLoading ? <LoadingDots /> : "Save Results Bullets"}
								</Button>
							</Form>

							<h2>My States</h2>
							<ChooseStates userID={user.id} />
							<h2>Show Reviews</h2>
							<p>
								Choose whether the reviews you receive from clients will be shown publicly on the search results and on your profile page.
								We recommend leaving the box checked unless local regulations require you to hide reviews.
							</p>
							{errorSection.show &&
								<Alert color="danger">
									{errorSection.text}
								</Alert>
							}

							<Form onSubmit={onSaveShowReviews}>
								<p><Label>
									<Input
										id="showReviews"
										type="checkbox"
										checked={user.showReviews}
										onChange={onFormChange}
									/>&nbsp;Show my reviews publicly
								</Label>
								</p>
								<p>
									<Button color="primary" disabled={saveShowReviewsLoading}>
										{saveShowReviewsLoading ? <LoadingDots /> : "Save Review Status"}
									</Button>
								</p>
							</Form>
						</div>
					}
				</Col>
			</Row>
		</Container>
	);
}
