import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useOutletContext } from 'react-router-dom'
import { AccordionBody, AccordionHeader, AccordionItem, Col, Container, Row, UncontrolledAccordion } from 'reactstrap'
import authHelper from '../Helpers/AuthorizeHelper'
import dateHelper from '../Helpers/DateHelper'
import typeformParser from '../Helpers/TypeformParser'
import { BeforeYourMeeting } from './../BeforeYourMeeting'

async function populateDashboardData() {
  const data = await authHelper.secureGet('/api/account/dashboard').catch((error) => {
    console.error('Error getting dashboard data!', error)
  })
  console.log('Dashboard Data', data)
  return data
}

export const AccountIndex = () => {
  const [advisorsNeedingReview, setAdvisorsNeedingReview] = useState(null)
  const [upcomingEvents, setUpcomingEvents] = useState(null)
  const [pastEvents, setPastEvents] = useState(null)
  const [showIntakeFormReminder, setShowIntakeFormReminder] = useState(null)
  const [user, setUser] = useOutletContext()

  React.useEffect(() => {
    populateDashboardData().then((d) => {
      setAdvisorsNeedingReview(d.advisorsNeedingReview)
      setUpcomingEvents(d.upcomingEvents)
      setPastEvents(d.pastEvents)
      updateIntakeFormReminder(d.upcomingEvents)
      console.log('advisorsNeedingReview', d.advisorsNeedingReview)
    })
  }, [])

  function updateIntakeFormReminder(upcomingEvents) {
    let reminder = false
    upcomingEvents.forEach((event) => {
      if (!event.intakeFormCompleted) {
        reminder = true
      }
    })
    setShowIntakeFormReminder(reminder)
  }

  //Make a const date that's two hours from now
  const currentDateTime = new Date()
  const twoHoursFromNow = new Date(currentDateTime.getTime() + 2 * 60 * 60 * 1000)

  return (
    <Container>
      <Helmet>
        <title>Nectarine - My Account</title>
      </Helmet>
      <Row className="account-home">
        <Col>
          {user && <h1>Hello, {user.firstName}!</h1>}

          {showIntakeFormReminder && (
            <Row className="intake-reminder">
              <Col className="text-center">
                <h2>Don't forget to fill out your intake form!</h2>
                <p>Tell us what you need help with so your advisor can be prepared.</p>
                <a
                  href={'https://hellonectarine.typeform.com/to/QdrDHmPg#email=' + user.email + '&name=' + user.firstName}
                  className="btn btn-lg btn-primary"
                >
                  Fill Out Intake Form
                </a>
              </Col>
            </Row>
          )}

          {advisorsNeedingReview &&
            advisorsNeedingReview.length > 0 &&
            advisorsNeedingReview.map((advisor) => (
              <Row key={advisor.id} className="review-reminder">
                <Col lg="3" className="text-center">
                  <img
                    src={advisor.profilePicUrl}
                    className="circle-image headshot"
                    alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
                  />
                </Col>
                <Col xl="7" lg="9" className="text-center">
                  <h2>How was your meeting with {advisor.firstName}?</h2>
                  <p>Please take a moment to tell us about your experience!</p>
                  <Link to="/account/reviews" className="btn btn-primary">
                    Go To Reviews Page
                  </Link>
                </Col>
                <Col xl="2" lg="0"></Col>
              </Row>
            ))}

          {upcomingEvents ? (
            <>
              <div>
                <h2>My Upcoming Meeting{upcomingEvents.length == 1 ? '' : 's'}</h2>
                {upcomingEvents.length == 0 && (
                  <div className="upcoming-meetings">
                    <p>You have no upcoming meetings scheduled.</p>
                    <Link to="/" className="btn btn-primary btn-lg">
                      Book a Meeting
                    </Link>
                  </div>
                )}
                {upcomingEvents.length > 0 &&
                  upcomingEvents.map((event, index) => (
                    <div className="upcoming-meetings">
                      <Row>
                        <Col lg="3">
                          <img
                            src={event.advisorProfilePicUrl}
                            className="circle-image headshot"
                            alt={event.advisorFirstName + ' ' + event.advisorLastName}
                          />
                        </Col>
                        <Col lg="6" className="text-start">
                          <h3>{dateHelper.longDateFormat(event.startTime)}</h3>
                          <p>
                            with {event.advisorFullName}
                            {event.advisorCertifications !== null &&
                            event.advisorCertifications !== undefined &&
                            event.advisorCertifications !== ''
                              ? ', '
                              : ''}
                            {event.advisorCertifications}
                          </p>
                          {event.intakeFormCompleted && (
                            <span>
                              <i class="fa-duotone fa-check-to-slot att-icon"></i>&nbsp;
                              <strong>Intake form received!</strong>
                            </span>
                          )}
                          {!event.intakeFormCompleted && (
                            <a
                              href={'https://hellonectarine.typeform.com/to/QdrDHmPg#email=' + user.email + '&name=' + user.firstName}
                              className="btn btn-lg btn-primary"
                            >
                              Fill Out Intake Form
                            </a>
                          )}
                        </Col>
                        <Col lg="3" className="text-start upcoming-buttons">
                          {new Date(event.startTime) < twoHoursFromNow && (
                            <a className="btn btn-primary btn-lg" href={event.location}>
                              Join Meeting
                            </a>
                          )}
                          {new Date(event.startTime) >= twoHoursFromNow && (
                            <a className="btn btn-secondary disabled btn-lg" href={event.location}>
                              Join Meeting
                            </a>
                          )}
                          <br />
                          <a className="btn btn-light" href={event.rescheduleUrl}>
                            Reschedule Meeting
                          </a>
                          <br />
                          <a className="btn btn-light" href={event.cancelUrl}>
                            Cancel Meeting
                          </a>
                          <br />
                        </Col>
                      </Row>
                    </div>
                  ))}
              </div>

              <div className="my-5">
                <BeforeYourMeeting />
              </div>
            </>
          ) : (
            <></>
          )}

          {pastEvents && pastEvents.length > 0 && (
            <div>
              <h2>My Past Meetings</h2>
              {pastEvents.map((event, index) => (
                <UncontrolledAccordion className="accordion-primary past-meetings" defaultOpen={index == 0 ? '1' : ''} key={event.id}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <strong>{dateHelper.longDateFormat(event.startTime)}</strong> &nbsp;with&nbsp;{' '}
                      <strong>{event.advisorFullName}</strong>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <Row>
                        <Col lg="3" className="text-center">
                          <img
                            src={event.advisorProfilePicUrl}
                            className="circle-image headshot"
                            alt={event.advisorFirstName + ' ' + event.advisorLastName}
                          />
                        </Col>
                        <Col xl="7" lg="9" className="d-flex align-items-center pb-4">
                          <Link className="btn btn-primary btn-lg" to={'/advisor/' + event.advisorSlug}>
                            Book Another Meeting With {event.advisorFirstName}
                          </Link>
                        </Col>
                        <Col xl="2" lg="0"></Col>
                      </Row>

                      <h3>Intake Responses</h3>
                      {event.intakeForms.length == 0 && (
                        <div className="intake-form">You did not submit an intake form before this meeting.</div>
                      )}

                      {event.intakeForms.map((ir, index) => {
                        const parsedJson = JSON.parse(ir.rawJson)

                        return (
                          <div className="intake-form">
                            <p>
                              You submitted this form on <strong>{dateHelper.longDateFormat(ir.submittedAt)}</strong>
                            </p>
                            <ul>
                              {parsedJson.form_response.definition.fields.map((item, index) => (
                                <li key={index}>
                                  <b>{item.title}</b>
                                  <br />
                                  {typeformParser.formatAnswer(parsedJson.form_response.answers[index])}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )
                      })}

                      <h3>Meeting Notes</h3>
                      {!event.notesSent && (
                        <div className="meeting-notes">
                          {event.advisorFirstName} has not yet submitted notes for this meeting. They will be emailed to you at{' '}
                          <strong>{user.email}</strong> and appear here once completed.
                        </div>
                      )}
                      {event.notesSent && (
                        <div className="meeting-notes">
                          <p>
                            {event.advisorFirstName} last updated these notes{' '}
                            <strong>{dateHelper.longDateFormat(event.notesUpdated)}</strong>
                          </p>

                          <div dangerouslySetInnerHTML={{ __html: event.publicNotes }}></div>
                        </div>
                      )}

                      {event.recordings && event.recordings.length > 0 && (
                        <div>
                          <h3 className="mt-4">Meeting Recording</h3>
                          {event.recordings.map((recording, index) => (
                            <div>
                              {recording && recording.status == 'Active' && (
                                <div>
                                  <video key={index} controls className="recording-video">
                                    <source src={recording.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                  <br />
                                  <a className="btn btn-light" href={recording.url}>
                                    <i class="fa-solid fa-download"></i> Download ({(recording.fileSize / 1024.0 / 1024.0).toFixed(0)}MB)
                                  </a>
                                </div>
                              )}
                              {recording && recording.status == 'Uploading' && (
                                <div>
                                  <i class="fa-duotone fa-upload missing-icon"></i> The recording for this meeting is{' '}
                                  <strong>{recording.durationInMinutes}</strong> minutes long and is still being processed.
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </AccordionBody>
                  </AccordionItem>
                </UncontrolledAccordion>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
