import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import { LoadingSpinner } from './LoadingAnimations';

export const Redirect = (props) => {

    window.location.href = props.url;

    return (
        <Container>
            <Helmet>
                <title>Nectarine - Redirecting...</title>
            </Helmet>
            <LoadingSpinner />
        </Container>
    );
}
