import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	Container, Row, Col, Button,
	UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem,
	Input, Form, Label
} from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';
import stateHelper from '../Helpers/StateHelper';

async function populateAffiliateDashboard() {
	console.log("Getting affiliate dashboard");
	const data = await authHelper.secureGet('/api/affiliate/dashboard')
		.catch(error => { console.error('Error getting affiliate dashboard!', error) });
	console.log("Dashboard", data);
	return data;
}


export const AccountAffiliate = (props) => {

	const defaultToolTipText = "Copy to clipboard";
	const [dashboard, setDashboard] = useState(null);
	const [shareLink, setShareLink] = useState(null);
	const [toolTipText, setToolTipText] = useState(defaultToolTipText);
	const [isEditLinkMode, setIsEditLinkMode] = useState(false);
	const [slug, setSlug] = useState(null);
	const [agreeToTermsButtonEnabled, setButtonEnabled] = React.useState(false);

	//Make a const that's now in UTC
	const now = new Date();


	React.useEffect(() => {
		loadPageData();
	}, []);

	function loadPageData() {
		populateAffiliateDashboard().then((a) => {
			if (a) {
				setDashboard(a);
				setShareLink("https://hellonectarine.com/r/" + a.affiliate.slug);
				setSlug(a.affiliate.slug);
			}
		});
	}

	function onFormChange(e) {
		//hideError();
		setSlug(e.target.value);
	}

	function cancelEditSlug(e) {
		e.preventDefault();
		setSlug(dashboard.affiliate.slug);
		setIsEditLinkMode(false);
	}

	function onAgreeClick(e) {
		setButtonEnabled(!agreeToTermsButtonEnabled);
	}

	function docSeparator(i, length) {
		if (i == 0 && length == 2)
			return <span> and </span>;
		if (i < length - 2)
			return <span>, </span>;
		if (i < length - 1)
			return <span>, and </span>;
		return <span> </span>;
	}

	function onAgreeSubmit(e) {

		e.preventDefault();

		authHelper.securePost("/api/users/agreetoaffiliateterms/")
			.then(response => {
				console.log("response", response);
				if (!response.ok) {
					console.log("Response", response);
					return response.json().then(text => { throw new Error(text) })
				}
			})
			.then(() => {
				loadPageData();
			})
			.catch(error => {
				alert(error.message);
				console.error('Unable to add item.', error)
			});

		return false;
	}




	const saveShareLink = async (event) => {

		event.preventDefault();
		//hideError();

		console.log("onSave json", dashboard.affiliate);
		dashboard.affiliate.slug = slug;

		authHelper.securePut("/api/account/slug", dashboard.affiliate)
			.then(response => {
				console.log("response", response);

				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}
			})
			.then((data) => {
				console.log("data", data);
				setShareLink("https://hellonectarine.com/r/" + dashboard.affiliate.slug);
				setIsEditLinkMode(false);
			})
			.catch(error => {
				alert(error);
				console.error(error);
				setIsEditLinkMode(false);
			});

		return false;

	}

	function copyToClipboard() {
		var textToCopy = shareLink;
		var tempInput = document.createElement("input");
		tempInput.value = textToCopy;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);
		setToolTipText("Link copied to clipboard!");

		setTimeout(function () {
			setToolTipText(defaultToolTipText);
		}, 2000);
	}


	return (
		<Container>
			<Helmet>
				<title>Nectarine - Share Nectarine</title>
			</Helmet>
			<Row>
				<Col>
					{!dashboard && <LoadingSpinner message="Loading..." />}
					{dashboard && dashboard.missingComplianceDocuments.length > 0 &&
						<div>
							<h1>Agree to continue</h1>
							<p>
								To access your affiliate dashboard, please agree to the terms below.
							</p>

							<Form onSubmit={(e) => onAgreeSubmit(e)}>
								<Input id="agreeToTerms"
									name="agreeToTerms"
									type="checkbox"
									onChange={(e) => onAgreeClick(e)}
								/>&nbsp;

								<Label for="agreeToTerms">I agree to Nectarine's

									{Object.keys(dashboard.missingComplianceDocuments).map((i, keyIndex) => (
										<span key={i}> <a href={dashboard.missingComplianceDocuments[i]["url"]} target="_blank">
											{dashboard.missingComplianceDocuments[i]["displayName"]}
										</a>
											{docSeparator(i, dashboard.missingComplianceDocuments.length)}
										</span>
									))
									}
								</Label>
								<Row>
									<Button
										disabled={!agreeToTermsButtonEnabled}
										color="primary"
										size="lg"
										className="affiliate-agree-button">I Agree
									</Button>
								</Row>
							</Form>




						</div>

					}
					{dashboard && dashboard.missingComplianceDocuments.length <= 0 &&
						<div>
							<h1>My Share Link</h1>


							{dashboard && dashboard.affiliate &&
								<div>
									{dashboard && dashboard.affiliate.slug && !isEditLinkMode &&

										<div>
											<p>
												Share this link with your friends and earn <strong>10% of their purchases</strong> for the first year after they sign up!
											</p>
											<div className="share-link" onClick={copyToClipboard}>
												<span>{shareLink}</span>
												<i className="fa-regular fa-copy"></i>
												<span className="affiliate-tooltip" id="myTooltip">{toolTipText}</span>
											</div>
											&nbsp;<a href="#" onClick={(e) => { e.preventDefault(); setIsEditLinkMode(true); }}>Edit Link</a>
											<p className="mt-4">
												<i className="fa-duotone fa-bullseye-pointer att-icon"></i>
												&nbsp;&nbsp;Your share link has been clicked <strong>{dashboard.affiliate.affiliateLinkClicks}</strong> times.
											</p>
											<p className="alert alert-info">
												<strong>Important!</strong> When using your share link, you must disclose that you are an affiliate of Nectarine
												using language like:<br /><br />
												"I will be compensated by Nectarine if you use my affiliate link, which creates an incentive and conflict of interest. I am not a current client or employee of Nectarine."
											</p>
										</div>

									}
									{dashboard && !dashboard.affiliate.slug && !isEditLinkMode &&
										<div>
											<p>
												You haven't set your affiliate link yet. Click the button below to get started.
											</p>
											<Button color="primary" onClick={() => { setIsEditLinkMode(true) }}>Set My Affiliate Link</Button>
										</div>
									}
									{dashboard && isEditLinkMode &&
										<Form onSubmit={saveShareLink}>
											<p>Set your affiliate link below. This will be your unique share link. You can change this link at any time.</p>

											{dashboard.affiliate.affiliateLinkClicks > 0 &&
												<p className="alert alert-danger">
													<i class="fa-regular fa-triangle-exclamation"></i>&nbsp;
													<strong>Warning!</strong> Changing
													your share link will cause your old share link to no longer work.
													If your old link is posted anywhere, it should be updated so it continues to work.
													Any exising referrals will still be credited to you.
												</p>

											}

											<Row>
												<Col className="set-affiliate-title">https://hellonectarine.com/r/</Col>
												<Col className="set-affiliate-input">
													<Input type="text"
														id="slug"
														name="slug"
														value={slug}
														required
														minLength={4}
														onChange={(e) => onFormChange(e)}
														placeholder='e.g. your-name-here'
													/>
												</Col>
											</Row>

											<Button color="light" className="btn-lg" onClick={(e) => { cancelEditSlug(e); }}>Cancel</Button>&nbsp;
											<Button color="primary" className="btn-lg">Save</Button>
										</Form>

									}
								</div>

							}
							<h1>My Referrals</h1>

							{!dashboard && <LoadingSpinner message="Loading..." />}
							{dashboard && dashboard.referredUsers && dashboard.referredUsers.length > 0 &&
								<div>

									<p>Below are the users you have referred to Nectarine who signed in the last year and whose purchaes will qualify for revenue share.</p>
									<UncontrolledAccordion className="accordion-primary past-meetings">
										<AccordionItem>
											<AccordionHeader targetId="1">
												You have referred&nbsp;
												<strong>{dashboard.referredUsers.length}</strong>&nbsp;active user{dashboard.referredUsers.length != 1 ? "s" : ""}.
											</AccordionHeader>
											<AccordionBody accordionId="1">
												<table className="table advisor-events">
													<thead>
														<tr>
															<th></th>
															<th>Signed Up</th>
															<th>State</th>
														</tr>
													</thead>
													<tbody>
														{dashboard.referredUsers.map((user, index) => (
															<tr key={user.id}>
																<td>
																	{index + 1}
																</td>
																<td>
																	{dateHelper.mediumDateFormat(user.createdAt)}
																</td>
																<td>
																	{stateHelper.GetStateNameFromAbbreviation(user.state)}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</AccordionBody>
										</AccordionItem>
									</UncontrolledAccordion>

								</div>
							}

							{dashboard && dashboard.referredUsers && dashboard.referredUsers.length === 0 &&
								<p>No users have signed up with your link yet. Keep sharing!</p>
							}

							{dashboard && dashboard.eventsIncomplete && dashboard.eventsIncomplete.length > 0 &&
								<div>
									<h1>Incomplete Events</h1>
									<p>Below are the events that a client you referred has booked, but they haven't completed yet. The event will be payable to you once the event completes.</p>
									<UncontrolledAccordion className="accordion-primary past-meetings">
										<AccordionItem>
											<AccordionHeader targetId="1">
												You have a pending &nbsp;<strong>${dashboard.eventsIncomplete.reduce((sum, event) =>
													sum + (event.affiliateOwedAmount), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
												&nbsp;for&nbsp;<strong>{dashboard.eventsIncomplete.length}</strong>&nbsp;incomplete events
											</AccordionHeader>
											<AccordionBody accordionId="1">
												<table className="table advisor-events">
													<thead>
														<tr>
															<td></td>
															<th>Meeting Time</th>
															<th>Client State</th>
															<th>Amount Pending</th>
														</tr>
													</thead>
													<tbody>
														{dashboard.eventsIncomplete.map((event, index) => (
															<tr key={event.id}>
																<td>{index + 1}</td>
																<td>
																	{dateHelper.mediumDateFormat(event.startTime)}
																</td>
																<td>
																	{stateHelper.GetStateNameFromAbbreviation(event.consumerState)}
																</td>
																<td>
																	${event.affiliateOwedAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</AccordionBody>
										</AccordionItem>
									</UncontrolledAccordion>

								</div>
							}



							{dashboard && dashboard.eventsDue && dashboard.eventsDue.length > 0 &&
								<div>
									<h1>My Events Owed</h1>
									<p>Below are the completed events that are due to you. They will be included in an upcoming pay period based on the month the event was completed.</p>
									<UncontrolledAccordion className="accordion-primary past-meetings">
										<AccordionItem>
											<AccordionHeader targetId="1">
												You are owed&nbsp;<strong>${dashboard.eventsDue.reduce((sum, event) =>
													sum + (event.affiliateOwedAmount), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
												&nbsp;for&nbsp;<strong>{dashboard.eventsDue.length}</strong>&nbsp;completed events
											</AccordionHeader>
											<AccordionBody accordionId="1">
												<table className="table advisor-events">
													<thead>
														<tr>
															<td></td>
															<th>Meeting Time</th>
															<th>Client State</th>
															<th>Amount Pending</th>
														</tr>
													</thead>
													<tbody>
														{dashboard.eventsDue.map((event, index) => (
															<tr key={event.id}>
																<td>{index + 1}</td>
																<td>
																	{dateHelper.mediumDateFormat(event.startTime)}
																</td>
																<td>
																	{stateHelper.GetStateNameFromAbbreviation(event.consumerState)}
																</td>
																<td>
																	${event.affiliateOwedAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</AccordionBody>
										</AccordionItem>
									</UncontrolledAccordion>

								</div>
							}

							<h1>My Payments</h1>

							{!dashboard && <LoadingSpinner message="Loading..." />}



							{dashboard && dashboard.payments && dashboard.payments.length === 0 &&
								<p>No payments have been made to you yet.</p>
							}
							{dashboard && dashboard.payments && dashboard.payments.length > 0 &&
								<div>
									<p>Below is the list of past payments made to you.
										All time you have made <strong>${dashboard.payments.reduce((sum, payment) =>
											sum + (payment.amount), 0).toLocaleString()}</strong>
										{" "}
										from <strong>{dashboard.payments.reduce((sum, payment) =>
											sum + (payment.numEvents), 0).toLocaleString()}</strong> events.
									</p>


									<table className="table">
										<thead>
											<tr>
												<th>Payment Date</th>
												<th>Amount</th>
												<th>Events</th>
											</tr>

										</thead>
										<tbody>
											{dashboard.payments.map(item => (
												<tr key={item.id}>
													<td>{dateHelper.shortDateFormat(item.paidAt)}</td>
													<td>${item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
													<td>{item.numEvents}</td>
												</tr>

											))}
										</tbody>
									</table>
								</div>
							}
						</div>
					}

				</Col>
			</Row>
		</Container >
	);
}

