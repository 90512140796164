import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import { ImpersonateLink } from './Impersonate';
import dateHelper from '../Helpers/DateHelper';

async function populateData() {

	const params = new URLSearchParams(window.location.search);
	const q = params.get('q');

	if (!q) {
		return [];
	}

	const data = await authHelper.secureGet(`/api/admin/search?q=${encodeURIComponent(q)}`);
	console.log("Search Results Data:", data);
	return data;
}


export const AdminSearch = (props) => {

	const location = useLocation();

	const [data, setData] = useState(null);

	React.useEffect(() => {
		populateData().then((a) => { setData(a); });
	}, []);

	React.useEffect(() => {
		setData(null);
		populateData().then((a) => { setData(a); });
	}, [location.search]);


	return (
		<Container>
			<Helmet>
				<title>Nectarine - Search</title>
			</Helmet>
			<Row>
				<Col className="admin-search-page">
					{!data && <LoadingSpinner message="Loading..." />}
					{data && data.users &&
						<div>
							<h2 className="mt-0">Found <strong>{data.users.length}</strong> Matching Users</h2>
							<p>(Searches name, title, certifications, email, IP address, slug, bio)</p>
							<table className="table admin-users">
								<thead>
									<tr>
										<th>Last</th>
										<th>First</th>
										<th>Email</th>
										<th>State</th>
										<th><i class="fa-duotone fa-peach light-blue"></i></th>
										<th>Report</th>
										<th>Impersonate</th>
									</tr>
								</thead>
								<tbody>
									{data.users.map(user => (
										<tr key={user.id}>
											<td>
												<Link to={"/admin/users/" + user.id + "/edit"}>
													{user.formattedLastName}
												</Link>
											</td>
											<td>
												<Link to={"/admin/users/" + user.id + "/edit"}>
													{user.formattedFirstName}
												</Link>
											</td>
											<td>
												<Link to={"/admin/users/" + user.id + "/edit"}>
													{user.email}
												</Link>
											</td>
											<td>
												{user.state}
											</td>
											<td>
												{user.isNectarine ? <i class="fa-duotone fa-peach light-blue"></i> : ""}
											</td>
											<td>
												<Link to={"/admin/users/" + user.id + "/report"}>
													Compliance Report
												</Link>
											</td>
											<td>
												<ImpersonateLink userId={user.id} />
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					}

					{data && data.events &&
						<div>
							<h2>Found <strong>{data.events.length}</strong> Matching Events</h2>
							<p>(Searches client, email, private notes, public notes)</p>
							<table className="table">
								<thead>
									<tr>
										<th>Advisor</th>
										<th>Consumer</th>
										<th>
											<i class="fa-duotone fa-peach light-blue"></i>
										</th>
										<th>State</th>
										<th>Intake</th>
										<th>Created At</th>
										<th>Start Time</th>
										<th>Status</th>
										<th>Links</th>
										<th>Paid</th>
									</tr>
								</thead>
								<tbody>
									{data.events.map(event => (
										<tr key={event.id}>
											<td>
												<Link to={"/admin/users/" + event.advisorId + "/edit"}>
													{event.advisorFullName}
												</Link>

												{!event.advisorFullName &&
													<span>{event.advisorEmail}</span>
												}

												{event.advisorId &&
													<ImpersonateLink userId={event.advisorId} />
												}
											</td>

											<td>
												<Link to={"/admin/users/" + event.consumerId + "/edit"}>
													{event.consumerFullName}
												</Link>
												{!event.consumerFullName &&
													<span>{event.consumerEmail}</span>
												}

												{event.consumerId &&
													<ImpersonateLink userId={event.consumerId} />
												}
											</td>
											<td>
												{event.isNectarine &&
													<i class="fa-duotone fa-peach light-blue"></i>
												}
											</td>
											<td>
												{event.consumerState}
											</td>
											<td>
												{event.intakeForm &&
													<i className="fa-duotone fa-check-to-slot att-icon"></i>
												}
												{!event.intakeForm &&
													<i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i>
												}
											</td>
											<td>
												<Link to={"/admin/events/" + event.id + "/edit"}>
													{dateHelper.mediumDateFormat(event.createdAt)}
												</Link>
											</td>
											<td>{dateHelper.mediumDateFormat(event.startTime)}</td>
											<td>{event.status}</td>

											<td>
												<a href={event.location}>Zoom</a>{" "}
												<a href={event.rescheduleUrl}>Reschedule</a>{" "}
												<a href={event.cancelUrl}>Cancel</a>{" "}
												{event.consumerId && event.advisorId &&
													<a href={"/leaveareview/" + event.advisorId + "/" + event.consumerId}>Review Page</a>
												}
											</td>

											<td>
												{new Intl.NumberFormat('en-US', {
													style: 'currency',
													currency: event.paymentCurrency ? event.paymentCurrency : "USD",
													maximumFractionDigits: 0
												}).format(event.paymentAmount)}
												{
													!event.paymentSuccessful && event.paymentAmount > 0 &&
													<span className="text-danger">
														{" "}<strong>FAILED!</strong>
													</span>
												}
											</td>
										</tr>
									))}
								</tbody>
							</table>

						</div>

					}

					{data && data.reviews &&
						<div>
							<h2>Found <strong>{data.reviews.length}</strong> Matching Reviews</h2>
							<p>(Searches client, body, title)</p>
							<table className="table">
								<thead>
									<tr>
										<th>Reviewer</th>
										<th>Reviewee</th>
										<th>Rating</th>
										<th>Title</th>
										<th>Display Name</th>
										<th>Modified</th>
										<td>Danger</td>
									</tr>
								</thead>
								<tbody>
									{data.reviews.map(review => (

										<tr key={review.id}>
											<td>
												<Link to={"/admin/users/" + review.reviewerId + "/edit"}>
													{review.reviewerFullName}
												</Link>
												<ImpersonateLink userId={review.reviewerId} /></td>
											<td>
												<Link to={"/admin/users/" + review.revieweeId + "/edit"}>
													{review.revieweeFullName}
												</Link>
												<ImpersonateLink userId={review.revieweeId} /></td>
											<td>{review.rating}</td>
											<td>{review.title}</td>
											<td>{review.displayName}</td>
											<td>{dateHelper.mediumDateFormat(review.createdAt)}</td>
										</tr>

									))}
								</tbody>
							</table>
						</div>
					}

					{data && data.userAgreements &&
						<div>

							<h2>Found <strong>{data.userAgreements.length}</strong> Matching User Agreements</h2>
							<p>(Searches client, IP address)</p>

							<table className="table">
								<thead>
									<tr>
										<th>Name</th>
										<th>Email</th>
										<th>State</th>
										<th>IP&nbsp;Address</th>
										<th>Timestamp</th>
										<th>Advisor</th>
										<th>Documents</th>
									</tr>
								</thead>
								<tbody>
									{data.userAgreements.map(ua => (
										<tr key={ua.id}>
											<td>
												<Link to={"/admin/users/" + ua.consumer.id + "/edit"}>
													{ua.firstName} {ua.lastName}
												</Link> <ImpersonateLink userId={ua.consumer.id} />
											</td>
											<td>
												<Link to={"/admin/users/" + ua.consumer.id + "/edit"}>
													{ua.emailAddress}
												</Link>
											</td>
											<td>
												{ua.stateAbbreviation}
											</td>
											<td>
												{ua.ipAddress}
											</td>
											<td>
												{new Date(ua.agreedAtTime).toLocaleString()}
											</td>
											<td>
												{ua.advisor &&
													<div>
														<Link to={"/admin/users/" + ua.advisor.id + "/edit"}>
															{ua.advisor.firstName} {ua.advisor.lastName}
														</Link> <ImpersonateLink userId={ua.advisor.id} />
													</div>
												}
											</td>
											<td>
												{ua.complianceDocuments.map(doc => (
													<span key={doc.id}>
														<a href={doc.url} target="_blank">
															{doc.displayName}
														</a>
														<span>, </span>
													</span>
												))}
											</td>
										</tr>
									))}
								</tbody>
							</table>

						</div>

					}

				</Col>
			</Row>
		</Container >
	);
}
