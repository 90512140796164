import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import { Outlet } from 'react-router-dom';
import { LoadingSpinner } from "./LoadingAnimations";
import './Layout.css';

export const LoadingBlank = ({ children }) => {

	return (
		<div>
			<Container tag="main">
				<LoadingSpinner />
			</Container>
		</div>
	);
}

