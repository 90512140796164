import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';

async function populateReviews() {
	const data = await authHelper.secureGet('api/advisor/reviews');
	console.log("Reviews:", data);
	return data;
}


export const AdvisorReviews = () => {

	const [reviews, setReviews] = useState(null);

	React.useEffect(() => {
		populateReviews().then((a) => { setReviews(a); });
	}, []);


	return (
		<Container>
			<Helmet>
				<title>Nectarine - My Account</title>
			</Helmet>
			<Row md="12">
				<Col>
					<h1 className="mb-4">My Reviews</h1>

					{!reviews && <LoadingSpinner message="Loading..." />}
					{reviews && reviews.length == 0 &&
						<p>
							You have not yet received any reviews. When you do they'll appear here!
						</p>
					}
					{reviews && reviews.length > 0 &&

						<div>
							<p>
								You have received <strong>{reviews.length}</strong> reviews with an average rating of <strong>{(reviews.reduce((a, b) => a + b.rating, 0) / reviews.length).toFixed(2)}</strong> stars.
							</p>

							{
								reviews.sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt)).map(review => (
									<div key={review.id} className="details-review">
										{Array.from({ length: review.rating }, () => (
											<i className="fa-star fa-solid att-icon"></i>
										))}
										<h3>{review.title}</h3>
										<p>
											{review.body}
										</p>

										<div className="review-footer">
											<div className="reviewer-name">{review.displayName}</div>
											<div className="review-date">{dateHelper.printMonthYearLong(review.modifiedAt)}</div>
										</div>
									</div>
								))
							}
						</div>
					}

				</Col>
			</Row>
		</Container>
	);
}
