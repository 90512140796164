import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import './Advisor.css';
import { ComplianceReportContent } from '../ComplianceReportContent';

async function populateComplianceReport(userID) {

	const data = await authHelper.secureGet('api/advisor/clients/' + userID + "/report");

	return data;
}

export const ComplianceReport = () => {

	let { userID } = useParams();

	const [complianceReport, setComplianceReport] = useState(null);

	React.useEffect(() => {
		populateComplianceReport(userID).then((a) => { setComplianceReport(a); });
	}, []);

	return (
		<Container>
			<ComplianceReportContent complianceReport={complianceReport} />
		</Container >
	);
}
