import React, { Component, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import heroImage from "./../img/investing-experts.png";
import briefcaseImage from "./../img/person-with-briefcase.png";
import meditationImage from "./../img/meditation.png";
import bicycleImage from "./../img/bicycle-rider.png";
import joinImage from "./../img/join-icon.png";
import setupImage from "./../img/setup-icon.png";
import chatImage from "./../img/chat-icon.png";
import dollarImage from "./../img/dollar-icon.png";
import wateringImage from "./../img/watering-plants.png";
import './Recruit.css';

export class Affiliates extends Component {

	render() {

		var signUpLink = "/register?affiliate=true";

		return (
			<Container>
				<Helmet>
					<title>Become a Nectarine Affiliate</title>
				</Helmet>
				<Row className="hero-row" md="2" sm="1" xs="1">
					<Col>
						<h1>Become a Nectarine Affiliate</h1>
						<h3>Your audience gets no-nonsense expert fiduciary advice. You get a 10% commission of their first year's sales.</h3>
						<div className="cta">
							<Button color="primary" size="lg" href={signUpLink}>Sign Up Now</Button>
						</div>
					</Col>
					<Col>
						<img src={heroImage} className="hero center" alt="Nectarine Investing Experts Illustration" />
					</Col>
				</Row>
				<Row>
					<Col className="affiliate-works">
						<h2>How It Works</h2>
						<Container>
							<Row md="3" sm="1" xs="1">
								<Col>

									<img src={joinImage} className="" alt="Just show up icon" />
									<h2>Start in 60 Seconds</h2>
									<p>
										Create a Nectarine account and get your unique sharing link.
									</p>

								</Col>
								<Col>
									<img src={chatImage} className="" alt="Set your own schedule icon" />
									<h2>Share your link</h2>
									<p>
										On your website, social media, podcast, anywhere! We'll provide proper disclosure language as well.
									</p>
								</Col>
								<Col>
									<img src={dollarImage} className="" alt="Less paperwork icon" />
									<h2>Get Paid</h2>
									<p>
										Get paid <strong>10%</strong> commission on the first <strong>year</strong> of sales for every client you refer. Payouts are monthly for completed sales.
									</p>
								</Col>

							</Row>

						</Container>
						<div className="cta">
							<Button color="primary" size="lg" href={signUpLink}>Become a Nectarine Affiliate</Button>
						</div>

					</Col>

				</Row>


			</Container >

		);
	}
}