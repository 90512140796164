import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Form, Button } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';

async function populateData(report) {
	const url = 'api/admin/reports/' + report;
	console.log("URL:", url);
	const data = await authHelper.secureGet(url);
	console.log("Data:", data);
	return data;
}


export const AdminReports = (props) => {

	const [data, setData] = useState(null);
	const [totals, setTotals] = useState(null);
	const [averages, setAverages] = useState(null);
	const [selectedReport, setSelectedReport] = useState(null);

	React.useEffect(() => {
		if (selectedReport) {
			populateData(selectedReport).then((a) => {
				setData(a);

				const tempTotals = {};
				a.forEach(record => {
					Object.keys(record).forEach(key => {
						if (typeof record[key] === 'number') {
							tempTotals[key] = (tempTotals[key] || 0) + record[key];
						}
					});
				});

				const tempAverages = {};
				Object.keys(a[0]).forEach(key => {
					tempAverages[key] = tempTotals[key] / a.length;
				});

				setTotals(tempTotals);
				setAverages(tempAverages);
			});
		}
	}, [selectedReport]);

	const reportChanged = async (event) => {
		event.preventDefault();
		var report = event.target.value;
		setSelectedReport(report);
	}

	const convertToCSV = (data) => {
		const keys = Object.keys(data[0]);
		const header = keys.join(',');
		const rows = data.map(record => keys.map(key => record[key]).join(',')).join('\n');

		return `${header}\n${rows}`;
	};

	// Function to download the CSV file
	const downloadCSV = () => {
		const csvContent = convertToCSV(data);
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', selectedReport + '.csv');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Container>
			<Helmet>
				<title>Nectarine - Admin Reports</title>
			</Helmet>
			<Row>
				<Col>
					<Link to="/admin"><i className="fa-regular fa-arrow-left back-arrow"></i> Back</Link>
					<h1>Admin Reports</h1>
					<Form>
						<select id="payPeriod" name="payPeriod" onChange={reportChanged} value={selectedReport}>
							<option value="">Select a report to view</option>
							<option value="all-advisor-payments">All Advisor Payments</option>
							<option value="all-affiliate-payments">All Affiliate Payments</option>
							<option value="nectarine-clients-by-state">Unique Nectarine Clients By State</option>
							<option value="non-nectarine-clients-by-state">Unique Non-Nectarine Clients By State</option>
							<option value="average-review-score">Average review score of all reviews</option>
							<option value="lifetime-customer-value">Average lifetime customer value</option>
							<option value="lifetime-customer-value-by-signup-month">Average lifetime customer value by signup month</option>
							<option value="all-affiliate-events">Last 500 Affiliate Events</option>
							<option value="top-affilite-link-clicks">Top Affiliate Link Clicks</option>
							<option value="top-affiliates-trailing-year">Top Affiliates By Revenue Trailing Year</option>
							<option value="accounts-payable-by-advisor">Unpaid amount owed by advisor</option>
							<option value="all-events-by-date">All Events By Date</option>
							<option value="rebookings-by-advisor">Rebookings By Advisor</option>
							<option value="review-rate-by-advisor">Review Rate By Advisor</option>
						</select>
					</Form>

					{data && data.length > 0 &&
						<div>
							<p>
								Showing <strong>{data.length}</strong> records.
							</p>
							<p>
								<Button color="primary" onClick={downloadCSV}>Download as CSV</Button>
							</p>
							<table className="table">
								<thead>
									<tr>
										<th>#</th>
										{Object.keys(data[0]).map(attribute => (
											<th key={attribute}>{attribute}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{data.map((record, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											{Object.values(record).map((value, idx) => (
												<td key={idx}>{value}</td>
											))}
										</tr>
									))}
								</tbody>
								{totals &&
									<tfoot style={{ border: '2px solid black' }}>
										<tr>
											<td style={{ backgroundColor: '#f5f5f5' }}>Sum</td>
											{Object.keys(data[0]).map((attribute, idx) => (
												<td key={idx} style={{ backgroundColor: '#f5f5f5' }}>
													{typeof data[0][attribute] === 'number' && totals[attribute]}
												</td>
											))}
										</tr>
										<tr>
											<td style={{ backgroundColor: '#f0f0f0' }}>Average</td>
											{Object.keys(data[0]).map((attribute, idx) => (
												<td key={idx} style={{ backgroundColor: '#f0f0f0' }}>
													{typeof data[0][attribute] === 'number' && averages[attribute]}
												</td>
											))}
										</tr>
									</tfoot>
								}
							</table>
						</div>
					}
				</Col>
			</Row>
		</Container >
	);
}
