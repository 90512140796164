import React, { Component, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import { RoleRestriction } from './components/RoleRestriction';
import { Counter } from "./components/Counter";
import { Contact } from "./components/Contact";
import { About } from "./components/About";
import { Booked } from "./components/Booked";
import { Terms } from "./components/Terms";
import { Redirect } from "./components/Redirect";
import { Privacy } from "./components/Privacy";
import { Recruit } from "./components/Recruit";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { AdminRoles } from "./components/Admin/Roles";
import { AdminCreateRole } from "./components/Admin/CreateRole";
import { AdminEditRole } from "./components/Admin/EditRole";
import { AdminUsers } from "./components/Admin/Users";
import { AdminEditUser } from "./components/Admin/EditUser";
import { AdminUserAgreements } from "./components/Admin/UserAgreements";
import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { AdminLayout } from './components/Admin/Layout';
import { AccountLayout } from './components/Account/Layout';
import { AdvisorLayout } from './components/Advisor/Layout';
import { AdvisorReviews } from './components/Advisor/Reviews';
import { AdminIndex } from './components/Admin/Index';
import { AccountIndex } from './components/Account/Index';
import { AccountAdvisors } from './components/Account/Advisors';
import { AccountReviews } from './components/Account/Reviews';
import { AdvisorIndex } from './components/Advisor/Index';
import { ComplianceReport } from './components/Advisor/ComplianceReport';
import { AdvisorProfile } from './components/Advisor/Profile';
import { Register } from './components/Register';
import { AdminReviews } from './components/Admin/Reviews';
import { AdminEvents } from './components/Admin/Events';
import { AdminEditEvent } from './components/Admin/EditEvent';
import { LeaveAReview } from './components/LeaveAReview';
import ScrollToTop from './components/ScrollToTop';
import { AdvisorDetails } from './components/AdvisorDetails';
import { AdvisorsResults } from './components/AdvisorsResults';
import { AdminDocuments } from './components/Admin/Documents';
import { AdminComplianceReport } from './components/Admin/ComplianceReport';
import { AdvisorAttributes } from './components/Admin/AdvisorAttributes';
import { LandingPage_HourlyFinancialAdvisors } from './components/LandingPages/HourlyFinancialAdvisors';
import { LandingPage_FlatFeeFinancialPlanners } from './components/LandingPages/FlatFeeFinancialPlanners';
import { AffiliateLandingPage } from './components/LandingPages/AffiliateLandingPage';
import { AdvisorClient } from './components/Advisor/Client';
import { TestPage } from './components/Test';
import { NotFound } from './components/404';
import { AdminRecordings } from './components/Admin/Recordings';
import { AdminEditRecording } from './components/Admin/EditRecording';
import { AdminPayments } from './components/Admin/Payments';
import { AdminReports } from './components/Admin/Reports';
import { LandingPages } from './components/LandingPages/LandingPages';
import { AdvisorPayments } from './components/Advisor/Payments';
import { AdminSearch } from './components/Admin/Search';
import { AccountAffiliate } from './components/Account/Affiliate';
import { AdminAdvisorPayments } from './components/Admin/Payments_Advisor';
import { AdminAffiliatePayments } from './components/Admin/Payments_Affiliate';
import { NonUnitedStates } from './components/NonUnitedStates';
import { Affiliates } from './components/Affiliates';
import { ThePlanTerms } from './components/ThePlanTerms';
import { AdminIntakeForms } from './components/Admin/IntakeForms';


export class AppRoutes extends Component {
	render() {
		return (
			<div>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="counter" element={<Counter />} />
						<Route path="contact" element={<Contact />} />
						<Route path="/s/:urlState/:singleFilter?" element={<AdvisorsResults />} />
						<Route path="advisors" element={<AdvisorsResults />} />
						<Route path="advisors/:singleFilter" element={<AdvisorsResults />} />
						<Route path="/advisor/:slug" element={<AdvisorDetails />} />
						<Route path="about" element={<About />} />
						<Route path="booked" element={<Booked />} />
						<Route path="terms" element={<Terms />} />
						<Route path="recruit" element={<Recruit />} />
						<Route path="affiliates" element={<Affiliates />} />
						<Route path="non-us" element={<NonUnitedStates />} />
						<Route path="apply" element={<Redirect url="https://hellonectarine.typeform.com/advisors" />} />
						<Route path="privacy" element={<Privacy />} />
						<Route path="fetch-data" element={<FetchData />} />
						<Route path="test" element={<TestPage />} />
						<Route path="theplan-terms">
							<Route index element={<ThePlanTerms />} />
							<Route path=":slug" element={<ThePlanTerms />} />
						</Route>
						<Route path="leaveareview/:adminID/:consumerID" element={<LeaveAReview />} />
						{/* SEO Landing Pages */}
						<Route path="hourly-financial-advisors" element={<LandingPage_HourlyFinancialAdvisors />} />
						<Route path="flat-fee-financial-planners" element={<LandingPage_FlatFeeFinancialPlanners />} />
						<Route path="/services/:slug" element={<LandingPages />} />
						<Route path="/r/:slug" element={<AffiliateLandingPage />} />

						<Route path="admin" element={<RoleRestriction allowedRoles="Admin" path="admin" element={<AdminLayout />} />}>
							<Route index element={<AdminIndex />} />
							<Route path="search" element={<AdminSearch />} />
							<Route path="users">
								<Route index element={<AdminUsers />} />
								<Route path=":userID/edit" element={<AdminEditUser />} />
							</Route>
							<Route path="roles">
								<Route index element={<AdminRoles />} />
								<Route path=":roleID/edit" element={<AdminEditRole />} />
								<Route path="create" element={<AdminCreateRole />} />
							</Route>
							<Route path="useragreements" element={<AdminUserAgreements />} />
							<Route path="reviews" element={<AdminReviews />} />
							<Route path="documents" element={<AdminDocuments />} />
							<Route path="advisorattributes" element={<AdvisorAttributes />} />
							<Route path="advisorpayments" element={<AdminAdvisorPayments />} />
							<Route path="affiliatepayments" element={<AdminAffiliatePayments />} />
							<Route path="reports" element={<AdminReports />} />
							<Route path="intakeforms" element={<AdminIntakeForms />} />
							<Route path="events">
								<Route index element={<AdminEvents />} />
								<Route path=":eventID/edit" element={<AdminEditEvent />} />
							</Route>
							<Route path="recordings">
								<Route index element={<AdminRecordings />} />
								<Route path=":recordingID/edit" element={<AdminEditRecording />} />
							</Route>

						</Route>
					</Route>
					<Route path="account" element={<RoleRestriction allowedRoles="Admin,Consumer" element={<AccountLayout />} />}>
						<Route index element={<AccountIndex />} />
						<Route path="advisors" element={<AccountAdvisors />} />
						<Route path="reviews" element={<AccountReviews />} />
						<Route path="share" element={<AccountAffiliate />} />
					</Route>
					<Route path="dashboard" element={<RoleRestriction allowedRoles="Admin,Advisor" element={<AdvisorLayout />} />}>
						<Route index element={<AdvisorIndex />} />
						<Route path="profile" element={<AdvisorProfile />} />
						<Route path="reviews" element={<AdvisorReviews />} />
						<Route path="client/:clientID" element={<AdvisorClient />} />
						<Route path="payments" element={<AdvisorPayments />} />
					</Route>
					<Route path="admin/users/:userID/report" element={<AdminComplianceReport />} />
					<Route path="dashboard/compliance/:userID/report" element={<ComplianceReport />} />
					{ApiAuthorzationRoutes.map((route, index) => {
						const { element, requireAuth, ...rest } = route;
						return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
					})}


					{/*No Template*/}
					<Route path="apply" element={<Redirect url="https://hellonectarine.typeform.com/advisors" />} />
					<Route path="register" element={<Register />} />

					{ApiAuthorzationRoutes.map((route, index) => {
						const { element, requireAuth, ...rest } = route;
						return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
					})}
					<Route path="/" element={<Layout />}>
						<Route path="*" element={<NotFound />} /> {/* Catch-all route 404 page */}
					</Route>
				</Routes>
			</div>

		);
	}
}

//const AppRoutes = [
//    {
//        index: true,
//        element: <Home />
//    },
//    {
//        path: '/counter',
//        element: <Counter />
//    },
//    {
//        path: '/match',
//        element: <Match />
//    },
//    {
//        path: '/about',
//        element: <About />
//    },
//    {
//        path: '/terms',
//        element: <Terms />
//    },
//    {
//        path: '/privacy',
//        element: <Privacy />
//    },
//    {
//        path: '/fetch-data',
//        element: <FetchData />
//    }
//];

export default AppRoutes;
