import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';

export class AdminRoles extends Component {
	static displayName = AdminRoles.name;

	constructor(props) {
		super(props);
		this.state = {
			roles: []
		};
	}

	componentDidMount() {
		this.populateRoles();
	}

	async populateRoles() {
		const token = await authService.getAccessToken();
		const response = await fetch('api/admin/roles', {
			headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
		});
		const data = await response.json();
		console.log("Roles", data);
		this.setState({ roles: data, loading: false });
	}

	render() {
		return (
			<Container>
				<Helmet>
					<title>Admin / Roles</title>
				</Helmet>
				<Row md="2" sm="1" xs="1">
					<Col>
						<h1>Roles</h1>
						<Button href="/admin/roles/create">New Role</Button>
						<ul>
							{this.state.roles.map(role => (
								<li key={role.id}>
									<a key={role.id} href={"/admin/roles/" + role.id + "/edit"}>
										{role.name}
									</a>
								</li>
							))}
						</ul>
					</Col>
				</Row>
			</Container>
		);
	}
}
