import authService from '../api-authorization/AuthorizeService';

export class AuthorizeHelper {

	//Use for testing to emulate network delays (remove in prod!) 
	//Use like this: await authHelper.wait(1000);
	async wait(ms) {
		await new Promise(r => setTimeout(r, ms));
	}

	async secureGet(url) {
		const token = await authService.getAccessToken();
		const response = await fetch(url, {
			headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
		});

		var data = null;
		try {
			data = response.status === 204 ? [] : await response.json();
		}
		catch (error) {
			//console.error("secureGet Error", error);
		}

		return data;
	}

	async securePut(url, body) {
		return this.secureVerb(url, "PUT", body);
	}

	async securePost(url, body) {
		return this.secureVerb(url, "POST", body);
	}

	async secureDelete(url, body) {
		return this.secureVerb(url, "DELETE", body);
	}

	async secureVerb(url, verb, body) {
		const token = await authService.getAccessToken();

		const bodyContent = JSON.stringify(body);

		return fetch(url, {
			method: verb,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
				'Referer': window.location.href
			},
			body: bodyContent
		})
	}


}


const authHelper = new AuthorizeHelper();

export default authHelper;
