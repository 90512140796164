import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Form, FormGroup, Container, Row, Col, Button, Input } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import dateHelper from '../Helpers/DateHelper';
import { ImpersonateLink } from './Impersonate';


export const AdminPayments = (props) => {

	const [data, setData] = useState(null);
	const [filteredEvents, setFilteredEvents] = useState(null);
	const [user, setUser] = useState(null);
	const [selectedEvents, setSelectedEvents] = useState([]);
	const [selectedPayPeriod, setSelectedPayPeriod] = useState(null);
	const [newPayment, setNewPayment] = useState(null);

	const userTypeLower = props.userTitle.toLowerCase();

	const payPeriodChanged = async (event) => {
		event.preventDefault();
		var payPeriod = event.target.value;
		setSelectedPayPeriod(payPeriod);
	}

	React.useEffect(() => {
		if (selectedPayPeriod) {
			searchPayments();
		}
	}, [selectedPayPeriod]);

	const searchPayments = async () => {
		setData(null);

		console.log("selectedPayPeriod", selectedPayPeriod);

		const data = await authHelper.secureGet('api/admin/' + userTypeLower + 'payments/' + selectedPayPeriod);
		console.log("Data:", data);
		setData(data);
		setFilteredEvents(data.events);
	};

	const filter = (e, userID) => {
		e.preventDefault();
		var filteredEvents;
		filteredEvents = data.events.filter(event => event[userTypeLower + "Id"] == userID);
		setFilteredEvents(filteredEvents);
		return false;
	}

	const logPaymentMode = (e, a) => {
		filter(e, a.userId);
		setUser(a);
		setSelectedEvents([]);
		const today = new Date().toISOString().split('T')[0];
		setNewPayment({ paidAt: today });
	}

	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;
		if (checked) {
			setSelectedEvents((prevSelected) => [...prevSelected, value]);
		} else {
			setSelectedEvents((prevSelected) =>
				prevSelected.filter((state) => state !== value)
			);
		}
	};

	function onFormChange(e) {
		console.log("e", e);
		setNewPayment(a => ({
			...a,
			[e.target.id]: e.target.value,
		}))
		console.log("onFormChange", e);
	}

	const logPayment = async (e) => {
		e.preventDefault();

		if (!selectedEvents || selectedEvents.length == 0) {
			alert("Please select at least one event to log a payment.");
			return false;
		}

		// Package the selected events with the newPayment object
		newPayment.eventIDs = selectedEvents;

		//Treat the date as UTC
		newPayment.paidAt = new Date(newPayment.paidAt + 'T00:00:00Z');

		const url = "/api/admin/" + userTypeLower + "payments/" + user.userId + "/logpayment";
		authHelper.securePost(url, newPayment)
			.then(response => {
				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) })
				}
			})
			.then(() => {
				console.log("End logPayment");
				setUser(null);
				searchPayments();
			})
			.catch(error => { /*showError(error);*/
				alert(error);
				console.error('Unable to add item.', error)
			});

		return false;
	}

	// Format date to 'YYYY-MM-DD' string
	const formatDate = (date) => {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	};


	return (
		<Container>
			<Helmet>
				<title>Nectarine - {props.userTitle} Payments</title>
			</Helmet>
			<Row>
				<Col>
					{!user &&
						<Form>
							<select id="payPeriod" name="payPeriod" onChange={payPeriodChanged} value={selectedPayPeriod}>
								<option value="">Choose a Pay Period</option>
								<option value="unsubmitted-notes">Unsubmitted Notes</option>
								{props.payPeriods.map((range, index) => (
									<option key={index} value={formatDate(range.start)}>
										{dateHelper.shortDateFormat(range.start)} - {dateHelper.shortDateFormat(range.end)}
									</option>
								))}

							</select>{" "}
						</Form>
					}
					{user &&
						<div>
							<h2>Log a Payment For {user.userFullName}</h2>
							<p>
								Select the events below to log a payment for {user.userFullName}. The total number of events and amount owed will appear below.
								Once that is correct, click "Log Payment" to mark all of those events as paid and create a record of the payment. For now,
								the actual payment will still need to be sent outside of Nectarine.
							</p>
							<p>
								<strong>Events Selected:</strong> {selectedEvents.length}
							</p>
							<p>
								<strong>Payment Amount:</strong>

								{" "}
								${filteredEvents.filter(event => selectedEvents.includes(event.id)).reduce((sum, event) => sum + event[userTypeLower + "OwedAmount"], 0).toLocaleString()}
							</p>
							<p>
								<strong>Payment Date:</strong>
								<Input type="date" id="paidAt" name="paidAt" onChange={onFormChange} style={{ width: '150px' }} value={newPayment.paidAt} />
							</p>


							<Button color="light" size="lg" onClick={(e) => { setUser(null); setFilteredEvents(data.events); }}>Cancel</Button>{" "}
							<Button color="primary" size="lg" onClick={(e) => logPayment(e)}>Log Payment</Button>
							<hr />
						</div>
					}

					{selectedPayPeriod && !data && <LoadingSpinner message="Loading..." />}

					{
						data && data.length == 0 &&
						<div>No data.</div>
					}

					{data && data.userPaymentDetails && !user &&
						<div>
							<h2>Payment by {props.userTitle} Summary</h2>
							<p>
								Showing <strong>{data.userPaymentDetails.length}</strong> {props.userTitle.toLowerCase()}s. {" "}
								Total Owed:{" "}
								<strong>${data.userPaymentDetails.reduce((sum, user) =>
									sum + user.totalOwedAmount, 0).toLocaleString()}</strong>
							</p>
							<table className="table">
								<thead>
									<tr>
										<th>{props.userTitle}</th>
										<th>Total Paid</th>
										<th>Events Paid</th>
										<th>Total Owed</th>
										<th>Events Owed</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{data.userPaymentDetails
										.map(a => (
											<tr key={a.userId}>
												<td>
													<Link to={"/admin/users/" + a.userId + "/edit"}>
														{a.userFullName}
													</Link>

													{!a.userFullName &&
														<span>{a.userEmail}</span>
													}

													{a.userId &&
														<ImpersonateLink userId={a.userId} />
													}
												</td>

												<td>
													${a.totalPaidAmount.toLocaleString()}
												</td>
												<td>
													{a.eventsPaid.toLocaleString()}
												</td>
												<td>
													${a.totalOwedAmount.toLocaleString()}
												</td>
												<td>
													{a.eventsOwed.toLocaleString()}
												</td>
												<td>
													{a.totalOwedAmount > 0 &&
														<Button color="primary" size="sm" onClick={(e) => logPaymentMode(e, a)}>Log Payment...</Button>
													}
												</td>
											</tr>
										))}
								</tbody>
							</table>

						</div>
					}

					{filteredEvents &&
						<div>
							<h2>Events Details</h2>
							<p>
								Showing <strong>{filteredEvents.length}</strong> events. {" "}
								Total Owed:{" "}
								<strong>${filteredEvents.reduce((sum, event) =>
									sum + (event.paymentSuccessful && event.status == "Active"
										? event[userTypeLower + "OwedAmount"] : 0), 0).toLocaleString()}</strong>
							</p>

							{/* Select all checkboxes link */}
							{user &&
								<p>
									<a href="#" onClick={(e) => {
										e.preventDefault();
										setSelectedEvents(filteredEvents.filter(event => event[userTypeLower + "Paid"] == false).map(event => event.id));
									}}>Select All</a>{" - "}
									<a href="#" onClick={(e) => {
										e.preventDefault();
										setSelectedEvents([]);
									}}>Select None</a>
								</p>
							}

							<table className="table">
								<thead>
									<tr>
										{user &&
											<th></th>
										}
										<th>{props.userTitle}</th>
										<th>Consumer</th>
										<th>
											<i class="fa-duotone fa-peach light-blue"></i>
										</th>
										<th>State</th>
										<th>Start Time</th>
										<th>Notes Sent</th>
										<th>Status</th>
										<th>Client Paid</th>
										<th>{props.userTitle} Paid</th>
										<th>{props.userTitle} Owed</th>
									</tr>
								</thead>
								<tbody>
									{filteredEvents.map(event => (
										<tr key={event.id}>
											{user &&
												<td>
													{!event.userPaid &&
														<Input
															type="checkbox"
															value={event.id}
															checked={selectedEvents.includes(event.id)}
															onChange={handleCheckboxChange}
														/>
													}
												</td>
											}
											<td>
												<Link to={"/admin/users/" + event.userId + "/edit"}>
													{event[userTypeLower + "FullName"]}
												</Link>

												{!event.userFullName &&
													<span>{event.userEmail}</span>
												}

												{event.userId &&
													<ImpersonateLink userId={event.userId} />
												}
											</td>

											<td>
												<Link to={"/admin/users/" + event.consumerId + "/edit"}>
													{event.consumerFullName}
												</Link>
												{!event.consumerFullName &&
													<span>{event.consumerEmail}</span>
												}

												{event.consumerId &&
													<ImpersonateLink userId={event.consumerId} />
												}
												{" "}
												<Link to={"/admin/users/" + event.consumerId + "/report"}><i class="fa-duotone fa-file-invoice"></i></Link>
											</td>
											<td>
												{event.isNectarine &&
													<i class="fa-duotone fa-peach light-blue"></i>
												}
											</td>
											<td>
												{event.consumerState}
											</td>
											<td>
												<Link to={"/admin/events/" + event.id + "/edit"}>
													{dateHelper.mediumDateFormat(event.startTime)}
												</Link>
											</td>
											<td>{dateHelper.mediumDateFormat(event.notesSentAt)}</td>
											<td>{event.status}</td>
											<td>
												{new Intl.NumberFormat('en-US', {
													style: 'currency',
													currency: event.paymentCurrency ? event.paymentCurrency : "USD",
													maximumFractionDigits: 0
												}).format(event.paymentAmount)}
												{
													!event.paymentSuccessful && event.paymentAmount > 0 &&
													<span className="text-danger">
														{" "}<strong>FAILED!</strong>
													</span>
												}
											</td>
											<td>
												${event[userTypeLower + "PaidAmount"].toLocaleString()}
											</td>
											<td>
												${event[userTypeLower + "OwedAmount"].toLocaleString()}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					}
				</Col>
			</Row>
		</Container>
	);
}
