import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';


export const SortResultsDropDown = (props) => {

	const navigate = useNavigate();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const [selectedItem, setSelectedItem] = useState('Next Available');

	const handleSelect = (event) => {
		setSelectedItem(event.target.innerText);
		updateUrlWithSort(event.target.value);
	};

	const updateUrlWithSort = (sort) => {
		const url = new URL(window.location.href);
		url.searchParams.set('sort', sort);
		navigate(url.pathname + url.search);
	};

	React.useEffect(() => {
	}, []);


	return (
		<Dropdown color="light" isOpen={dropdownOpen} toggle={toggle}>

			<DropdownToggle color="light"><i className="fa-duotone fa-sort"></i>&nbsp; Sort by: {selectedItem}</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={handleSelect} value="available"><i className="fa-regular fa-calendar"></i> Next Available</DropdownItem>
				<DropdownItem onClick={handleSelect} value="price"><i className="fa-solid fa-dollar-sign"></i> Lowest Price</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}
