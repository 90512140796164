

export class DateHelper {

	//August 22, 2023 at 1:11 PM PDT
	longDateFormat(timestamp) {
		return new Date(timestamp).toLocaleString('en-US', {
			month: 'long',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
			timeZoneName: 'short'
		}).replace(/(\d)(st|nd|rd|th)/, '$1<sup>$2</sup>');
	}

	//8/24/2023 9:16pm
	mediumDateFormat(timestamp) {
		const date = new Date(timestamp);
		const options = {
			month: 'numeric',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true
		};

		return date.toLocaleString('en-US', options);
	}

	//8/24/2023
	shortDateFormat(timestamp) {
		const date = new Date(timestamp);
		const options = {
			month: 'numeric',
			day: 'numeric',
			year: 'numeric',
		};

		return date.toLocaleString('en-US', options);
	}


	//Same as medium, but replaces date with "Today" or "Tomorrow"
	casualDateFormat(timestamp) {
		const date = new Date(timestamp);

		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(today.getDate() + 1);

		if (date.toDateString() === today.toDateString()) {
			return "Today, " + date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
		}

		if (date.toDateString() === tomorrow.toDateString()) {
			return "Tomorrow, " + date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
		}

		const options = {
			month: 'numeric',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true
		};

		return date.toLocaleString('en-US', options);
	}

	getFullYearsBetweenDates(startDate, endDate) {
		// Calculate the difference in years
		const yearDifference = endDate.getFullYear() - startDate.getFullYear();

		// Check if the end date has not occurred yet in the current year
		if (
			endDate.getMonth() < startDate.getMonth() ||
			(endDate.getMonth() === startDate.getMonth() &&
				endDate.getDate() < startDate.getDate())
		) {
			return yearDifference - 1;
		} else {
			return yearDifference;
		}
	}

	isValidDate(dateString) {
		// Attempt to create a Date object from the provided string
		const dateObject = new Date(dateString);

		// Check if the created object is a valid date
		return !isNaN(dateObject.getTime());
	}


	printYearRange(startDateText, endDateText) {
		const startDate = new Date(startDateText);
		const endDate = new Date(endDateText);
		const minDate = new Date(0);
		const today = new Date();

		if (startDate > minDate && endDate > minDate && endDate < today) {
			//Return full year start and end
			return startDate.getUTCFullYear() + " - " + endDate.getUTCFullYear();

		}
		if (startDate > minDate) {
			return startDate.getUTCFullYear() + " - Present";
		}

		return "";
	}

	printMonthYearLong(dateString) {
		const dateObject = new Date(dateString);
		const formattedDate = dateObject.toLocaleString('en-US', { month: 'long', year: 'numeric' });
		return formattedDate;
	}


}

const dateHelper = new DateHelper();
export default dateHelper;
