import React, { useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, ButtonGroup, Form, Input, Alert, Label } from 'reactstrap';
import { LoadingSpinner } from "../LoadingAnimations";
import { ReviewForm } from "../ReviewForm";
import authHelper, { AuthorizeHelper } from '../Helpers/AuthorizeHelper';
import stateHelper from '../Helpers/StateHelper';
import dateHelper from '../Helpers/DateHelper';

async function populateAdvisorsToReview() {
	const data = await authHelper.secureGet('/api/account/advisorstoreview')
		.catch(error => { console.error('Error getting advisors!', error) });
	console.log("Advisors", data);
	return data;
}

async function populateReviews() {
	const data = await authHelper.secureGet('/api/account/reviews')
		.catch(error => { console.error('Error getting reviews!', error) });
	console.log("Reviews", data);
	return data;
}

export const AccountReviews = () => {

	const [advisorsToReview, setAdvisorsToReview] = useState(null);
	const [advisorBeingReviewed, setAdvisorBeingReviewed] = useState(null);
	const [review, setReview] = useState({}); //The review this user is editing
	const [showSavedMessage, setShowSavedMessage] = useState(false);
	const [errorSection, setErrorSection] = useState({ show: false, text: null });
	const [reviews, setReviews] = useState(null); //The reviews this user has posted
	const [user, setUser] = useOutletContext();

	React.useEffect(() => {
		populatePageData();
	}, []);

	function populatePageData() {
		populateReviews().then((a) => setReviews(a));
		populateAdvisorsToReview().then((a) => setAdvisorsToReview(a));
	}

	function onEditReview(e, review) {
		e.preventDefault();

		var advisor = {};
		advisor.firstName = review.revieweeFirstName;
		advisor.lastName = review.revieweeLastName;
		advisor.certifications = review.revieweeCertifications;
		advisor.id = review.revieweeId;
		advisor.fullName = review.revieweeFullName;
		advisor.profilePicUrl = review.revieweeProfilePicUrl;

		setReview(review);
		setAdvisorBeingReviewed(advisor);

		return false;
	}

	const showError = (text) => {

		let errorSection = {};

		errorSection.show = true;
		errorSection.text = text.toString();

		setErrorSection(errorSection);
	}

	function onUserChange(e) {
		setUser(a => ({
			...a,
			[e.target.name]: e.target.value,
		}))
		console.log("onFormChange", e.target.name, e.target.value);
		console.log("User", user);
	}

	const showSuccess = () => {
		setErrorSection({ show: false, text: null });
		setAdvisorBeingReviewed(null);
		setAdvisorsToReview(null);
		setReviews(null);
		setReview(null);
		populatePageData();

		setShowSavedMessage(true);
		setTimeout(() => setShowSavedMessage(false), 3000);
	}

	return (
		<Container>
			<Helmet>
				<title>Nectarine - My Reviews</title>
			</Helmet>
			<Row>
				<Col xl="10" md="12" className="review-section">
					{showSavedMessage &&
						<Alert className="saved-alert">
							<strong>Thank you!</strong> Your review has been saved!
						</Alert>
					}
					{advisorBeingReviewed &&
						<ReviewForm advisor={advisorBeingReviewed}
							consumer={user}
							review={review}
							onSuccessfulSave={showSuccess}
							onCancel={() => setAdvisorBeingReviewed(null)}
							putUrl="/api/account/review/" />
					}
					{/*{advisorsToReview && advisorsToReview.length === 0 &&*
						<div></div>
					}*/}
					{advisorsToReview && advisorsToReview.length > 0 && !advisorBeingReviewed &&
						<div>
							{advisorsToReview.map(advisor =>
								<Row key={advisor.id} className="review-reminder">
									<Col lg="3" className="text-center">
										<img src={advisor.profilePicUrl}
											className="circle-image headshot"
											alt={advisor.firstName + " " + advisor.lastName + ", " + advisor.certifications} />
									</Col>
									<Col xl="7" lg="9" className="text-center">
										<h2>Reminder to leave a review</h2>
										<p>How was your experience with {advisor.firstName + " " + advisor.lastName + ", " + advisor.certifications}?</p>
										<Button color="primary" size="lg" onClick={() => { setReview({ reviewee: advisor }); setAdvisorBeingReviewed(advisor); }}>Leave a Review!</Button>
									</Col>
									<Col xl="2" lg="0">
									</Col>
								</Row>
							)}
						</div>
					}

					{!reviews && <LoadingSpinner message="Loading..." />}
					{reviews && reviews.length > 0 && !advisorBeingReviewed &&
						<div>
							<h1>My Reviews</h1>

							{reviews.map(review =>
								<Row key={review.revieweeId} className="my-review-row">
									<Col md="3" className="text-center">
										<img src={review.revieweeProfilePicUrl}
											className="circle-image headshot"
											alt={getUserName(review)} />
									</Col>
									<Col md="9">
										<h2>{getUserName(review)}</h2>
										<div className="review-ratings review-ratings-display">
											<i className={review.rating >= 1 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
											<i className={review.rating >= 2 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
											<i className={review.rating >= 3 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
											<i className={review.rating >= 4 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
											<i className={review.rating >= 5 ? "fa-star fa-solid rating-" + review.rating : "fa-star fa-solid"}></i>
										</div>
										<h3>{review.title}</h3>
										<p>{review.body}</p>
										<div className="review-footer">
											<div className="reviewer-name">{review.displayName}</div>
											<div className="review-date">{dateHelper.printMonthYearLong(review.modifiedAt)}</div>
										</div>
										<p>
											<Button size="sm" color="primary" onClick={(e) => onEditReview(e, review)}>Edit this review</Button>
										</p>

									</Col>
								</Row>
							)}
						</div>
					}
					{reviews && reviews.length === 0 && !advisorBeingReviewed &&
						<div>
							<h1>My Reviews</h1>
							<p>You have not yet left any reviews.</p>
						</div>
					}
				</Col>
			</Row>
		</Container >
	);

	function getUserName(review) {
		return review.revieweeFirstName + " " + review.revieweeLastName + (review.revieweeCertifications ? ", " + review.revieweeCertifications : '');
	}
}
