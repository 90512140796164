import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LandingPageTemplate } from './LandingPageTemplate';

export const LandingPages = (props) => {

	// Get the slug from the URL
	let { slug } = useParams();

	var blurb, title, plural, singular;

	// Ask Chat GPT to give the benefits of this business model, formatted as HTML
	switch (slug) {
		case 'fee-only-fiduciaries':
			title = "Fee-Only Fiduciaries";
			plural = "fee-only fiduciaries";
			singular = "fee-only fiduciary";
			blurb = <div>
				<p><strong>Transparent and Unbiased Advice:</strong> Fee-only fiduciaries operate solely on client fees, eliminating potential conflicts of interest that may arise from commissions or product sales. This ensures that their advice is unbiased and in the client's best interest.</p>
				<p><strong>Alignment of Interests:</strong> Since fee-only fiduciaries don't earn commissions from financial products, their incentives are aligned with their clients' financial goals. They prioritize providing sound advice tailored to the client's needs rather than promoting specific products for their own gain.</p>
				<p><strong>Customized Financial Planning:</strong> Fee-only fiduciaries offer comprehensive financial planning services tailored to each client's unique circumstances. With a focus on long-term financial success, they develop personalized strategies to help clients achieve their goals and navigate life's financial complexities.</p>
				<p><strong>Fiduciary Duty:</strong> As fiduciaries, fee-only advisors are legally bound to act in their clients' best interests at all times. This commitment to fiduciary duty instills trust and confidence, assuring clients that their advisor is ethically obligated to prioritize their financial well-being above all else.</p>
			</div>;
			break;
		case 'fiduciary-cfps':
			title = "Fiduciary CFPs";
			plural = "fiduciary CFPs";
			singular = "fiduciary CFP";
			blurb = <div>
				<p><strong>Fiduciary Duty:</strong> Certified Financial Planners (CFPs) who adhere to fiduciary standards are legally obligated to act in their clients' best interests at all times. This commitment ensures that their advice and recommendations are unbiased and aligned with client goals.</p>
				<p><strong>Comprehensive Financial Planning:</strong> Fiduciary CFPs provide comprehensive financial planning services, covering various aspects of personal finance, including retirement planning, investment management, estate planning, tax strategies, and risk management. They develop holistic strategies tailored to each client's unique circumstances and objectives.</p>
				<p><strong>Objective Advice:</strong> Fiduciary CFPs offer objective advice free from conflicts of interest. They prioritize transparency and disclose any potential conflicts, ensuring that clients receive unbiased recommendations that are in their best interests.</p>
				<p><strong>Continual Education and Expertise:</strong> Fiduciary CFPs are required to maintain their certification through ongoing education and adherence to high ethical standards. They stay up-to-date with industry trends, regulations, and best practices to provide clients with informed and expert guidance.</p>
			</div>;
			break;
		case 'independent-financial-advisors':
			title = "Independent Financial Advisors";
			plural = "independent financial advisors";
			singular = "independent financial advisor";
			blurb = <div>
				<p><strong>Objective and Unbiased Guidance:</strong> Independent financial advisors offer objective and unbiased guidance since they are not tied to specific financial products or institutions. They prioritize client interests above all else, ensuring recommendations are tailored to meet individual financial goals.</p>
				<p><strong>Wide Range of Investment Options:</strong> Independent advisors have access to a broad range of investment options from various providers. This allows them to create diversified portfolios tailored to clients' risk tolerance, investment objectives, and time horizons.</p>
				<p><strong>Personalized Financial Planning:</strong> Independent advisors provide personalized financial planning services, taking into account each client's unique financial situation, goals, and preferences. They develop comprehensive strategies to address specific needs and help clients navigate complex financial decisions.</p>
				<p><strong>Client-Centric Approach:</strong> Independent financial advisors prioritize building long-term relationships with their clients. They offer ongoing support, education, and guidance to empower clients to make informed financial decisions and achieve their desired outcomes.</p>
			</div>;
			break;
		case 'flat-rate-financial-planners':
			title = "Flat-Rate Financial Planners";
			plural = "flat-rate financial planners";
			singular = "flat-rate financial planner";
			blurb = <div>
				<p><strong>Transparent Pricing:</strong> Flat-rate financial planners offer transparent pricing structures, typically charging a fixed fee for their services rather than earning commissions or fees based on assets under management. This ensures clarity and eliminates potential conflicts of interest.</p>
				<p><strong>Cost-Effective Solutions:</strong> Flat-rate financial planners provide cost-effective solutions for clients seeking financial advice. Clients pay a predetermined fee for the services they receive, regardless of the size of their assets or investment portfolio.</p>
				<p><strong>Customized Financial Plans:</strong> Flat-rate financial planners offer personalized financial plans tailored to each client's specific needs, goals, and circumstances. They take a holistic approach to financial planning, addressing various aspects such as budgeting, saving, investing, retirement planning, and risk management.</p>
				<p><strong>Client-Centric Focus:</strong> Flat-rate financial planners prioritize client interests and strive to build long-term relationships based on trust and mutual respect. They provide unbiased advice and ongoing support to help clients achieve their financial goals and navigate life's financial complexities.</p>
			</div>;
			break;
	}

	return (
		<LandingPageTemplate
			title={title} //Caps case
			plural={plural} //Plural, lowercase
			singular={singular} //Singular, lowercase
			blurb={blurb}
		/>
	);
}
